import "./PerDiemRateCardDetailsComponent.scss";
import DataLabelValueComponent from "../../../../components/data-label-value/DataLabelValueComponent";
import { Divider, Table} from "antd";
import React from "react";

interface PerDiemRateCardDetailsComponentProps{
    rateDetails?:any;
}

const PerDiemRateCardDetailsComponent=(props:PerDiemRateCardDetailsComponentProps)=>{
    const {rateDetails}=props;

    const payRateTableData=[
        {
            id:'shift_rate',
            rateType:'Shift Rate',
            rate:rateDetails?.pay_rate?.shift_rate?.rate
        },
        {
            id:'holiday_multiplier_rate',
            rateType:'Holiday Multiplier Rate',
            rate:rateDetails?.pay_rate?.holiday_multiplier_rate?.rate
        },
        {
            id:'pm_diff_rate',
            rateType:'PM Diff Rate',
            rate:rateDetails?.pay_rate?.pm_diff_rate?.rate
        },
        {
            id:'no_diff_rate',
            rateType:'NOC Diff Rate',
            rate:rateDetails?.pay_rate?.noc_diff_rate?.rate
        },
        {
            id:'weekend_rate',
            rateType:'Weekend Rate',
            rate:rateDetails?.pay_rate?.weekend_rate?.rate
        },
        {
            id:'rush_rate',
            rateType:'Rush Rate',
            rate:rateDetails?.pay_rate?.rush_rate?.rate
        },
        {
            id:'hazard_rate',
            rateType:'Hazard Rate',
            rate:rateDetails?.pay_rate?.hazard_rate?.rate
        },
        {
            id:'ot_pay_rate',
            rateType:'OT Pay Rate',
            rate:rateDetails?.pay_rate?.ot_pay_rate?.rate
        },
        {
            id:'dt_pay_rate',
            rateType:'DT Pay Rate',
            rate:rateDetails?.pay_rate?.dt_pay_rate?.rate
        },
    ]


    const rateColumnList=[
        {
            title:'Rate Type',
            width:250,
            render:(item:any)=>{
                return (
                    <div>{item?.rateType || 'N/A'}</div>
                )
            }
        },
        {
            title:'Rate',
            width:250,
            render:(item:any)=>{
                return (
                    <div>{item?.rate || 'N/A'}</div>
                )
            }
        }
    ]
    const billRateColumnList=[
        {
            title:'Rate Type',
            width:250,
            render:(item:any)=>{
                return (
                    <div>{item?.type || 'N/A'}</div>
                )
            }
        },
        {
            title:'Rate/Hr',
            width:250,
            render:(item:any)=>{
                return (
                    <div>{item?.rate || 'N/A'}</div>
                )
            }
        },
        {
            title:'Rate/Week',
            width:250,
            render:(item:any)=>{
                return (
                    <div>{item?.week_rate || 'N/A'}</div>
                )
            }
        }
    ]
    return (
        <div className={'mrg-top-20'}>
            <div className={'rate-type-header'}>Pay Rate Details</div>
            <div className={'mrg-bottom-20 mrg-top-20 d-flex'}>
                <div className={'flex-equal'}>
                    <DataLabelValueComponent label={'Final Pay Rate'}>
                        {rateDetails?.pay_rate?.total_pay_rate ?
                            <>
                                $ {rateDetails?.pay_rate?.total_pay_rate}
                            </> : 'N/A'
                        }
                    </DataLabelValueComponent>
                </div>
            </div>
            <div className={'per-diem-details-table-wrapper'}>
                <Table
                    columns={rateColumnList}
                    dataSource={payRateTableData}
                    pagination={false}
                />
            </div>

            <Divider/>

            <div className={'rate-type-header'}>Bill Rate Details</div>
            {/*<div className={'mrg-bottom-20 mrg-top-20 d-flex'}>*/}
            {/*    <div className={'flex-equal'}>*/}
            {/*        <DataLabelValueComponent label={'Total Bill Rate'}>*/}
            {/*            {rateDetails?.bill_rate?.total_bill_rate ?*/}
            {/*                <>*/}
            {/*                    $ {rateDetails?.bill_rate?.total_bill_rate}*/}
            {/*                </> : 'N/A'*/}
            {/*            }*/}
            {/*        </DataLabelValueComponent>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={'per-diem-details-table-wrapper mrg-bottom-20 mrg-top-20'}>
                <Table
                    columns={billRateColumnList}
                    dataSource={rateDetails?.bill_rate?.bill_rate || []}
                    pagination={false}
                />
            </div>
        </div>
    )
}

export default PerDiemRateCardDetailsComponent;