import "./JobViewDetailsComponent.scss"
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Tabs, Descriptions, Typography, Divider, Button, TableColumnsType, Row, Col, Modal} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import {ApiService, CommonService} from "../../../helpers";
import {ENV, ImageConfig} from "../../../constants";
import {Link, useHistory, useParams} from "react-router-dom";
import {Spin} from "antd";
import {LoadingOutlined, SendOutlined} from "@ant-design/icons";
import AntTableComponent from "../../../components/ant-table-component/AntTableComponent";
import SelectDropdownComponent from "../../ant-component/select/SelectComponent";
import CustomPreviewFile from "../../../components/shared/CustomPreviewFile";
import DrawerComponent from "../../../components/drawer/DrawerComponent";
import SingleEmailEditorComponent from "./email-editor-single/SingleEmailEditorComponent";
import JobNotesComponent from "./job-notes/JobNotesComponent";
import DialogComponent from "../../../components/DialogComponent";
import AddStaffToJobComponent from "./add-staff-to-job/AddStaffToJobComponent";
import PerDiemRateCardDetailsComponent from "./per-diem-rate-card-details/PerDiemRateCardDetailsComponent";
import ContractRateCardDetailsComponent from "./contract-rate-card-details/ContractRateCardDetailsComponent";
import PermanentRateCardDetailsComponent from "./permanent-rate-card-details/PermanentRateCardDetailsComponent";
import {Tooltip} from "@material-ui/core";

const {Title} = Typography;

const JobViewDetailsComponent = () => {
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const {id} = params;
    const [jobDetails, setJobDetails] = React.useState<any>();
    const [jobRateDetails, setJobRateDetails] = React.useState<any>([]);
    const [isJobDetailsLoading, setIsJobDetailsLoading] = useState<any>();
    const [isJobRateDetailsLoading, setIsRateJobDetailsLoading] = useState<any>();
    const [rowStatus, setRowStatus] = useState<{ [key: string]: string }>({});
    const [jobFilterData,setJobFilterData] = React.useState<any>({
        search: '',
        sort: {},
        limit: 10,
        job_id: id
    });

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>();
    const [openEmailDrawer,setOpenEmailDrawer]=useState<boolean>(false);
    const [emailDetails,setEmailDetails]=useState<any>(null);
    const [openAddStaffDrawer,setOpenAddStaffDrawer]=useState<boolean>(false);
    const [refreshToken,setRefreshToken]=useState<number>(0);

    const handlePreview = useCallback((fileUrl: any) => {
        setSelectedFile(fileUrl);
        setIsModalVisible(true);
    }, []);

    const handleStatusChange = useCallback((key: string, newStatus: string) => {
        setRowStatus((prev) => ({
            ...prev,
            [key]: newStatus, // Update only the selected row
        }));
        let payload: any = {};
        payload.status = newStatus;
        ApiService.put(ENV.API_URL + `ats/${key}/jobSubmissons`, payload)
            .then((resp: any) => {
                setJobFilterData({...jobFilterData})
                CommonService.showToast('Status updated successfully','success')
            })
            .catch((err: any) => {
                CommonService.showToast(err.error,'error')
            });
    }, [jobFilterData]);


    const getJobDetails = useCallback(() => {
        setIsJobDetailsLoading(true);
        CommonService._api
            .get(ENV.API_URL + `ats/${id}/job`)
            .then((resp) => {
                setJobDetails(resp.data);
                setIsJobDetailsLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setIsJobDetailsLoading(false)
            });
    }, [id])

    const getJobRateDetails = useCallback(() => {
        setIsRateJobDetailsLoading(true);
        CommonService._api
            .get(ENV.API_URL + `ats/${id}/ratecard`)
            .then((resp) => {
                setJobRateDetails(resp.data);
                setIsRateJobDetailsLoading(false)
            })
            .catch((err) => {
                console.log(err);
            });
    }, [id])

    useEffect(() => {
        getJobDetails();
        getJobRateDetails()
    }, [getJobDetails, getJobRateDetails]);

    const onBack = useCallback(() => {
        //   history.push(`/ats/list`);
        history.push(`/job`);
    }, [history])

    const handleEmailDrawer=useCallback((details:any)=>{
        setOpenEmailDrawer(true)
        setEmailDetails(details);
    },[])

    const cancelAdd = useCallback(() => {
        setOpenAddStaffDrawer(false);
    }, []);



    const JobSubmissionTableColumns: TableColumnsType =useMemo(()=>{
       return  [
           {
               title: "Name",
               width: 250,
               render: (item: any) => {
                   return (
                       <div >
                           {/*to={`/hcp/view/${item?.hcp_details?._id}`}*/}
                           <div onClick={()=>{
                               history.push(`/hcp/view/${item?.hcp_details?._id}`,{
                                   from:history.location.pathname,
                               })
                           }}  style={{textDecoration:"underline",cursor:'pointer'}}>
                                     {item?.hcp_details?.first_name + " " + item?.hcp_details?.last_name
                               || "N/A"}</div>
                       </div>
                   );
               },
           },
           {
               title: "Submitted By",
               width: 200,
               render: (item: any) => {
                   return (
                       <div>
                           <div>{CommonService.formatTitleCase(item?.applied_source) || "N/A"}</div>
                       </div>
                   );
               },
           },
           {
               title: "Email",
               width: 250,
               render: (item: any) => {
                   return (
                       <div>
                           {item?.hcp_details?.email >20 ?
                            <Tooltip title={item?.hcp_details?.email}>
                                <div className={'ellipses-for-email'}>
                                    {item?.hcp_details?.email}
                                </div>
                            </Tooltip>:
                               <div>
                                   {item?.hcp_details?.email}
                               </div>
                           }
                       </div>
                   );
               },
           },
           {
               title: "Pay Rate",
               width: 200,
               render: (item: any) => {
                   const contractTotalHourlyRate=item?.job_details?.job_type === 'contract' &&
                      ( item?.pay_rate?.travel_per_diems.reduce((sum:any, item:any) => sum + Number(item.rate), 0));
                   const contractTotalWeeklyRate=item?.job_details?.job_type === 'contract' &&
                          ( item?.pay_rate?.travel_per_diems.reduce((sum:any, item:any) => sum + Number(item.week_rate), 0));
                   return (
                       <div>
                           {item?.job_details?.job_type === 'per_diem' ?
                               <div>
                                   {item?.pay_rate?.total_pay_rate || 'N/A'}
                               </div> :
                               item?.job_details?.job_type === 'permanent' ?
                                   <div>
                                        {item?.pay_rate?.salary || 'N/A'}
                                   </div>:
                                   <div>
                                      ${contractTotalHourlyRate}/Hr - ${contractTotalWeeklyRate}/Week
                                   </div>
                           }
                       </div>
                   );
               },
           },
           {title:'Document Received',
               width:160,
               render:(item:any)=> {
                   return <div>
                       {item?.required_credentials_count !== 0 ?
                           <>
                            {item?.received_credentials_count}/{item?.required_credentials_count}
                           </>: 'N/A'
                       }
                   </div>
               }
           },
           {
               title: "Status",
               width: 150,
               render: (item: any) => {
                   return <div className={'table-under-select-box'}>
                       <SelectDropdownComponent
                           placeholder={'Select job status'}
                           value={rowStatus[item._id] || item.status}
                           options={[
                               {title: 'Open', code: 'open'},
                               {title: 'Closed', code: 'closed'},
                               {title: 'Filled', code: 'filled'},
                               {title: 'Hold', code: 'hold'},
                               {title: 'In Progress', code: 'in_progress'},
                               {title:'Submitted',code:'submitted'},
                               {title:'Offer Accepted',code:'offer_accepted'},
                               {title:'Offer Extended',code:'offer_extended'}
                           ]}
                           required={true}
                           size={"middle"}
                           displayWith={(option) => option.title}
                           valueExtractor={(option) => option.code}
                           onChange={(value) => handleStatusChange(item._id, value)}
                       />
                   </div>;
               },
           },
           {
               title:'Send Mail',
               width:100,
               render:(item:any)=>{
                   return (
                       <div style={{marginLeft:'10px'}}>
                           <Button icon={<SendOutlined/>}
                                   iconPosition={'end'} onClick={()=>handleEmailDrawer(item)}>
                           </Button>
                       </div>
                   )
               }
           },
           {
               title: "Action",
               width: 120,
               render: (item: any) => {
                   return <div className={'cursor-pointer underline-text color-underline-text'}
                               onClick={() => handlePreview(item)}>View Resume</div>;
               },
           },
       ]
    },[handleEmailDrawer,handlePreview,handleStatusChange,rowStatus,history]) ;


    return (
        <>
            <Modal
                title="Resume Preview"
                open={isModalVisible}
                footer={null}
                onCancel={() => {
                    setIsModalVisible(false)
                    setSelectedFile(undefined)
                }}
                width={800}
            >
                <CustomPreviewFile
                    previewData={{
                        url: selectedFile?.resume_url,
                        file_type: selectedFile?.attachments[0]?.file_type,
                    }}/>
            </Modal>
            <DialogComponent open={openAddStaffDrawer} cancel={cancelAdd} headerLabel={'Add Staff to this Job'}>
                <AddStaffToJobComponent
                    cancel={cancelAdd}
                    staff_type={jobDetails?.staff_type}
                    setRefreshToken={setRefreshToken}
                    id={id}
                    basicDetails={jobDetails}
                />
            </DialogComponent>
            <DrawerComponent  isOpen={openEmailDrawer} title={'Email'} showClose={true} onClose={()=>setOpenEmailDrawer(false)}>
                    <SingleEmailEditorComponent recipientEmail={emailDetails?.hcp_details?.email || ''} setOpenEmailDrawer={setOpenEmailDrawer}/>
            </DrawerComponent>
            <div className={'view-job-container-whole'}>
                <div className={'view-job-header'}>
                    <div className={'d-flex header-back-content'} onClick={onBack}>
                        <img src={ImageConfig.BackIcon} alt="back"/>
                        <div className={'header-back mrg-left-10'}>
                            Back
                        </div>
                    </div>
                    <div className="header-title pdd-10">View Job</div>
                    <div>
                        <Link to={`/job/edit/` + id} className="add-job-link">
                            <Button
                                type="primary"
                                className="add-job-button"
                            >
                                Edit Job
                            </Button>
                        </Link>
                    </div>
                </div>
                {isJobDetailsLoading &&
                    <div className={'flex-equal d-flex justify-content-center align-items-center'}>
                        <div>
                            <Spin indicator={<LoadingOutlined style={{fontSize: 50, color: "#40D6C3"}} spin/>}
                                  size={"large"} spinning={true}>
                            </Spin>

                        </div>
                    </div>}
                {(!isJobDetailsLoading && !isJobRateDetailsLoading) &&
                    <div className={'job-view-details-content'}>
                        <Tabs defaultActiveKey="1">
                            {/* Basic Details Section */}
                            <TabPane tab="Basic Details" key="1">
                                <div className={'job-details-content-container'}>
                                    <div className={'d-flex align-items-center justify-content-space-between mrg-bottom-20'}>
                                    <Title level={5}>Job Details</Title>
                                        <Button className={'add-job-button'} onClick={()=>setOpenAddStaffDrawer(true)}>Add Staff</Button>
                                    </div>

                                    {/*First row*/}
                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Facility</div>
                                            <div>{jobDetails?.facility_details?.name || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Job Type</div>
                                            <div>{jobDetails?.job_type ? CommonService.formatTitleCase(jobDetails?.job_type) : 'N/A'}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Job Title</div>
                                            <div>{jobDetails?.job_title || "N/A"}</div>
                                        </Col>
                                        {/*<Col span={8}>*/}
                                        {/*    <div className={'text-header-for-soft-content'}>Max. allowed*/}
                                        {/*        submissions*/}
                                        {/*    </div>*/}
                                        {/*    <div>{jobDetails?.max_submissions || "-"}</div>*/}
                                        {/*</Col>*/}
                                    </Row>

                                    {/*Second row*/}

                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Job ID</div>
                                            <div>
                                                {jobDetails?._id
                                                    ? jobDetails?._id?.slice(-7)
                                                    : "N/A"}
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Facility Manager</div>
                                            <div>
                                                {jobDetails?.facility_manager_details
                                                    ? `${jobDetails?.facility_manager_details?.first_name} ${jobDetails?.facility_manager_details?.last_name}`
                                                    : "N/A"}
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Job Status</div>
                                            <div>{jobDetails?.job_status ? CommonService.formatTitleCase(jobDetails?.job_status) : "N/A"}</div>
                                        </Col>
                                        {/*<Col span={8}>*/}
                                        {/*    <div className={'text-header-for-soft-content'}>Job Id*/}
                                        {/*    </div>*/}
                                        {/*    <div>{jobDetails?.job_id || "-"}</div>*/}
                                        {/*</Col>*/}
                                    </Row>

                                    {/*Third Row*/}

                                    <Row gutter={[16, 16]} className={'mrg-bottom-10'}>
                                        {/*<Col span={8}>*/}
                                        {/*    <div className={'text-header-for-soft-content'}>Clearance</div>*/}
                                        {/*    <div>{jobDetails?.is_clearance_required ? "Yes" : "No"}</div>*/}
                                        {/*</Col>*/}
                                        {/*<Col span={8}>*/}
                                        {/*    <div className={'text-header-for-soft-content'}>Offerings</div>*/}
                                        {/*    <div>{CommonService.formatTitleCase(jobDetails?.offerings)}</div>*/}
                                        {/*</Col>*/}
                                    </Row>

                                    {/*Fourth Row*/}

                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Profession</div>
                                            <div>{jobDetails?.staff_type || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Specialty</div>
                                            <div>{jobDetails?.speciality || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Offerings</div>
                                            <div>{jobDetails?.offerings ? CommonService.formatTitleCase(jobDetails?.offerings) : "N/A"}</div>
                                        </Col>
                                    </Row>

                                    {/*Seventh Row*/}
                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Number of Positions</div>
                                            <div>{jobDetails?.no_of_positions || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Tax Terms</div>
                                            <div>{jobDetails?.tax_terms || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Remote Work</div>
                                            <div>{jobDetails?.remote_job || "No"}</div>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Job Category</div>
                                            <div>{jobDetails?.job_category || "N/A"}</div>
                                        </Col>
                                        {jobDetails?.job_type === 'permanent' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Start Date</div>
                                                    <div>{jobDetails?.job_start_date ? CommonService.getUtcDate(jobDetails?.job_start_date) : "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>End Date</div>
                                                    <div>{jobDetails?.job_end_date ? CommonService.getUtcDate(jobDetails?.job_end_date) : "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                        {jobDetails?.job_type === 'per_diem' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Start Date</div>
                                                    <div>{jobDetails?.per_diem_details?.start_date ? CommonService.getUtcDate(jobDetails?.per_diem_details?.start_date) : "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>End Date</div>
                                                    <div>{jobDetails?.per_diem_details?.end_date ? CommonService.getUtcDate(jobDetails?.per_diem_details?.end_date) : "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                        {jobDetails?.job_type === 'contract' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Start Date</div>
                                                    <div>{jobDetails?.contract_details?.start_date ? CommonService.getUtcDate(jobDetails?.contract_details?.start_date) : "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>End Date</div>
                                                    <div>{jobDetails?.contract_details?.end_date ? CommonService.getUtcDate(jobDetails?.contract_details?.end_date) : "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                    </Row>

                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Street</div>
                                            <div>{jobDetails?.address?.street || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>City</div>
                                            <div>{jobDetails?.address?.city || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>State</div>
                                            <div>{jobDetails?.address?.state || "N/A"}</div>
                                        </Col>
                                    </Row>


                                    {/*Eighth Row*/}
                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Country</div>
                                            <div>{jobDetails?.address?.country || "N/A"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'text-header-for-soft-content'}>Zip Code</div>
                                            <div>{jobDetails?.address?.zip_code || "N/A"}</div>
                                        </Col>
                                        {jobDetails?.job_type ==='contract' &&
                                            <Col span={8}>
                                                <div className={'text-header-for-soft-content'}>Shift Type</div>
                                                <div>{jobDetails?.contract_details?.shift_type ?
                                                   jobDetails?.contract_details?.shift_type : "N/A"}</div>
                                            </Col>
                                        }
                                        {jobDetails?.job_type ==='per_diem' &&
                                            <Col span={8}>
                                                <div className={'text-header-for-soft-content'}>Shift Type</div>
                                                <div>{jobDetails?.per_diem_details?.shift_type ?
                                                    jobDetails?.per_diem_details?.shift_type : "N/A"}</div>
                                            </Col>
                                        }
                                    </Row>
                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        {jobDetails?.job_type === 'contract' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>No of Days Per Week</div>
                                                    <div>{jobDetails?.contract_details?.days_per_week || "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>No of Weeks</div>
                                                    <div>{jobDetails?.contract_details?.no_of_weeks || "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Hours Per Shift</div>
                                                    <div>{jobDetails?.contract_details?.shift_duration || "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row gutter={[16, 16]} className={'mrg-bottom-20'}>
                                        {jobDetails?.job_type ==='per_diem' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Start Time</div>
                                                    <div>{jobDetails?.per_diem_details?.start_time ?
                                                        CommonService.convertMinsToHrsMins(jobDetails?.per_diem_details?.start_time) : "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>End Time</div>
                                                    <div>{jobDetails?.per_diem_details?.end_time ?
                                                        CommonService.convertMinsToHrsMins(jobDetails?.per_diem_details?.end_time) : "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                        {jobDetails?.job_type ==='contract' &&
                                            <>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>Start Time</div>
                                                    <div>{jobDetails?.contract_details?.start_time ?
                                                        CommonService.convertMinsToHrsMins(jobDetails?.contract_details?.start_time) : "N/A"}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className={'text-header-for-soft-content'}>End Time</div>
                                                    <div>{jobDetails?.contract_details?.end_time ?
                                                        CommonService.convertMinsToHrsMins(jobDetails?.contract_details?.end_time) : "N/A"}</div>
                                                </Col>
                                            </>
                                        }
                                    </Row>


                                    <Divider/>

                                    {/* Job Description Section */}
                                    <Title level={5}>Job Description</Title>
                                    <Typography.Paragraph>
                                        <div dangerouslySetInnerHTML={{__html:jobDetails?.job_description || 'N/A'}} style={{width:'100%'}}></div>
                                        {/*{jobDetails?.job_description || "N/A"}*/}
                                    </Typography.Paragraph>
                                    <Divider/>
                                    <Title level={5}>Job Benefits</Title>
                                    <Typography.Paragraph>
                                        <div dangerouslySetInnerHTML={{__html: jobDetails?.job_benefits || 'N/A'}}
                                             style={{width: '100%'}}></div>
                                    </Typography.Paragraph>
                                    <Divider/>
                                    <Title level={5}>Job Requirements</Title>
                                    <Typography.Paragraph>
                                        <div dangerouslySetInnerHTML={{__html: jobDetails?.job_requirements || 'N/A'}}
                                             style={{width: '100%'}}></div>
                                    </Typography.Paragraph>


                                    <Divider/>

                                    <Title level={5}>Posting Configuration</Title>
                                    <Row gutter={[16, 16]} className={'mrg-bottom-10'}>
                                        <Col span={8}>
                                            <div className={'job-basic-details-header-wrapper'}>On Company website</div>
                                            <div>{jobDetails?.posting_status?.website ? "Yes": "No"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'job-basic-details-header-wrapper'}>On mobile</div>
                                            <div>{jobDetails?.posting_status?.mobile_app ? "Yes": "No"}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div className={'job-basic-details-header-wrapper'}>On job boards
                                            </div>
                                            <div>{jobDetails?.posting_status?.is_job_boards ? "Yes": "No"}</div>
                                        </Col>
                                    </Row>
                                    {jobDetails?.posting_status?.is_job_boards &&
                                    <Row gutter={[16, 16]}>
                                        <Col span={24}>
                                            <div>
                                                <div className={'text-header-for-soft-content  mrg-bottom-10'}>Job
                                                    boards
                                                </div>
                                                <div className={'view-details-document-tag'}>
                                                    {Array.isArray(jobDetails?.posting_status?.job_boards) && jobDetails?.posting_status?.job_boards?.map((docName: any, index: any) => {
                                                        return (
                                                            <>
                                                                <div className={'document-tag'}>
                                                                    <div
                                                                        className={'job-doc-text'}>{CommonService.formatTitleCase(docName) || 'N/A'}</div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>}

                                    <Divider/>

                                    {/* Documents Section */}
                                    <Title level={5}>Documents</Title>
                                    {jobDetails?.required_credentials?.documents_submission?.length > 0 &&
                                        <div className={'mrg-bottom-20 mrg-top-10'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}>Required
                                                documents for
                                                submission
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.documents_submission?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.license_submission?.length > 0 &&
                                        <div className={'mrg-bottom-20'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}>Required
                                                license for
                                                submission
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.license_submission?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.certifications_submission?.length > 0 &&
                                        <div className={'mrg-bottom-20'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}>Required
                                                certifications for
                                                submission
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.certifications_submission?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.skill_checklist_submission?.length > 0 &&
                                        <div className={'mrg-bottom-20'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}>Required
                                                skills checklist for
                                                submission
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.skill_checklist_submission?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.documents_eboarding?.length > 0 &&
                                        <div className={'mrg-bottom-20'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}>
                                                Required documents for eBoarding
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.documents_eboarding?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.license_eboarding?.length > 0 &&
                                        <div className={'mrg-bottom-20'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}> Required
                                                license
                                                for eBoarding
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.license_eboarding?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    {jobDetails?.required_credentials?.certifications_eboarding?.length > 0 &&
                                        <div className={'mrg-bottom-20'}>
                                            <div className={'text-header-for-soft-content  mrg-bottom-10'}> Required
                                                certifications for eBoarding
                                            </div>
                                            <div className={'view-details-document-tag'}>
                                                {jobDetails?.required_credentials?.certifications_eboarding?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div className={'job-doc-text'}>{docName}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                    <Divider/>

                                    {/* Skills Section */}
                                    <Title level={5}>Skills</Title>
                                    <Descriptions column={1} size="middle">
                                        <Descriptions.Item label="Degree">
                                            <div className={'view-details-document-tag'}>
                                                {Array.isArray(jobDetails?.job_qualifications?.degree) &&
                                                    jobDetails.job_qualifications.degree.map((docName: any, index: number) => (
                                                        <div key={index} className="document-tag">
                                                            <div className="job-doc-text">{CommonService.formatTitleCase(docName)}</div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Experience">
                                            <div className={'view-details-document-tag'}>
                                                <div className={'document-tag'}>
                                                    <div
                                                        className={'job-doc-text'}>{jobDetails?.job_qualifications?.min_experience} years
                                                        to {jobDetails?.job_qualifications?.max_experience} years
                                                    </div>
                                                </div>
                                            </div>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Priority">
                                            <div className={'view-details-document-tag'}>
                                                <div className={'document-tag'}>
                                                    <div
                                                        className={'job-doc-text'}>{String(jobDetails?.job_qualifications?.priority) ? CommonService.formatTitleCase(jobDetails?.job_qualifications?.priority) : 'N/A'}
                                                    </div>
                                                </div>
                                            </div>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Languages">
                                            <div className={'view-details-document-tag'}>
                                                {Array.isArray(jobDetails?.job_qualifications?.languages) && jobDetails?.job_qualifications?.languages?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div
                                                                    className={'job-doc-text'}>{CommonService.formatTitleCase(docName)}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Skills">
                                            <div className={'view-details-document-tag'}>
                                                {Array.isArray(jobDetails?.job_qualifications?.skills) && jobDetails?.job_qualifications?.skills?.map((docName: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className={'document-tag'}>
                                                                <div
                                                                    className={'job-doc-text'}>{CommonService.formatTitleCase(docName)}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </Descriptions.Item>
                                    </Descriptions>


                                    {/* Posting Configuration Section */}

                                </div>
                            </TabPane>

                            {/* Rate Details Section */}
                            <TabPane tab="Rate Details" key="2">
                                <div className={'job-details-content-container'}>
                                    {jobDetails?.job_type ==='per_diem' &&
                                        <PerDiemRateCardDetailsComponent rateDetails={jobRateDetails?.per_diem_rate_card}/>}
                                    {jobDetails?.job_type ==='contract' &&
                                        <ContractRateCardDetailsComponent rateDetails={jobRateDetails?.contract_rate_card}/>}
                                    {jobDetails?.job_type ==='permanent' &&
                                    <PermanentRateCardDetailsComponent rateDetails={jobRateDetails?.permanent_rate_card}/>}

                                </div>
                            </TabPane>

                            {/* Submissions Details Section */}
                            <TabPane tab="Submissions" key="3">
                                <AntTableComponent
                                    key={refreshToken}
                                    isBordered={true}
                                    url={ENV.API_URL + "ats/jobSubmissons"}
                                    method={'post'}
                                    extraPayload={jobFilterData}
                                    columns={JobSubmissionTableColumns}
                                    noDataText={'No Submissions Found.'}
                                    isRowSelection={false}
                                />
                            </TabPane>

                            {/* Notes Details Section*/}
                            <TabPane tab="Notes" key="4">
                                <JobNotesComponent id={id} jobDetails={jobDetails}/>
                            </TabPane>
                        </Tabs>
                    </div>
                }
            </div>
        </>
    )
}

export default JobViewDetailsComponent
