import "./JobEditCredentialComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {jobCertificationsDocs, jobDocuments, jobLicense, jobSkillsDoc} from "../../../../constants/data";
import {Button, Divider} from "antd";
import {ApiService, CommonService} from "../../../../helpers";
import {ENV} from "../../../../constants";
import {Form, Formik} from "formik";
import AddDocumentDrawer from "../../add-job/job-credentialing/documents-modal/DocumentsModalComponent";




interface SectionState {
    [key: string]: string[];
}

interface StatJobCredentialingComponentsProps {
    setButtonText?: any
    next?: any
    jobDetails:any
}


const JobEditCredentialComponent=(props:StatJobCredentialingComponentsProps)=>{
    const {setButtonText, next,jobDetails} = props;
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [activeSection, setActiveSection] = useState<string>("");
    const [sectionState, setSectionState] = useState<SectionState>({
        documents: [],
        certifications: [],
        licenses: [],
        skills: [],
        boardingDocuments: [],
        boardingCertifications: [],
        boardingLicenses: []
    });

    // Available options for each section
    const availableOptions: SectionState = {
        documents: jobDocuments,
        certifications: jobCertificationsDocs,
        licenses: jobLicense,
        skills: jobSkillsDoc,
        boardingDocuments: jobDocuments,
        boardingCertifications: jobCertificationsDocs,
        boardingLicenses: jobLicense,
    };

    useEffect(() => {
        const documentInitial = {
            documents: jobDetails?.required_credentials?.documents_submission || [],
            certifications: jobDetails?.required_credentials?.certifications_submission || [],
            licenses: jobDetails?.required_credentials?.license_submission || [],
            skills: jobDetails?.required_credentials?.skill_checklist_submission || [],
            boardingDocuments: jobDetails?.required_credentials?.documents_eboarding || [],
            boardingCertifications: jobDetails?.required_credentials?.certifications_eboarding || [],
            boardingLicenses: jobDetails?.required_credentials?.license_eboarding || [],
        }
        setSectionState(documentInitial)
    }, [jobDetails]);


    useEffect(() => {
        if (sectionState?.documents?.length > 0 || sectionState?.certifications?.length > 0 || sectionState?.licenses?.length > 0 || sectionState?.skills?.length > 0 || sectionState?.boardingDocuments?.length > 0 || sectionState?.boardingCertifications?.length > 0 || sectionState?.boardingLicenses?.length > 0) {
            setButtonText("Next")
        } else {
            setButtonText("Skip")
        }
    }, [sectionState, setButtonText]);

    // Open the drawer for a specific section
    const openDrawer = useCallback((section: string) => {
        setActiveSection(section);
        setIsDrawerVisible(true);
    }, []);

    // Close the drawer
    const closeDrawer = useCallback(() => {
        setIsDrawerVisible(false);
    }, []);

    // Update the selected items for a section
    const updateSection = useCallback((section: string, selectedItems: string[]) => {
        setSectionState((prev) => ({
            ...prev,
            [section]: selectedItems,
        }));
        closeDrawer();
    }, [closeDrawer]);


    const removeDocument = useCallback((sectionKey, index) => {
        setSectionState((prev) => ({
            ...prev,
            [sectionKey]: prev[sectionKey].filter((_, i) => i !== index),
        }))
    }, [])

    const renderSection = (
            title: string,
            sectionKey: string,
            addButtonText: string
        ) => (
            <div className="required-documents-layout__section">
                <h3 className="required-documents-layout__section-title">{title}</h3>
                <div className="required-documents-layout__content">
                    {sectionState[sectionKey]?.length > 0 && <>
                        {
                            sectionState[sectionKey]?.map((item, index) => {
                                return (
                                    <>
                                        <div className={'document-tag'}>
                                            <div className={'job-doc-text'}>{item}</div>
                                            <div className={'doc-cross-icon cursor-pointer'} onClick={() => {
                                                removeDocument(sectionKey, index)
                                            }}>x
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </>}
                    {sectionState[sectionKey]?.length === 0 && <>
                        <p className="required-documents-layout__no-selection">
                            No {sectionKey} selected
                        </p>
                    </>}
                    <Button
                        type="link"
                        className="required-documents-layout__add-button"
                        icon={<span style={{fontWeight: "bold", fontSize: "16px"}}>+</span>}
                        onClick={() => openDrawer(sectionKey)}
                    >
                        {addButtonText}
                    </Button>
                </div>
                <Divider/>
            </div>
        )
    ;

    const onSubmitBasicDetails = useCallback((values: any) => {
        // insertedId
        const payload = {
            required_credentials: {
                ...values,
                documents_submission: sectionState.documents,
                certifications_submission: sectionState.certifications,
                license_submission: sectionState.licenses,
                skill_checklist_submission: sectionState.skills,
                documents_eboarding: sectionState.boardingDocuments,
                certifications_eboarding: sectionState.boardingCertifications,
                license_eboarding: sectionState.boardingLicenses
            }
        }
        if (sectionState?.documents?.length > 0 ||
            sectionState?.certifications?.length > 0 ||
            sectionState?.licenses?.length > 0 ||
            sectionState?.skills?.length > 0 ||
            sectionState?.boardingDocuments?.length > 0 ||
            sectionState?.boardingCertifications?.length > 0 ||
            sectionState?.boardingLicenses?.length > 0) {
            ApiService.put(ENV.API_URL + `ats/${jobDetails?._id}/job`, payload)
                .then((resp: any) => {
                    CommonService.showToast('Documents updated successfully','success')
                    next()
                })
                .catch((err: any) => {
                    CommonService.showToast(err.err ,'error')
                });
        } else {
            next()
        }

    }, [next, sectionState, jobDetails])

    return (
        <Formik
            initialValues={{}}
            onSubmit={onSubmitBasicDetails}
        >
            {({handleSubmit}) => (
                <Form onSubmit={handleSubmit} id="add-job-credentialing">
                    <div className="required-documents-layout">
                        {renderSection("Required documents for submission:", "documents", "Add Documents")}
                        {renderSection("Required Certifications for Submission:", "certifications", "Add Certificates")}
                        {renderSection("Required License for Submission:", "licenses", "Add Licenses")}
                        {renderSection("Required Skill Checklist for Submission:", "skills", "Add Skills")}
                        {renderSection("Required Documents for eBoarding:", "boardingDocuments", "Add Documents")}
                        {renderSection("Required Certifications for eBoarding:", "boardingCertifications", "Add Certificates")}
                        {renderSection("Required License for  eBoarding:", "boardingLicenses", "Add Licenses")}
                        <AddDocumentDrawer
                            visible={isDrawerVisible}
                            onClose={closeDrawer}
                            onUpdate={(selectedItems: any) => updateSection(activeSection, selectedItems)}
                            selectedItems={sectionState[activeSection]}
                            availableOptions={availableOptions[activeSection]}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
export default JobEditCredentialComponent