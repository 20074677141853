import "./AddStaffToJobComponent.scss";
import FormLabel from "@material-ui/core/FormLabel";
import {CssTextField} from "../../../../constants/data/styles";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import LoaderComponent from "../../../../components/LoaderComponent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Lottie from "react-lottie";
import {Button} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import animationData from "../../../../assets/animations/NoData.json";
import {CommonService} from "../../../../helpers";
import {ENV} from "../../../../constants";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

interface AddStaffToJobComponentProps{
    cancel: () => void;
    id:string;
    basicDetails:any;
    staff_type:string;
    setRefreshToken:any;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(3),
        },
        paper: {
            padding:"0px 26px 26px 26px",
            margin: "auto",
            marginTop:'10px'
        },
        assignNcActions: {
            margin: "auto",
            marginTop: "20px",
            textAlign: "center",
            justifyContent: "center",
        },
        title: {
            fontWeight: 450,
        },
    })
);

const AddStaffToJobComponent=(props:AddStaffToJobComponentProps)=>{
    const {id,setRefreshToken}=props;
    const afterCancel = props?.cancel;
    const classes = useStyles();
    const [hcpList, sethcpList] = React.useState<any>(null);
    const [searchHcp, setSearchHcp] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSelectedCount, setIsSelectedCount] = useState<any>(-1)
    const [selectedHcps, setSelectedHcps] = useState<any>([]);

    const defaultOptions = {
        animationData,
    };

    const init = useCallback(() => {
        setIsLoading(true);
        let payload: any = {
            roles:['hcp']
        };
        if (searchHcp !== "") {
            payload.search = searchHcp
        }

        CommonService._api.post(ENV.API_URL + `user/list`,payload).then((resp) => {
            sethcpList(resp?.data?.docs);
            setIsLoading(false);
        })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [searchHcp]);


    const cancel = (resetForm: any) => {
        if (afterCancel) {
            afterCancel();
        }
    };

    useEffect(() => {
        init();
    }, [init]);

    const isSelected = useCallback((_id: any) => {
        if (selectedHcps?.indexOf(_id) !== -1) {
            return true;
        } else {
            return false;
        }
    }, [selectedHcps]);

    const handleAddStaffToJob=useCallback(()=>{
        const payload={
            job_ids:[id],
            user_ids:selectedHcps
        }
        console.log(payload)
        CommonService._api.post(ENV.API_URL + 'ats/applyJobs',payload).then((response:any)=>{
            CommonService.showToast(response.message,"success")
            setRefreshToken((prev:number)=>prev +1);
            afterCancel()
        }).catch((error:any)=>{
            CommonService.showToast(error.error || 'Error',"error");
        })
    },[selectedHcps,id,afterCancel,setRefreshToken])


    const handleChange = (event: any) => {
        let index = selectedHcps.indexOf(event.target.value);
        let tempHcps = [];
        if (index > -1) {
            setIsSelectedCount(selectedHcps?.length - 1 === 0 ? -1 : selectedHcps?.length - 1)
            tempHcps = selectedHcps.filter((item: any) => item !== event.target.value);
        } else {
            setIsSelectedCount(selectedHcps?.length + 1)
            tempHcps = [...selectedHcps, event.target.value];
        }
        setSelectedHcps(tempHcps);
    };


    return (
        <div>
            <div className={'pdd-left-20 pdd-right-20 pdd-bottom-20 mrg-top-20'}>
                {/*<h2 className={classes.title}>Add Staff to this Shift</h2>*/}
                <FormLabel component="legend" className="mrg-left-0">
                    List Of Staff
                </FormLabel>
                <div id="alert-dialog-title">
                    <div className="mrg-top-20">
                        <div>
                            <div className="d-flex">
                                <div className="d-flex position-relative">
                                    <CssTextField
                                        defaultValue={""}
                                        onChange={(event) => {
                                            setSearchHcp(event?.target?.value);
                                        }}
                                        className="staff-search-field"
                                        variant={"outlined"}
                                        size={"small"}
                                        type={"text"}
                                        placeholder={"Search Staff"}
                                        value={searchHcp}
                                    />
                                    {searchHcp === "" ? (
                                        <div className={"search_icon"}>
                                            <SearchRounded/>
                                        </div>
                                    ) : (
                                        <div className={"search_icon"}>
                                            <ClearIcon onClick={(event) => setSearchHcp("")} id="clear_hcp_search"/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoading ?
                        <div className={'mrg-top-20'}>
                        <LoaderComponent position="block"/>
                        </div>
                        :
                        <div className={'mrg-top-20'}>
                            {hcpList?.length === 0 ?
                                <div className={"display-flex flex-one mrg-top-20 mrg-bottom-20"}>
                                    <Lottie width={400} height={400} speed={1} options={defaultOptions}/>
                                </div> :
                                <>
                                    <div className="mrg-top-20 job-list-content">
                                        {hcpList?.map((item: any) => {
                                            const isItemSelected = isSelected(item?.user_id);
                                            return (
                                                <div>
                                                    <FormControlLabel value={item?.user_id} checked={isItemSelected}
                                                                      control={<Checkbox/>} onChange={(event) => handleChange(event)}
                                                                      label={item?.first_name + " " + item?.last_name}/>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={classes.assignNcActions}>
                                        <Button type={"submit"} size="large" variant={"outlined"} className={"normal"}
                                                onClick={cancel}>
                                            Cancel
                                        </Button>
                                        <Button type={"submit"} color={"primary"} size="large"
                                                disabled={isSelectedCount === -1}
                                                className={'mrg-left-20'} variant={"contained"}
                                                onClick={handleAddStaffToJob}>
                                            Add Staff
                                        </Button>
                                    </div>
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AddStaffToJobComponent