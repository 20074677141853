import './MyPipeLineComponent.scss';
import React from "react";
import MyPipeLineListComponent from './my-pipeline-list/MyPipeLineListComponent';

const MyPipeLineComponent=()=>{
    return (
        <>
            <MyPipeLineListComponent/>
        </>
    )
}
export default MyPipeLineComponent