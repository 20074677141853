import React, {useCallback, useEffect, useState} from "react";
import {Formik, Form, FormikHelpers} from "formik";
import {
    Divider,
    Form as AntdForm,
    Radio,
} from "antd";
import './JobBasicDetailsComponent.scss'
import FormikSelectDropdownComponent from "../../../ant-component/formik-select-dropdown/FormikSelectDropdownComponent";
import FormLabelComponent from "../../../ant-component/form-label/FormLabelComponent";
import FormikInputComponent from "../../../ant-component/formik-input-component/FormikInputComponent";
import FormikDatePickerComponent from "../../../ant-component/formik-date-picker/FormikDatePickerComponent";
import {ApiService, CommonService} from "../../../../helpers";
import {ENV} from "../../../../constants";
import { useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";
import { languages, shiftDurationList, weekDaysList} from "../../../../constants/data";
import * as Yup from "yup";
import TextEditorComponent from "../../../../components/text-editor/TextEditorComponent";
import FormikTimePickerComponent from "../../../ant-component/formik-time-picker/FormikTimePickerComponent";

interface jobBasicDetailsProps {
    next: () => void;
    setApiDataAfterAdd?: any;
    job_details?:any;
    // formData:any;
    // setFormData:any;
}

const formInitialValues = {
    facility_id: "",
    job_title: "",
    job_type: "",
    remote_job: "",
    shift_dates:"",
    per_diem_details:{
        start_time:"",
        end_time:"",
        start_date:'',
        end_date:'',
    },
    speciality: "",
    profession: "",
    address:{
        street:"",
        city:"",
        state:"",
        country:'US',
        zip_code:"",
    },
    job_qualifications: {
        degree: [],
        skills: [],
        languages: [],
        priority: "",
        min_experience: "",
        max_experience: "",
    },

    posting_status: {
        website: "",
        mobile_app: "",
        is_job_boards: "",
        job_boards: [],
    },
};


const validationSchema = Yup.object({
    // facility_id: Yup.string().required("Facility ID is required"),
    job_type: Yup.string().required("Job Type is required"),
    job_title: Yup.string().required("Job title is required"),
});

const JobBasicsDetailsForm = (props: jobBasicDetailsProps) => {
    const {next, setApiDataAfterAdd} = props;
    const [initialValues]=useState<any>(formInitialValues);
    const {regions, isLoading} = useSelector((state: StateParams) => state.meta);
    const {hcpTypes, specialitiesMasterList} = useSelector((state: StateParams) => state.meta);
    const [hcpTypeSpecialities, setHcpTypeSpecialities] = useState<any>([]);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const [modifiedNcList, setModifiedNcList] = useState<any | null>([]);
    const [jobDescription,setJobDescription]=useState<any>('');
    const [jobBenifits,setJobBenifits]=useState<any>('');
    const [jobRequirements,setJobRequirements]=useState<any>('');

    const getNcList=useCallback(()=>{
        CommonService._api.get(ENV.API_URL + `user/lite?is_active=true&roles=account_manager&roles=nurse_champion&roles=hr&roles=operational_manager&roles=finance&roles=super_admin&roles=teric_admin`)
        .then((response:any)=>{
            console.log(response?.data,"DATA")
            const transformedData = response?.data?.map((item:any) => ({
                code: item?._id,
                title: item?.first_name + " " + item?.last_name,
            }));
            setModifiedNcList(transformedData || []);
        }).catch((error:any)=>{
            console.log(error)
        })
    },[])

    useEffect(() => {
        getNcList()
    }, [getNcList]);

    // useEffect(() => {
    //     const transformedData = ncList?.map(({_id, first_name, last_name, ...rest}) => ({
    //         code: _id,
    //         title: first_name + " " + last_name,
    //         ...rest, // Include other properties if needed
    //     }));
    //     setModifiedNcList(transformedData || []);
    // }, [ncList]);

    // useEffect(() => {
    //     dispatch(fetchNurseChampionListRequest(""))
    // }, [dispatch]);


    const getFacilityData = useCallback(() => {
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                //@ts-ignore
                const transformedData = res?.data?.map(({_id, name, ...rest}) => ({
                    code: _id,
                    title: name,
                    ...rest, // Include other properties if needed
                }));
                setFacilityList(transformedData || []);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getFacilityData()
    }, [getFacilityData]);

    const onSubmitBasicDetails = useCallback((values: any, {
        setSubmitting,
        setErrors,
    }: FormikHelpers<any>) => {
        setSubmitting(true);

        const payload = {
            ...values,
            workflow_status:'draft',
            is_clearance_required: values?.is_clearance_required === "Yes",
            job_description: jobDescription || '',
            job_benefits: jobBenifits || '',
            job_requirements: jobRequirements || '',
            posting_status: {
                website: values?.posting_status?.website === "Yes",
                mobile_app: values?.posting_status?.mobile_app === "Yes",
                is_job_boards: values?.posting_status?.is_job_boards === "Yes",
                job_boards: values?.posting_status?.job_boards || []
            }
        };
        if(payload?.job_type === 'per_diem'){
            payload.per_diem_details= {
                ...values?.per_diem_details,
                        start_time:CommonService.convertHoursToMinutes(values?.per_diem_details?.start_time ||''),
                        end_time:CommonService.convertHoursToMinutes(values?.per_diem_details?.end_time || ''),
            }
        }
        if(payload?.job_type === 'contract'){
            payload.contract_details= {
                    ...values?.contract_details,
                    start_time:CommonService.convertHoursToMinutes(values?.contract_details?.start_time ||''),
                    end_time:CommonService.convertHoursToMinutes(values?.contract_details?.end_time || ''),
            }
        }
        ApiService.post(ENV.API_URL + "ats/job", payload)
            .then((resp: any) => {
                CommonService.showToast(resp.message,'success');
                setApiDataAfterAdd(resp?.data)
                next()
                setSubmitting(false);
            })
            .catch((err: any) => {
                CommonService.handleErrors(setErrors,err);
                // CommonService.showToast(err.error,'error')
                setSubmitting(false);
            });
    }, [next,jobBenifits,jobRequirements,jobDescription,setApiDataAfterAdd])


    // const handleDatePicker = useCallback((dates: any) => {
    //     // Ensure dates are in ISO 8601 format
    //     setValue(dates);
    //     const formattedDates = Array.isArray(dates)
    //         ? dates.map((date) => format(new Date(date), 'yyyy-MM-dd'))
    //         : format(new Date(dates), 'yyyy-MM-dd');
    //     console.log('Formatted Dates:', formattedDates);
    //     setShiftDate(formattedDates)
    //     // Handle the formatted dates (e.g., set state or pass to form)
    // }, []);
    //
    // const handleShowHideCalender = () => {
    //     if (value) {
    //         if (value instanceof Array) {
    //             if (value.length === 0) {
    //                 return {display: "block"};
    //             } else {
    //                 return {display: "none"};
    //             }
    //         }
    //         return {
    //             display: "none",
    //         };
    //     }
    // };

    return (
        <>
            <div className={'job-basic-details-form'}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmitBasicDetails}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({values, handleChange, handleSubmit, setFieldValue,resetForm}) => (
                        <Form id="add-job-basic-details">
                            <AntdForm layout="vertical">
                                <div className={'job-main-header'}>Job Details:</div>
                                <div className={''}>
                                    <div className={'mrg-top-5 flex-equal'}>
                                        <FormLabelComponent Label={"Job Type"}/>
                                    </div>
                                    <div className={'flex-double mrg-top-10'}>
                                        <FormikSelectDropdownComponent
                                            placeholder={'Select Job type'}
                                            name={'job_type'}
                                            size={'middle'}
                                            allowClear={true}
                                            required={true}
                                            options={[
                                                {title: 'Contract', code: 'contract'},
                                                {title: 'Per Diem', code: 'per_diem'},
                                                {title: "Permanent", code: 'permanent'},
                                            ]}
                                            //  mode={'tags'}
                                            // label={`Multiple Select dropdown`}
                                            displayWith={(option) => option.title}
                                            valueExtractor={(option) => option.code}
                                            onUpdate={(value: any) => {
                                                resetForm();
                                                setJobDescription('');
                                                setJobBenifits('');
                                                setJobRequirements('');
                                            }}
                                        />
                                    </div>
                                </div>
                                <Divider/>
                                {values?.job_type ?
                                    <>
                                        <div className={''}>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Facility"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select Facility'}
                                                            name={'facility_id'}
                                                            options={facilityList || []}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Facility Manager"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select facility manager'}
                                                            name={'facility_manager_id'}
                                                            options={modifiedNcList || []}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Job Title*"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikInputComponent name="job_title" size={'middle'}
                                                                              required={true}
                                                                              placeholder={'Enter job title'}/>
                                                    </div>
                                                </div>
                                                <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Offerings"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select offering'}
                                                            name={'offerings'}
                                                            options={[
                                                                {
                                                                    title: 'Allied-Advanced Practice',
                                                                    code: 'allied_advanced_practice'
                                                                },
                                                                {title: 'Allied-Laboratory', code: 'allied_laboratory'},
                                                                {title: 'Allied-Others', code: 'allied_others'},
                                                                {title: 'Allied-Radiology', code: 'allied_radiology'},
                                                                {title: 'Allied-Rehab', code: 'allied_rehab'},
                                                                {title: 'IT', code: 'it'},
                                                                {title: 'Locums', code: 'locums'},
                                                                {title: 'Non-Clinical', code: 'non-clinical'},
                                                                {title: 'Nursing', code: 'nursing'},
                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Profession"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select profession'}
                                                            name={'staff_type'}
                                                            options={hcpTypes}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                            onUpdate={(e) => {
                                                                const selectedSpeciality = specialitiesMasterList[e];
                                                                setHcpTypeSpecialities(selectedSpeciality);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Speciality"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select speciality'}
                                                            name={'speciality'}
                                                            options={hcpTypeSpecialities}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Job Category"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select job category'}
                                                            name={'job_category'}
                                                            options={[
                                                                {title: 'None', code: 'none'},
                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Job Status"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select job status'}
                                                            name={'job_status'}
                                                            options={[
                                                                {title: 'Open', code: 'open'},
                                                                {title: 'Closed', code: 'closed'},
                                                                {title: 'Filled', code: 'filled'},
                                                                {title: 'Hold', code: 'hold'},
                                                                {title: 'In Progress', code: 'in_progress'},
                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Number of positions"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikInputComponent name="no_of_positions"
                                                                              size={'middle'}
                                                                              placeholder={'Enter no of positions'}/>
                                                    </div>
                                                </div>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Tax Terms"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select tax terms'}
                                                            name={'tax_terms'}
                                                            options={[
                                                                {title: '1099', code: '1099'},
                                                                {title: 'C2C', code: 'C2C'},
                                                                {title: 'C2H', code: 'C2H'},
                                                                {title: 'Contract-W2', code: 'Contract-W2'},
                                                                {title: 'Full Time', code: 'Full Time'},
                                                                {title: 'Intern', code: 'Intern'},
                                                                {title: 'Other', code: 'Other'},
                                                                {title: 'Part Time', code: 'Part Time'},
                                                                {title: 'Seasonal', code: 'Seasonal'},
                                                                {title: 'W-2', code: 'W-2'},
                                                                {title: 'W2', code: 'W2'},

                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Remote Job"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <AntdForm.Item>
                                                            <Radio.Group
                                                                className="custom-radio"
                                                                value={values.remote_job}
                                                                onChange={(e) => setFieldValue("remote_job", e.target.value)}
                                                            >
                                                                <Radio value="Yes">Yes</Radio>
                                                                <Radio value="No">No</Radio>
                                                                <Radio value="Hybrid">Hybrid</Radio>
                                                            </Radio.Group>
                                                        </AntdForm.Item>
                                                    </div>
                                                </div>
                                                <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Street"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikInputComponent name="address.street" size={'middle'}
                                                                              placeholder={'Enter street'}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"City"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikInputComponent name="address.city" size={'middle'}
                                                                              placeholder={'Enter city'}/>
                                                    </div>
                                                </div>
                                                <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"State"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select State'}
                                                            name={'address.state'}
                                                            options={regions}
                                                            required={true}
                                                            size={"middle"}
                                                            disabled={isLoading}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'d-flex'}>
                                                <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Country"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikInputComponent name="address.country" size={'middle'}
                                                                              placeholder={'Enter country'}/>
                                                    </div>
                                                </div>
                                                <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Zip Code"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikInputComponent name="address.zip_code" size={'middle'}
                                                                              placeholder={'Enter Zip Code'}
                                                                              maxLength={6}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {values?.job_type === 'permanent' &&
                                                <div className={'d-flex'}>
                                                <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Start Date"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikDatePickerComponent
                                                            // size={size}
                                                            name={'job_start_date'}
                                                            required
                                                            size={"middle"}
                                                            layout={'vertical'}
                                                            className="custom-class"
                                                        />

                                                    </div>
                                                </div>
                                                <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"End Date"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikDatePickerComponent
                                                            // size={size}
                                                            name={'job_end_date'}
                                                            required
                                                            size={"middle"}
                                                            layout={'vertical'}
                                                            className="custom-class"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {values?.job_type === 'per_diem' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Start Date"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikDatePickerComponent
                                                                // size={size}
                                                                name={'per_diem_details.start_date'}
                                                                required
                                                                size={"middle"}
                                                                layout={'vertical'}
                                                                className="custom-class"
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"End Date"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikDatePickerComponent
                                                                // size={size}
                                                                name={'per_diem_details.end_date'}
                                                                required
                                                                size={"middle"}
                                                                layout={'vertical'}
                                                                className="custom-class"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'contract' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Start Date"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikDatePickerComponent
                                                                // size={size}
                                                                name={'contract_details.start_date'}
                                                                required
                                                                size={"middle"}
                                                                layout={'vertical'}
                                                                className="custom-class"
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"End Date"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikDatePickerComponent
                                                                // size={size}
                                                                name={'contract_details.end_date'}
                                                                required
                                                                size={"middle"}
                                                                layout={'vertical'}
                                                                className="custom-class"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'per_diem' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Start Time"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikTimePickerComponent
                                                                name={'per_diem_details.start_time'}
                                                                className="time-wrapper"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"End Time"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikTimePickerComponent
                                                                name={'per_diem_details.end_time'}
                                                                className="time-wrapper"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'contract' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Start Time"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikTimePickerComponent
                                                                name={'contract_details.start_time'}
                                                                className="time-wrapper"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"End Time"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikTimePickerComponent
                                                                name={'contract_details.end_time'}
                                                                className="time-wrapper"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'contract' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"No. of Days per Week"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikSelectDropdownComponent
                                                                placeholder={'Select'}
                                                                name={'contract_details.days_per_week'}
                                                                options={weekDaysList}
                                                                required={true}
                                                                size={"middle"}
                                                                //  mode={'multiple'}
                                                                // label={`Multiple Select dropdown`}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.code}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"No. of Weeks"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <div className={'flex-double'}>
                                                                <FormikInputComponent
                                                                    name="contract_details.no_of_weeks"
                                                                    size={'middle'}
                                                                    required={true}
                                                                    placeholder={'No of Weeks'}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'contract' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Hours per Shift"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikSelectDropdownComponent
                                                                placeholder={'Select Duration'}
                                                                name={'contract_details.shift_duration'}
                                                                options={shiftDurationList}
                                                                required={true}
                                                                size={"middle"}
                                                                //  mode={'multiple'}
                                                                // label={`Multiple Select dropdown`}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.code}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Shift Type"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikSelectDropdownComponent
                                                                placeholder={'Select shift type'}
                                                                name={'contract_details.shift_type'}
                                                                options={[
                                                                    {title: 'AM', code: 'AM'},
                                                                    {title: 'PM', code: 'PM'},
                                                                    {title: 'NOC', code: 'NOC'},
                                                                    {title:'Flexible', code:'Flexible'}
                                                                ]}
                                                                required={true}
                                                                size={"middle"}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.code}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {values?.job_type === 'per_diem' &&
                                                <div className={'d-flex'}>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal mrg-right-20'}>
                                                        <div className={'mrg-top-5 flex-equal'}>
                                                            <FormLabelComponent Label={"Shift Type"}/>
                                                        </div>
                                                        <div className={'flex-double'}>
                                                            <FormikSelectDropdownComponent
                                                                placeholder={'Select Shift Type'}
                                                                name={'per_diem_details.shift_type'}
                                                                options={[
                                                                    {title: 'AM', code: 'AM'},
                                                                    {title: 'PM', code: 'PM'},
                                                                    {title: 'NOC', code: 'NOC'},
                                                                ]}
                                                                required={true}
                                                                size={"middle"}
                                                                displayWith={(option) => option.title}
                                                                valueExtractor={(option) => option.code}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'d-flex  mrg-bottom-10 flex-equal'}/>
                                                </div>
                                            }
                                        </div>
                                        <div className={'job-main-header'}>Job Description:</div>
                                        {/*<FormikTextareaComponent size={'middle'} name={'job_description'} rows={4}/>*/}
                                        <TextEditorComponent
                                            value={jobDescription}
                                            onChange={(value) => setJobDescription(value)}
                                            placeholder={'Enter job description'}
                                            className={'job-text-editor'}
                                        />

                                        <div className={'job-main-header'}>Job Benefits:</div>
                                        {/*<FormikTextareaComponent size={'middle'} name={'job_benefits'} rows={4}/>*/}
                                        <TextEditorComponent
                                            value={jobBenifits}
                                            onChange={(value) => setJobBenifits(value)}
                                            placeholder={'Enter job benefits'}
                                            className={'job-text-editor'}
                                        />

                                        <div className={'job-main-header'}>Job Requirements:</div>
                                        {/*<FormikTextareaComponent size={'middle'} name={'job_requirements'} rows={4}/>*/}
                                        <TextEditorComponent
                                            value={jobRequirements}
                                            onChange={(value) => setJobRequirements(value)}
                                            placeholder={'Enter job requirements'}
                                            className={'job-text-editor'}
                                        />


                                        <div className={'job-main-header'}>Skills:</div>
                                        <div className={'d-flex'}>
                                            <div className={'flex-equal pdd-right-85'}>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Degree"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select degree'}
                                                            name={'job_qualifications.degree'}
                                                            options={[
                                                                {title: 'Associate Degree', code: 'associate_degree'},
                                                                {title: 'Bachelors Degree', code: 'bachelors_degree'},
                                                                {title: 'HighSchool', code: 'high_school'},
                                                                {title: 'Masters Degree', code: 'masters_degree'},
                                                                {title: 'PHD', code: 'phd'},
                                                                {title: 'Undergraduate', code: 'under_graduate'},
                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Priority"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select Priority'}
                                                            name={'job_qualifications.priority'}
                                                            options={[
                                                                {title: 'Critical', code: 'critical'},
                                                                {title: 'High', code: 'high'},
                                                                {title: 'Low', code: 'low'},
                                                                {title: 'Medium', code: 'medium'},
                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            //  mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Languages"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select Languages'}
                                                            name={'job_qualifications.languages'}
                                                            options={languages}
                                                            required={true}
                                                            size={"middle"}
                                                            mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex-equal'}>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Experience"}/>
                                                    </div>
                                                    <div className={'flex-double d-flex'}>
                                                        <div className={'flex-equal'}>
                                                            <FormikInputComponent name="job_qualifications.min_experience"
                                                                                  size={'middle'} placeholder={'Minimum'}/>
                                                        </div>
                                                        <div className={'flex-equal mrg-left-10'}>
                                                            <FormikInputComponent name="job_qualifications.max_experience"
                                                                                  size={'middle'} placeholder={'Maximum'}/>
                                                        </div>
                                                        <div
                                                            className={'d-flex  justify-content-center mrg-left-10 mrg-top-5'}>
                                                            in years
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"Skills"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <FormikSelectDropdownComponent
                                                            placeholder={'Select skills'}
                                                            name={'job_qualifications.skills'}
                                                            options={[
                                                                {title: 'Skill1', code: 'skill1'},
                                                                {title: 'Skill2', code: 'skill2'},
                                                                {title: 'Skill3', code: 'skill3'},
                                                            ]}
                                                            required={true}
                                                            size={"middle"}
                                                            mode={'multiple'}
                                                            // label={`Multiple Select dropdown`}
                                                            displayWith={(option) => option.title}
                                                            valueExtractor={(option) => option.code}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className={'job-main-header'}>Posting Configuration:</div>
                                        <div className={'d-flex'}>
                                            <div className={'flex-equal'}>

                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"On Company Website"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <AntdForm.Item>
                                                            <Radio.Group
                                                                className="custom-radio"
                                                                value={values.posting_status.website}
                                                                onChange={(e) => setFieldValue("posting_status.website", e.target.value)}
                                                            >
                                                                <Radio value="Yes">Yes</Radio>
                                                                <Radio value="No">No</Radio>
                                                            </Radio.Group>
                                                        </AntdForm.Item>
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"On Mobile"}/>
                                                    </div>
                                                    <div className={'flex-double'}>
                                                        <AntdForm.Item>
                                                            <Radio.Group
                                                                className="custom-radio"
                                                                value={values.posting_status.mobile_app}
                                                                onChange={(e) => setFieldValue("posting_status.mobile_app", e.target.value)}
                                                            >
                                                                <Radio value="Yes">Yes</Radio>
                                                                <Radio value="No">No</Radio>
                                                            </Radio.Group>
                                                        </AntdForm.Item>
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-top-5 flex-equal'}>
                                                        <FormLabelComponent Label={"On Job Boards"}/>
                                                    </div>
                                                    <div className={'flex-double d-flex'}>
                                                        <div>
                                                            <AntdForm.Item>
                                                                <Radio.Group
                                                                    className="custom-radio"
                                                                    value={values.posting_status.is_job_boards}
                                                                    onChange={(e) => setFieldValue("posting_status.is_job_boards", e.target.value)}
                                                                >
                                                                    <Radio value="Yes">Yes</Radio>
                                                                    <Radio value="No">No</Radio>
                                                                </Radio.Group>
                                                            </AntdForm.Item>
                                                        </div>
                                                        <div className={'flex-1'} style={{width: "56%"}}>
                                                            {values.posting_status.is_job_boards === "Yes" &&
                                                                <div>
                                                                    <FormikSelectDropdownComponent
                                                                        placeholder={'Select job board'}
                                                                        name={'posting_status.job_boards'}
                                                                        options={[
                                                                            {title:'Adzuna',
                                                                            code:'adzuna'
                                                                            },
                                                                            {
                                                                                title: 'Career Builder',
                                                                                code: 'career_builder'
                                                                            },
                                                                            {title: 'Facebook', code: 'facebook'},
                                                                            {title: 'Google', code: 'google'},
                                                                            {title: 'GoodWork', code: 'good_work'},
                                                                            {title: 'Indeed', code: 'indeed'},
                                                                            {title: 'LinkedIn', code: 'linkedin'},
                                                                            {title: 'Monster', code: 'monster'},
                                                                            // {title: 'Open Jobs', code: 'openjobs'},
                                                                            {title: 'Wanderly', code: 'wanderly'},

                                                                        ]}
                                                                        required={true}
                                                                        size={"middle"}
                                                                        mode={'multiple'}
                                                                        // label={`Multiple Select dropdown`}
                                                                        displayWith={(option) => option.title}
                                                                        valueExtractor={(option) => option.code}
                                                                    />
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex-equal'}>
                                            </div>
                                        </div>
                                    </>:
                                    <div style={{
                                        textAlign:'center',
                                        fontWeight:600,
                                        height:'250px',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'
                                    }}>
                                        Please Select Job Type
                                    </div>
                                }

                            </AntdForm>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default JobBasicsDetailsForm;
