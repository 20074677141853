import "./PayRateComponent.scss";
import {FieldArray, useFormikContext} from "formik";
import React from "react";
import FormikInputComponent from "../../../../../ant-component/formik-input-component/FormikInputComponent";
import {ImageConfig} from "../../../../../../constants";
import {Button} from "@material-ui/core";

const PayRateComponent=()=>{
    const {values}=useFormikContext<any>();
    return (
        <div className={'pay-rate-component'}>
            {/*<div className={'mrg-bottom-10'}>*/}
            {/*    <div className={'mrg-top-5 flex-equal'}>*/}
            {/*        <FormLabelComponent Label={"Pay rate"}/>*/}
            {/*    </div>*/}
            {/*    <div className={'flex-double mrg-top-10 mrg-bottom-20'}>*/}
            {/*        <FormikInputComponent name="pay_rate.total_pay_rate" size={'middle'}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <FieldArray name="pay_rate.travel_per_diems">
                {({push, remove}) => (
                    <div className="pay-rate-card">
                            {values?.pay_rate?.travel_per_diems?.map((perDiem: any, index: number) => (
                                <div
                                    className="shift-row mrg-top-20">
                                    <FormikInputComponent
                                        name={`pay_rate.travel_per_diems.${index}.type`}
                                        disabled={index < 3}
                                        placeholder={'Enter Value'}
                                        className="time-wrapper"
                                    />
                                    <FormikInputComponent
                                        name={`pay_rate.travel_per_diems.${index}.rate`}
                                        placeholder={'Gross hourly Rate'}
                                        type={'number'}
                                        className="time-wrapper"
                                    />
                                    <div className={'d-flex'}
                                         style={{minWidth: "31.5%"}}>
                                        <FormikInputComponent
                                            name={`pay_rate.travel_per_diems.${index}.week_rate`}
                                            placeholder={'Gross Weekly Rate'}
                                            type={'number'}
                                            className="time-wrapper"
                                        />
                                        {(values?.pay_rate?.travel_per_diems?.length > 3 && index > 2) &&
                                            <div
                                                className="ts-col-1 ts-justify-content-center ts-align-content-center mrg-top-10 mrg-left-10">
                                                <div
                                                    onClick={() => {
                                                        remove(index)
                                                    }}
                                                    className={'cursor-pointer'}>
                                                    <img
                                                        src={ImageConfig.ContractDelete}
                                                        alt={ImageConfig.ContractDelete}/>
                                                </div>
                                            </div>}
                                    </div>

                                </div>
                            ))}
                                <div
                                    className={'d-flex justify-content-space-between mrg-top-10'}>
                                    <div
                                        className={'d-flex ts-justify-content-center align-items-center'}>
                                        <Button
                                            variant={"outlined"}
                                            color={"primary"}
                                            onClick={() =>
                                                push({
                                                    type: '',
                                                    rate: "",
                                                    week_rate: ""
                                                })
                                            }
                                        >
                                            Add More
                                        </Button>
                                    </div>
                                </div>
                    </div>
                )}
            </FieldArray>
        </div>
    )
}

export default PayRateComponent