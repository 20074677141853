import React, {useCallback, useEffect, useState} from 'react';
import {Radio, Checkbox, InputNumber, Row, Col, Divider} from 'antd';
import './ShiftRateComponent.scss';


interface shiftRateProps {
    facilityData?: any
    currentHCPType?: any
    setTotalShiftRate?: any
    setOtherRate?: any
    totalShiftRate?: any
    module?:string;
}

const ShiftRateComponent = (props: shiftRateProps) => {
    const {facilityData, setTotalShiftRate, setOtherRate,module='shift_creation'} = props;
    const [shiftType, setShiftType] = useState<string>('regular');
    const [shiftTiming, setShiftTiming] = useState<string[]>([]);
    const [hazardTimings, setHazardTimings] = useState<string[]>([]);
    const [rushRate, setRushRate] = useState<any>(0);
    const [NOCDiff, setNOCDiff] = useState<any>(0);
    const [PMDiff, setPMDiff] = useState<any>(0);
    const [weekendRate, setWeekendRate] = useState<any>(0);
    const [hazardRate, setHazardRate] = useState<any>(0);
    const [shiftRate, setShiftRate] = useState<any>(0);
    const [holidayRate,setHolidayRate]=useState<number>(0);

    //
    // useEffect(() => {
    //     if (!shiftTiming?.includes("PM")) {
    //         setPMDiff(Number(facilityData?.diff_rates?.pm) || 0)
    //     }
    //     if (!shiftTiming?.includes("NOC")) {
    //         setNOCDiff(Number(facilityData?.diff_rates?.noc) || 0)
    //     }
    //
    //     if (!shiftTiming?.includes("Weekend")) {
    //         setWeekendRate(Number(facilityData?.diff_rates?.weekend) || 0)
    //     }
    //
    //     if (!hazardTimings?.includes("Rush")) {
    //         setRushRate(Number(facilityData?.conditional_rates?.rush?.rate) || 0)
    //     }
    //
    //     if (!hazardTimings?.includes("Hazard")) {
    //         setHazardRate(Number(facilityData?.hourly_base_rates?.hazard) || 0)
    //     }
    // }, [hazardTimings, shiftTiming, facilityData]);

    useEffect(() => {
        setOtherRate((prevState: any) => ({
            ...prevState,
            pm: PMDiff,
            noc: NOCDiff,
            weekend: weekendRate,
            rush: rushRate,
            hazard: hazardRate,
            is_rush_rate: hazardTimings?.includes("Rush"),
            is_noc_diff: shiftTiming?.includes("NOC"),
            is_pm_diff: shiftTiming?.includes("PM"),
            is_weekend_rate: shiftTiming?.includes("Weekend"),
            is_hazard_rate: hazardTimings?.includes("Hazard"),
            is_holiday: shiftType === "holiday",
            base_shift_rate: shiftRate
        }));
    }, [PMDiff, NOCDiff, weekendRate, rushRate, hazardRate, setOtherRate, hazardTimings, shiftTiming, shiftType, shiftRate]);

    const calculateTotalShiftRate = useCallback(() => {
        // Coerce all values to numbers
        const baseShiftRate = Number(shiftRate);
        const finalHolidayRate =module !== 'job_creation' ? Number(facilityData?.pay_hourly_base_rates?.holiday || 0) : Number(holidayRate || 0);
        const hazardRates = Number(hazardRate || 0);

        let totalShiftRatesInitial = 0;

        if (shiftTiming?.includes("PM")) {
            totalShiftRatesInitial += PMDiff;
        }
        if (shiftTiming?.includes("NOC")) {
            totalShiftRatesInitial += NOCDiff;
        }

        if (shiftTiming?.includes("Weekend")) {
            totalShiftRatesInitial += weekendRate;
        }

        if (hazardTimings?.includes("Rush")) {
            totalShiftRatesInitial += rushRate;
        }

        if (hazardTimings?.includes("Hazard")) {
            totalShiftRatesInitial += hazardRates;
        }

        if (shiftType === "holiday") {
            totalShiftRatesInitial += (finalHolidayRate * baseShiftRate);
        }
        if (shiftType === "regular") {
            totalShiftRatesInitial += baseShiftRate;
        }


        setTotalShiftRate(totalShiftRatesInitial);


        // if (isRushRate) totalShiftRate += rushRateValue;
        // if (isNocRate) totalShiftRate += nocDiff;
        // if (isPmRate) totalShiftRate += pmDiff;
        // if (isWeekendRate) totalShiftRate += weekendDiff;
        return totalShiftRatesInitial
    }, [
        setTotalShiftRate,
        facilityData,
        rushRate,
        NOCDiff,
        PMDiff,
        weekendRate,
        hazardRate,
        shiftRate,
        shiftType,
        shiftTiming,
        hazardTimings,
        module,
        holidayRate
    ]);

    // const calculateFinalRate = () => {
    //     const baseRate = shiftType === 'holiday' ? rates.base * rates.multiplier : rates.base;
    //     const timingRate = shiftTiming === 'PM' ? rates.pm : rates[shiftTiming?.toLowerCase() as keyof typeof rates];
    //     const hazardRate = hazardTimings?.reduce((sum, timing) => sum + rates[timing?.toLowerCase() as keyof typeof rates], 0);
    //
    //     return baseRate + timingRate + hazardRate;
    // };

    const handleShiftTimingChange = (checkedValues: string[]) => {
        setShiftTiming(checkedValues); // Update state with multiple selected timings
    };

    const handleHazardShiftTimingChange = (checkedValues: string[]) => {
        setHazardTimings(checkedValues); // Update state with multiple selected timings
    };

    // useEffect(() => {
    //     const key = currentHCPType?.facility_code
    //     if (currentHCPType && facilityData) {
    //         const foundItem = facilityData?.hourly_base_rates[key];
    //         setShiftRate(Number(foundItem) || 0);
    //     }
    // }, [currentHCPType, facilityData]);


    // useEffect(() => {
    //     if (currentHCPType?.facility_code && facilityData?.hourly_base_rates) {
    //         const key = currentHCPType.facility_code;
    //         const foundItem = facilityData.hourly_base_rates[key];
    //         setShiftRate(Number(foundItem) || 0);
    //     } else {
    //         setShiftRate(0); // Fallback value when data is missing
    //     }
    // }, [currentHCPType, facilityData]);


    return (
        <div className="shift-rate-container">
            <div style={{width: "100%"}}>
                {/*<div>Shift rate</div>*/}
                {/* Shift Type */}
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <div className={'d-flex justify-content-space-between'}>
                            <div>
                                <div className={'shift-header-holiday'}>Shift type
                                </div>
                                <Radio.Group
                                    className="custom-radio"
                                    value={shiftType}
                                    onChange={(e) => setShiftType(e.target.value)}
                                    style={{display: 'flex', gap: '1rem', marginTop: '0.5rem'}}
                                >
                                    <Radio value="regular">Regular shift</Radio>
                                    <Radio value="holiday">Holiday multiplier Rate</Radio>
                                </Radio.Group>
                            </div>
                            <div className={'d-flex mrg-top-20'}>

                                <div className={`${shiftType === "holiday" ? "mrg-right-5" : ""}`}>
                                    <InputNumber
                                        prefix="$"
                                        value={shiftRate}
                                        style={{width: '100%'}}
                                        onChange={(e) => {
                                            setShiftRate(e)
                                        }}
                                    />
                                </div>
                                {shiftType === "holiday" &&
                                    <div className={'mrg-right-5 disabled-color'}>
                                        < InputNumber
                                            className="custom-antd-input-number"
                                            disabled={module !== 'job_creation'}
                                            value={module !== 'job_creation' ? `x ${facilityData?.pay_hourly_base_rates?.holiday || 0}` : holidayRate}
                                            style={{width: '100%'}}
                                            onChange={(value:any)=>{
                                                if(module === 'job_creation') {
                                                    setHolidayRate(value)
                                                }
                                            }}
                                        />
                                    </div>}
                                {shiftType === "holiday" &&
                                    <div className={'disabled-color'}>
                                        <InputNumber
                                            className="custom-antd-input-number"
                                            disabled
                                            prefix="$"
                                            value={
                                            module !== 'job_creation'? (facilityData?.pay_hourly_base_rates?.holiday || 0) * shiftRate : holidayRate * shiftRate}
                                            style={{width: '100%'}}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        {/*<*/}
                    </Col>

                    {/* Base Rate */}

                </Row>
                <Divider/>


                {/* Shift Timing */}
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <div className={'d-flex justify-content-space-between'}>
                            <div style={{width: "100%"}}>
                                {/*<div>*/}
                                {/*    <b style={{color: "black", marginBottom: "10px"}}>Shift timing (Multiple*/}
                                {/*        select):</b>*/}
                                {/*</div>*/}
                                <Checkbox.Group
                                    className="custom-checkbox"
                                    value={shiftTiming}
                                    onChange={handleShiftTimingChange}
                                    style={{display: "flex", flexDirection: "column", gap: '1rem', marginTop: '0.5rem'}}
                                >
                                    <div className={'d-flex justify-content-space-between'}>
                                        <div>
                                            <Checkbox value="PM">PM</Checkbox>
                                        </div>
                                        <div className={`${hazardTimings?.includes("PM") ? "" : "disabled-color"}`}>
                                            <InputNumber
                                                className="custom-antd-input-number"
                                                min={0} // Set minimum to 0
                                                max={1000}
                                                disabled={!shiftTiming?.includes("PM")}
                                                prefix="$"
                                                value={PMDiff}
                                                style={{width: '100%'}}
                                                onChange={(e) => {
                                                    setPMDiff(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={'d-flex justify-content-space-between'}>
                                        <div>
                                            <Checkbox value="NOC">NOC</Checkbox>
                                        </div>
                                        <div className={`${hazardTimings?.includes("NOC") ? "" : "disabled-color"}`}>
                                            <InputNumber
                                                className="custom-antd-input-number"
                                                min={0} // Set minimum to 0
                                                max={1000}
                                                disabled={!shiftTiming?.includes("NOC")}
                                                prefix="$"
                                                value={NOCDiff}
                                                style={{width: '100%'}}
                                                onChange={(e) => {
                                                    setNOCDiff(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={'d-flex justify-content-space-between'}>
                                        <div>
                                            <Checkbox value="Weekend">Weekend</Checkbox>
                                        </div>
                                        <div
                                            className={`${hazardTimings?.includes("Weekend") ? "" : "disabled-color"}`}>
                                            <InputNumber
                                                className="custom-antd-input-number"
                                                min={0} // Set minimum to 0
                                                max={1000}
                                                disabled={!shiftTiming?.includes("Weekend")}
                                                prefix="$"
                                                value={weekendRate}
                                                style={{width: '100%'}}
                                                onChange={(e) => {
                                                    setWeekendRate(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Checkbox.Group>
                            </div>
                        </div>
                    </Col>
                </Row>

                {/*<Divider/>*/}
                {/* Hazard Shift Timing */}
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <div className={'d-flex justify-content-space-between'}>
                            <div style={{width: "100%", marginTop: '0.5rem'}}>
                                {/*<div>*/}
                                {/*    <b style={{color: "black", marginBottom: "10px"}}>Hazard Shift Timing (Multiple*/}
                                {/*        select):</b>*/}
                                {/*</div>*/}
                                <Checkbox.Group
                                    className="custom-checkbox"
                                    value={hazardTimings}
                                    onChange={handleHazardShiftTimingChange}
                                    style={{display: 'flex', flexDirection: "column", gap: '1rem', marginTop: '0.5rem'}}
                                >
                                    <div className={'d-flex justify-content-space-between'}>
                                        <div>
                                            <Checkbox value="Rush">Rush Rate</Checkbox>
                                        </div>
                                        <div className={`${hazardTimings?.includes("Rush") ? "" : "disabled-color"}`}>
                                            <InputNumber
                                                className="custom-antd-input-number"
                                                min={0} // Set minimum to 0
                                                max={1000}
                                                disabled={!hazardTimings?.includes("Rush")}
                                                prefix="$"
                                                value={rushRate}
                                                style={{width: '100%'}}
                                                onChange={(e) => {
                                                    setRushRate(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={'d-flex justify-content-space-between'}>
                                        <div>
                                            <Checkbox value="Hazard">Hazard Rate</Checkbox>
                                        </div>
                                        <div className={`${hazardTimings?.includes("Hazard") ? "" : "disabled-color"}`}>
                                            <InputNumber
                                                className="custom-antd-input-number"
                                                min={0} // Set minimum to 0
                                                max={1000}
                                                prefix="$"
                                                disabled={!hazardTimings?.includes("Hazard")}
                                                value={hazardRate}
                                                style={{width: '100%'}}
                                                onChange={(e) => {
                                                    setHazardRate(e)
                                                }}
                                            />
                                        </div>
                                    </div>

                                </Checkbox.Group>
                            </div>
                        </div>

                    </Col>
                </Row>
                <Divider/>
                {/* Final Rate */}

                <div className={"d-flex justify-content-space-between"}>
                    <div className={'d-flex justify-content-center align-items-center'}>
                        <b style={{color: "black"}}>Final shift rate</b>
                    </div>
                    <div className={'disabled-color'}>
                        <InputNumber
                            className="custom-antd-input-number"
                            disabled
                            prefix="$"
                            value={calculateTotalShiftRate()}
                            style={{width: '100%'}}
                            onChange={(e) => {
                                setTotalShiftRate(e)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShiftRateComponent;
