import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent"
import {defaultTableColumnList} from "../defaultTableColumnList";

const ApplicationsComponent=()=>{
    return (
        <div className={'pipeline-table-wrapper'}>
            <AntTableComponent
                data={[]}
                columns={defaultTableColumnList || []}
                extraPayload={[]}
                noDataText={'No Applications Data Found.'}
            />
        </div>
    )
}
export default ApplicationsComponent;