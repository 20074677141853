import "./BillRateComponent.scss";
import React from "react";
import {FieldArray, useFormikContext} from "formik";
import FormikInputComponent from "../../../../../ant-component/formik-input-component/FormikInputComponent";
import {Button} from "@material-ui/core";
import {ImageConfig} from "../../../../../../constants";

interface BillRateComponentProps{
}

const BillRateComponent=(props:BillRateComponentProps)=>{
    const {values}=useFormikContext<any>();

    return (
        <div className={'pay-rate-component'}>
            <FieldArray name="bill_rate.bill_rate">
                {({push, remove}) => (
                    <div className="pay-rate-card">
                        {values?.bill_rate?.bill_rate?.map((item: any, index: number) => (
                            <div
                                className="shift-row mrg-top-20">
                                <FormikInputComponent
                                    name={`bill_rate.bill_rate.${index}.type`}
                                    disabled={index < 3}
                                    placeholder={'Enter Value'}
                                    className="time-wrapper"
                                />
                                <FormikInputComponent
                                    name={`bill_rate.bill_rate.${index}.rate`}
                                    placeholder={'$/hr'}
                                    type={'number'}
                                    className="time-wrapper"
                                />
                                <div className={'d-flex'}
                                     style={{minWidth: "31.5%"}}>
                                    <FormikInputComponent
                                        name={`bill_rate.bill_rate.${index}.week_rate`}
                                        placeholder={'$/week'}
                                        type={'number'}
                                        className="time-wrapper"
                                    />
                                    {(values?.bill_rate?.bill_rate?.length > 2 && index >2) &&
                                        <div
                                            className="ts-col-1 ts-justify-content-center ts-align-content-center mrg-top-5 mrg-left-10">
                                            <div
                                                onClick={() => {
                                                    remove(index)
                                                }}
                                                className={'cursor-pointer'}>
                                                <img
                                                    src={ImageConfig.ContractDelete}
                                                    alt={ImageConfig.ContractDelete}/>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        ))}
                        <div
                            className={'d-flex justify-content-space-between mrg-top-10'}>
                            <div
                                className={'d-flex ts-justify-content-center align-items-center'}>
                                <Button
                                    variant={"outlined"}
                                    color={"primary"}
                                    onClick={() =>
                                        push({
                                            type: '',
                                            rate: "",
                                            week_rate: ""
                                        })
                                    }
                                >
                                    Add More
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </FieldArray>
        </div>
    )
}

export default BillRateComponent