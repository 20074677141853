import "./BillRateTabComponent.scss";
import FormLabelComponent from "../../../../../ant-component/form-label/FormLabelComponent";
import FormikInputComponent from "../../../../../ant-component/formik-input-component/FormikInputComponent";
import React from "react";

const BillRateTabComponent=()=>{
    return (
        <div className={'bill-rate-component'}>
            <div className={'d-flex salary-range-wrapper'}>
                <div className={'d-flex flex-equal'}>
                    <div className={'mrg-top-5 mrg-right-20'}>
                        <FormLabelComponent Label={"Bill Rate / Year"}/>
                    </div>
                    <div className={'flex-equal'}>
                        <FormikInputComponent name="bill_rate.total_bill_rate" size={'middle'}
                                              type={"number"}
                                              prefix={"$"}/>
                    </div>
                </div>
                <div className={'d-flex flex-equal'}>
                    <div className={'mrg-top-5 mrg-right-10'}>
                        <FormLabelComponent Label={"Salary / Year"}/>
                    </div>
                    <div className={'flex-equal'}>
                        <FormikInputComponent name="bill_rate.salary" size={'middle'}
                                              type={"number"}
                                              prefix={"$"}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillRateTabComponent