import "./SingleEmailEditorComponent.scss";
import ChipInputComponent from "../../../../components/chip-input/ChipInputComponent";
import InputComponent from "../../../ant-component/input/InputComponent";
import TextEditorComponent from "../../../../components/text-editor/TextEditorComponent";
import {CloseOutlined, DeleteOutlined, SendOutlined} from "@ant-design/icons";
import {Button} from "antd";
import React, {useCallback, useState} from "react";
import {ApiService, CommonService} from "../../../../helpers";
import {ENV} from "../../../../constants";
import FilePickerComponent from "../../../../components/file-picker/FilePickerComponent";


interface SingleEmailEditorComponentProps{
    recipientEmail?:string;
    setOpenEmailDrawer?:any;
}

const SingleEmailEditorComponent=(props:SingleEmailEditorComponentProps)=>{
    const {recipientEmail,setOpenEmailDrawer}=props;
    const [showCC,setShowCC]=useState<boolean>(false);
    const [showBCC,setShowBCC]=useState<boolean>(false);
    const [subject,setSubject]=useState<string>("");
    const [emailMessage,setEmailMessage]=useState<any>(null);
    const [ccEmails,setCCEmails]=useState<any>([]);
    const [bccEmails,setBCCEmails]=useState<any>([]);
    const [attachment,setAttachment]=useState<any>(null)
    const [isSubmitting,setIsSubmitting]=useState<boolean>(false);

    const handleCCOpenClose = () => {
        if (showCC) {
            setCCEmails([]);
        }
        setShowCC(!showCC);
    };

    const handleBCCOpenClose = () => {
        if (showBCC) {
            setBCCEmails([]);
        }
        setShowBCC(!showBCC);
    };

    const handleSubmit=useCallback(()=>{
        setIsSubmitting(true)
        const combinedRecipientEmails = [recipientEmail || ''];
        const ccEmailsList = ccEmails || [];
        const bccEmailsList = bccEmails || [];
        const formData = new FormData();
        if (attachment) {
            formData.append('attachment', attachment);
        }
        combinedRecipientEmails?.forEach((email:any,index:number)=>{
            formData.append(`email[${index}]`,email || "");
        })
        ccEmailsList?.forEach((email:any,index:number)=>{
            formData.append(`cc[${index}]`,email || "");
        })
        bccEmailsList?.forEach((email:any,index:number)=>{
            formData.append(`bcc[${index}]`,email || "");
        })
        formData.append('subject',subject || "");
        formData.append('emailMessage',emailMessage || "");


        ApiService.upload(ENV.API_URL + "ats/bulkEmail",formData)
            .then((response)=>{
                CommonService.showToast(response.message,"success");
                setOpenEmailDrawer(false);
            }).catch((error:any)=>{
            CommonService.showToast(error.error || error.message || "Error occurred while sending email",'error')
        }).finally(()=>{
            setIsSubmitting(false)
        })
    },[recipientEmail,ccEmails,bccEmails,subject,emailMessage,attachment,setOpenEmailDrawer])

    const handleClear=useCallback(()=>{
        setAttachment(null)
        setEmailMessage("");
        setSubject("");
        setCCEmails([]);
        setBCCEmails([]);
    },[])

    return (
        <div className={'single-email-editor-component'}>
            <div className={'single-email-box'}>
                <div className={'email-editor-wrapper'}>
                <div className={'email-editor'}>
                    <ChipInputComponent
                        placeholder={'Recipients'}
                        title={'Recipients'}
                        selectedEmails={[recipientEmail || '']}
                        showCC={showCC}
                        showBCC={showBCC}
                        disabled={true}
                        isSingleEmail={true}
                        handleCCToggle={handleCCOpenClose}
                        handleBCCToggle={handleBCCOpenClose}
                    />
                    {showCC &&
                        <ChipInputComponent
                            placeholder={'CC'}
                            manualEmails={ccEmails}
                            setManualEmails={setCCEmails}
                            title={"CC"}
                        />
                    }
                    {showBCC &&
                        <ChipInputComponent
                            placeholder={'BCC'}
                            manualEmails={bccEmails}
                            setManualEmails={setBCCEmails}
                            title={'BCC'}
                        />
                    }
                    <div>
                        <InputComponent
                            placeholder={'Subject'}
                            value={subject}
                            className={'email-subject-input'}
                            onChange={(value: string) => {
                                setSubject(value)
                            }}
                        />
                    </div>
                    <div className={'email-quill-wrapper'}>
                        <TextEditorComponent
                            value={emailMessage}
                            onChange={(value: any) => {
                                setEmailMessage(value)
                            }}
                        />
                    </div>
                    <div className={'email-attachment-wrapper'}>
                        <div>
                            {!attachment &&
                                <FilePickerComponent
                                    variant={'outlined'}
                                    height={'470px'}
                                    id={" sc_upload_btn"}
                                    btnText={" Add Attachment"}
                                    multiple={false}
                                    showDropZone={false}
                                    onFilesDrop={(acceptedFiles: any, rejectedFiles: any) => {
                                        if (acceptedFiles && acceptedFiles.length > 0) {
                                            const file = acceptedFiles[0];
                                            //   setTimesheetFile(file);
                                            setAttachment(file);
                                        }
                                    }}
                                    acceptedFileTypes={["pdf", "jpeg", "jpg", "png", "doc", "docx"]}
                                    uploadDescription={"(upload only one file)"}
                                />}
                            {attachment &&
                                <div className={'file-preview-wrapper'}>
                                    {attachment?.name}
                                    <div className={'file-preview-close-icon'} onClick={() => setAttachment(null)}>
                                        <CloseOutlined/>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={'email-btn-wrapper'}>
                        <Button onClick={handleClear}
                                icon={<DeleteOutlined/>}
                                iconPosition={'start'}
                                className={'delete-btn'}
                        >
                        </Button>
                        <Button
                            type="primary"
                            className="email-save-button"
                            icon={<SendOutlined/>}
                            iconPosition={'end'}
                            loading={isSubmitting}
                            onClick={handleSubmit}
                        >
                            Send
                        </Button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="email-footer">

            </div>
        </div>
    )
}

export default SingleEmailEditorComponent