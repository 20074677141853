import './MyPipeLineListComponent.scss';
import React,{useCallback,useState} from "react";
import {Tabs} from 'antd';
import type { TabsProps } from 'antd';
import ActiveComponent from "../active/ActiveComponent";
import BookedComponent from "../booked/BookedComponent";
import PermStatsComponent from "../permstats/PermStatsComponent";
import SubmittalsComponent from "../submittals/SubmittalsComponent";
import ExtensionsComponent from "../extensions/ExtensionsComponent";
import AvailabilityLogComponent from "../availability-log/AvailabilityLogComponent";
import HotListComponent from "../hot-list/HotListComponent";
import WatchListComponent from "../watch-list/WatchListComponent";
import ApplicationsComponent from "../applications/ApplicationsComponent";
import PendingComponent from "../pending/PendingComponent";
import ProspectComponent from "../prospect/ProspectComponent";
import ManageTasksComponent from "../manage-tasks/ManageTasksComponent";
import ExpiringCredentialsComponent from "../expiring-credentials/ExpiringCredentialsComponent";


const MyPipeLineListComponent=()=>{
    const [activeTab,setActiveTab]=useState<string>('active')


    const pipeLineTabs: TabsProps['items'] = [
        { key: 'active', label: 'Active', children: <ActiveComponent/> },
        { key: 'booked', label: 'Booked', children: <BookedComponent/> },
        { key: 'perm-stats', label: 'Perm Stats', children: <PermStatsComponent/> },
        { key: 'submittals', label: 'Submittals', children: <SubmittalsComponent/> },
        { key: 'extensions', label: 'Extensions', children: <ExtensionsComponent/> },
        { key: 'availability-log', label: 'Availability Log', children: <AvailabilityLogComponent/> },
        { key: 'hot-list', label: 'Hot List', children: <HotListComponent/> },
        { key: 'watch-list', label: 'Watch List', children: <WatchListComponent/> },
        { key: 'applications', label: 'Applications', children: <ApplicationsComponent/> },
        { key: 'pending', label: 'Pending', children: <PendingComponent/> },
        { key: 'prospect', label: 'Prospect', children: <ProspectComponent/> },
        { key: 'manage-tasks', label: 'Manage Tasks', children: <ManageTasksComponent/> },
        { key: 'expiring-credentials', label: 'Expiring Credentials', children: <ExpiringCredentialsComponent/> },
    ];



    const handleTabChange = useCallback((key: string) => {
        setActiveTab(key);
    },[])

    return (
        <>
            <div className={"pipeline-list-component screen crud-layout pdd-30 pdd-top-10"}>
                <div className="custom-border pdd-10 pdd-top-20 pdd-bottom-0">
                    <div className="pipeline-tab-wrapper">
                        <Tabs
                            defaultActiveKey={activeTab}
                            items={pipeLineTabs}
                            onChange={handleTabChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default MyPipeLineListComponent