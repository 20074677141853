import React from 'react';
import {Select} from 'antd';
import './SelectComponent.scss';
import FormItemComponent, {CommonFormInputProps, extractCommonPropsFromInput} from "../formItem/FormItemComponent";

const {Option} = Select;

interface OptionType {
    title: string;
    code: string | number;
    disabled?: boolean;
}

export interface SelectDropdownComponentProps extends CommonFormInputProps {
    options: OptionType[];
    label?: string;
    placeholder?: string;
    className?: string;
    onChange?: (value: any) => void;
    value: any;
    mode?: "multiple" | "tags";
    displayWith?: (option: any) => void;
    valueExtractor?: (option: any) => void;
    disableOptions?: (item: any) => boolean;
    size?: 'large' | 'middle' | 'small';
    disabled?: boolean;
    suffixIcon?: React.ReactNode;
    onBlur?: (event: any) => void;
    allowClear?:boolean;
}

const SelectDropdownComponent: React.FC<SelectDropdownComponentProps> = (props) => {
    const {
        options,
        placeholder = "Select an option",
        className = '',
        onChange,
        value,
        mode,
        // displayWith = ((option) => option?.label),
        valueExtractor = ((option) => option?.code),
        disableOptions = (item) => item?.disabled,
        size,
        disabled,
        suffixIcon,
        onBlur,
        allowClear
    } = props;


    const handleChange = (value: string | number) => {
        if (onChange) {
            onChange(value);
        }
    };

    function displayWith(option: any): React.ReactNode {
        return option ? option?.title : "No option";
    }

    return (
        <FormItemComponent {...extractCommonPropsFromInput(props)}>
            <Select
                showSearch
                allowClear={allowClear}
                placeholder={placeholder}
                className={`select-dropdown-component ${className}`}
                data-testid={'select-dropdown-component'}
                value={value || []}
                maxTagCount={'responsive'}
                mode={mode}
                size={size}
                disabled={disabled}
                onChange={handleChange}
                suffixIcon={suffixIcon}
                onBlur={onBlur}
                filterOption={(input, option) =>
                    option?.children
                        ? option.children.toString().toLowerCase().includes(input.toLowerCase())
                        : false
                }
            >
                {options.map((option) => (
                    //@ts-ignore
                    <Option key={valueExtractor(option)}
                        //Note: Disable function will not work on tags, since user can always type text as tag
                            disabled={mode !== 'tags' ? disableOptions(option) : undefined}
                            value={valueExtractor(option)}>
                        {displayWith(option)}
                    </Option>
                ))}
            </Select>
        </FormItemComponent>
    );
};

export default SelectDropdownComponent;


//USE CASE
// const options = [
//     {label: 'Option 1', value: '1'},
//     {label: 'Option 2', value: '2'},
//     {label: 'Option 3', value: '3'},
// ];

// <SelectComponent options={options}/>
