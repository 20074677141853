import {ColumnsType} from "antd/es/table";

export const extensionTableColumnList:ColumnsType=[
    {
        title: "First Name",
        width: 150,
        render: (item: any) => <div>{item.firstName}</div>,
    },
    {
        title: "Last Name",
        width: 150,
        render: (item: any) => <div>{item.lastName}</div>,
    },
    {
        title: "Client Name",
        width: 200,
        render: (item: any) => <div>{item.clientName}</div>,
    },
    {
        title: "End Date",
        width: 150,
        render: (item: any) => <div>{item.endDate}</div>,
    },
    {
        title: "Job Id",
        width: 150,
        render: (item: any) => <div>{item.jobId}</div>,
    },
    {
        title: "Housing",
        width: 150,
        render: (item: any) => <div>{item.housing}</div>,
    },
    {
        title: "Benefits",
        width: 150,
        render: (item: any) => <div>{item.benefits}</div>,
    },
    {
        title: "GP%",
        width: 100,
        render: (item: any) => <div>{item.gpPercentage}</div>,
    },
    {
        title: "D/E",
        width: 100,
        render: (item: any) => <div>{item.de}</div>,
    },
    {
        title: "Speciality",
        width: 150,
        render: (item: any) => <div>{item.speciality}</div>,
    },
    {
        title: "Candidate Extending",
        width: 180,
        render: (item: any) => <div>{item.candidateExtending}</div>,
    },
    {
        title: "Request Date",
        width: 150,
        render: (item: any) => <div>{item.requestDate}</div>,
    },
    {
        title: "Notes",
        width: 250,
        render: (item: any) => <div>{item.notes}</div>,
    },
    {
        title: "Requests",
        width: 150,
        render: (item: any) => <div>{item.requests}</div>,
    },
    {
        title: "Client Extending",
        width: 180,
        render: (item: any) => <div>{item.clientExtending}</div>,
    },
    {
        title: "Sales Rep.",
        width: 150,
        render: (item: any) => <div>{item.salesRep}</div>,
    },
    {
        title: "Recruiter",
        width: 150,
        render: (item: any) => <div>{item.recruiter}</div>,
    },
    {
        title: "Profession",
        width: 150,
        render: (item: any) => <div>{item.profession}</div>,
    },
]