import "./PerDiemRateCardComponent.scss";
import {useCallback, useEffect, useState} from "react";
import * as Yup from "yup";
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import PayRateTabComponent from "./pay-rate/PayRateComponent";
import BillRateTabComponent from "./bill-rate/BillRateComponent";
import {Form, Formik, FormikHelpers} from "formik";
import {ApiService, CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import {useHistory} from "react-router-dom";


interface PerDiemRateCardComponentProps{
    apiDataAfterAdd?:any;
    rate_details?:any
}
const validationSchema = Yup.object().shape({

});
const PerDiemRateCardComponent=(props:PerDiemRateCardComponentProps)=>{
    const {apiDataAfterAdd,rate_details}=props;
    const history=useHistory();
    const [activeTab,setActiveTab]=useState<string>('pay_rate')
    const [totalPayRate,setTotalPayRate]=useState<number>(0);

    const updateInitialValues = useCallback(() => {

        return {
            pay_rate: {
                total_pay_rate: rate_details?.per_diem_rate_card?.pay_rate?.total_pay_rate || '',
                is_shift_rate: rate_details?.per_diem_rate_card?.pay_rate?.is_shift_rate ?? false,
                is_holiday_multiplier_rate: rate_details?.per_diem_rate_card?.pay_rate?.is_holiday_multiplier_rate ?? false,
                is_pm_diff_rate: rate_details?.per_diem_rate_card?.pay_rate?.is_pm_diff_rate ?? false,
                is_noc_diff_rate: rate_details?.per_diem_rate_card?.pay_rate?.is_noc_diff_rate ?? false,
                is_weekend_rate: rate_details?.per_diem_rate_card?.pay_rate?.is_weekend_rate ?? false,
                is_rush_rate: rate_details?.per_diem_rate_card?.pay_rate?.is_rush_rate ?? false,
                is_ot_pay_rate: rate_details?.per_diem_rate_card?.pay_rate?.is_ot_pay_rate ?? false,
                is_hazard_rate: rate_details?.per_diem_rate_card?.pay_rate?.is_hazard_rate ?? false,
                is_dt_pay_rate: rate_details?.per_diem_rate_card?.pay_rate?.is_dt_pay_rate ?? false,
                shift_rate: rate_details?.per_diem_rate_card?.pay_rate?.shift_rate || { rate: '', final_rate: '' },
                holiday_multiplier_rate: rate_details?.per_diem_rate_card?.pay_rate?.holiday_multiplier_rate || { rate: '', final_rate: '' },
                pm_diff_rate: rate_details?.per_diem_rate_card?.pay_rate?.pm_diff_rate || { rate: '', final_rate: '' },
                noc_diff_rate: rate_details?.per_diem_rate_card?.pay_rate?.noc_diff_rate || { rate: '', final_rate: '' },
                weekend_rate: rate_details?.per_diem_rate_card?.pay_rate?.weekend_rate || { rate: '', final_rate: '' },
                rush_rate: rate_details?.per_diem_rate_card?.pay_rate?.rush_rate || { rate: '', final_rate: '' },
                hazard_rate: rate_details?.per_diem_rate_card?.pay_rate?.hazard_rate || { rate: '', final_rate: '' },
                ot_pay_rate: rate_details?.per_diem_rate_card?.pay_rate?.ot_pay_rate || { rate: '', final_rate: '' },
                dt_pay_rate: rate_details?.per_diem_rate_card?.pay_rate?.dt_pay_rate || { rate: '', final_rate: '' },
            },
            bill_rate:{
                bill_rate:rate_details?.per_diem_rate_card?.bill_rate?.bill_rate || [
                    {
                        type:'Bill Rate',
                        rate:"",
                        week_rate:"",
                    },
                    {
                        type:'OT Bill Rate',
                        rate:"",
                        week_rate:"",
                    },{
                        type:'DT Bill Rate',
                        rate:"",
                        week_rate:"",
                    },
                ]
            },
        };
    }, [rate_details]);



    useEffect(()=>{
        if(rate_details){
            setTotalPayRate(rate_details?.per_diem_rate_card?.pay_rate?.total_pay_rate)
        }
    },[rate_details])

    const TabColumn: TabsProps['items'] = [
        {
            key: 'pay_rate',
            label: 'Pay Rate',
            children: <PayRateTabComponent totalPayRate={totalPayRate} setTotalPayRate={setTotalPayRate}/>,
        },
        {
            key: 'bill_rate',
            label: 'Bill Rate',
            children: <BillRateTabComponent/>,
        },

    ];

    const handleTabChange=useCallback((key: string)=>{
        setActiveTab(key)
    },[])

    const onSubmit=useCallback((values: any, { setErrors, setSubmitting }: FormikHelpers<any>)=>{
        setSubmitting(true);
        let payload = {
            per_diem_rate_card: {
                ...values,
                pay_rate: {
                    ...values.pay_rate,
                    total_pay_rate: totalPayRate,
                },
            },
            workflow_status: 'completed',
        };
        ApiService.put(ENV.API_URL + `ats/${apiDataAfterAdd?._id}/ratecard`, payload)
            .then((resp: any) => {
                CommonService.showToast('Job Details Updated Successfully','success');
                history.push("/job");
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors,error)
            });
    },[apiDataAfterAdd,history,totalPayRate])

    return (
        <div>
            <Formik
                validationSchema={validationSchema}
                initialValues={
                    updateInitialValues()
                }
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={false}
                onSubmit={onSubmit}
            >
                {({
                      values,
                      validateForm,
                  }) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form noValidate={true} id="add-job-rate-card">
                            <Tabs defaultActiveKey={activeTab} items={TabColumn} onChange={handleTabChange}/>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    )
}
export default PerDiemRateCardComponent