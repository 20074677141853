import { Tooltip } from "@material-ui/core";
// import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, {PropsWithChildren, useMemo} from 'react';
import { Link, useParams } from 'react-router-dom';
// import { TsDataListOptions, TsDataListState, TsDataListWrapperClass } from '../../../../../classes/ts-data-list-wrapper.class';
// import LoaderComponent from '../../../../../components/LoaderComponent';
// import NoDataCardComponent from '../../../../../components/NoDataCardComponent';
import {ENV, ImageConfig} from '../../../../../constants';
// import { ApiService } from '../../../../../helpers';
import './ApprovedHcpApplicationComponent.scss';
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";

export interface ApprovedHcpApplicationComponentProps {
    isAddOpen: Boolean;
}

const ApprovedHcpApplicationComponent = (props: PropsWithChildren<ApprovedHcpApplicationComponentProps>) => {
    const isAddHcpOpen = props?.isAddOpen
    const param = useParams<any>()
    const { id } = param;

    const approvedListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title: 'Staff Name',
                dataIndex: 'hcp_details',
                key: 'hcp_details',
                width:200,
                render: (hcp_details: any) => {
                    return (
                        <div>
                            {(hcp_details?.first_name + hcp_details?.last_name)?.length > 20 ?
                                <Tooltip title={hcp_details?.first_name + " " + hcp_details?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {hcp_details?.first_name + " " + hcp_details?.last_name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {hcp_details?.first_name + " " + hcp_details?.last_name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Approved By',
                dataIndex: 'approved_by',
                key: 'approved_by',
                width: 200,
                render: (approved_by: any) => {
                    return (
                        <div>
                            {(approved_by?.first_name + approved_by?.last_name)?.length > 20 ?
                                <Tooltip title={approved_by?.first_name + " " + approved_by?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {approved_by?.first_name + " " + approved_by?.last_name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {approved_by?.first_name + " " + approved_by?.last_name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Applied On',
                dataIndex: 'created_at',
                key: 'created_at',
                width: 120,
                render: (created_at: any) => {
                    return <span>{moment(created_at).format("MM-DD-YYYY") || 'N/A'}</span>
                }
            },
            {
                title: 'Staff Rate',
                dataIndex: 'hcp_details',
                key: 'hcp_details',
                width: 100,
                render: (hcp_details: any) => {
                    return <span>{hcp_details?.contract_details?.rate_per_hour ? hcp_details?.contract_details?.rate_per_hour : (hcp_details?.contract_details?.rate_per_hour === "" ? "N/A" : hcp_details?.contract_details?.rate_per_hour)}</span>
                }
            },
            {
                title: 'View Details',
                width:100,
                render: (actions: any, row: any) => {
                    return <Link to={{ pathname: '/hcp/user/view/' + row['hcp_details']?._id, state: { prevPath: "/shiftsRequirements/view/" + id } }} className="info-link" id={"link_hospital_details" + row['hcp_details']?._id} >
                        <ImageConfig.ArrowRightOutlinedIcon/>
                    </Link>
                }
            }
        ]
    },[id])

    return <div className='approved-hcps-list'>
        <AntTableComponent
            url={ENV.API_URL + 'requirement/' + id + '/application?status=approved'}
            method={'get'}
            extraPayload={isAddHcpOpen}
            columns={approvedListColumn}
            noDataText={'No Staff Approved Found.'}
        />
    </div>;
}

export default ApprovedHcpApplicationComponent;
