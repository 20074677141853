import { useFormikContext } from 'formik';
import {useEffect, useCallback, useMemo, useState} from 'react';
import {Table, TableColumnsType} from 'antd';
import FormikInputComponent from '../../../../../ant-component/formik-input-component/FormikInputComponent';

interface BillRateComponentProps {
    apiDataAfterAdd: any;

}

const tableData = [
    { value: 'bill_rate_per_hour', label: 'Bill Rate Per Hour' },
    { value: 'daily_lodging_gsa', label: 'Daily Lodging - Daily (GSA)' },
    { value: 'mi_daily', label: 'M&I Daily' },
    { value: 'guaranteed_hours_weekly', label: 'Guaranteed Hours-Weekly' },
    { value: 'vms_fee_bill_rate', label: 'VMS Fee of Bill Rate' },
    { value: 'state_burden_taxes', label: 'State Burden-State Taxes' },
    { value: 'gross_hourly_pay_rate', label: 'Gross Hourly Pay Rate(All Inclusive)' },
    { value: 'total_daily_stipend', label: 'Total Daily Stipend' },
    { value: 'weekly_stipend', label: 'Weekly Stipend' },
    { value: 'hourly_stipend', label: 'Hourly Stipend' },
    { value: 'tax_free_hourly', label: 'Tax Free' },
    { value: 'taxable_hourly_income', label: 'Taxable Hourly Income' },
    { value: 'total_hourly', label: 'Total Hourly' },
    { value: 'w2_hourly_rate', label: 'W2 Rate-Hourly' },
    { value: 'taxable_weekly_income', label: 'Taxable Weekly Income' },
    { value: 'tax_free_weekly_income', label: 'Tax Free Weekly Income' },
    { value: 'gross_total_weekly', label: 'Gross Total Weekly' },
    { value: 'ot_hourly_rate', label: 'OT Rate' },
];

const BillRateComponent = (props: BillRateComponentProps) => {
    const { values, setFieldValue } = useFormikContext<any>();
    const { apiDataAfterAdd } = props;
    const [manualEdits, setManualEdits] = useState<Record<string, boolean>>({});

    const handleInputChange = useCallback(
        (value: any, field: string) => {
            setFieldValue(`bill_rate.${field}`, value ? parseFloat(value) : '');
            setManualEdits((prev) => ({ ...prev, [field]: true })); // Mark field as manually edited
        },
        [setFieldValue]
    );

    useEffect(() => {

        const billRate = values?.bill_rate;

        const totalDailyStipend = (Number(billRate?.daily_lodging_gsa) || 0) + (Number(billRate?.mi_daily) || 0);
        if (!manualEdits['total_daily_stipend']) {
            setFieldValue('bill_rate.total_daily_stipend', String(totalDailyStipend));
        }

        const weeklyStipend = (Number(billRate?.total_daily_stipend) || 0) * (Number(apiDataAfterAdd?.contract_details?.days_per_week) || 0);
        if (!manualEdits['weekly_stipend']) {
            setFieldValue('bill_rate.weekly_stipend', String(weeklyStipend));
        }

        if (Number(billRate?.guaranteed_hours_weekly) > 0) {
            const hourlyStipend = (Number(billRate?.weekly_stipend) || 0) / Number(billRate?.guaranteed_hours_weekly);
            if (!manualEdits['hourly_stipend']) {
                setFieldValue('bill_rate.hourly_stipend', String(hourlyStipend));
            }
        }

        if (!manualEdits['tax_free_hourly']) {
            setFieldValue('bill_rate.tax_free_hourly', String(billRate?.hourly_stipend));
        }

        const taxableHourlyIncome = (Number(billRate?.gross_hourly_pay_rate) || 0) - (Number(billRate?.tax_free_hourly) || 0);
        if (!manualEdits['taxable_hourly_income']) {
            setFieldValue('bill_rate.taxable_hourly_income', String(taxableHourlyIncome));
        }

        const totalHourly = (Number(billRate?.taxable_hourly_income) || 0) + (Number(billRate?.tax_free_hourly) || 0);
        if (!manualEdits['total_hourly']) {
            setFieldValue('bill_rate.total_hourly', String(totalHourly));
        }

        const w2HourlyRate = Number(billRate?.total_hourly) - (Number(billRate?.total_hourly) * (Number(billRate?.state_burden_taxes) || 0));
        if (!manualEdits['w2_hourly_rate']) {
            setFieldValue('bill_rate.w2_hourly_rate', String(w2HourlyRate));
        }

        const taxableWeeklyIncome = (Number(billRate?.taxable_hourly_income) || 0) * (Number(billRate?.guaranteed_hours_weekly) || 0);
        if (!manualEdits['taxable_weekly_income']) {
            setFieldValue('bill_rate.taxable_weekly_income', String(taxableWeeklyIncome));
        }

        const taxFreeWeeklyIncome = (Number(billRate?.tax_free_hourly) || 0) * (Number(billRate?.guaranteed_hours_weekly) || 0);
        if (!manualEdits['tax_free_weekly_income']) {
            setFieldValue('bill_rate.tax_free_weekly_income', String(taxFreeWeeklyIncome));
        }

        const grossTotalWeekly = (Number(billRate?.taxable_weekly_income) || 0) + (Number(billRate?.tax_free_weekly_income) || 0);
        if (!manualEdits['gross_total_weekly']) {
            setFieldValue('bill_rate.gross_total_weekly', String(grossTotalWeekly));
        }

        const otHourlyRate = Number(billRate?.total_hourly) * 1.5;
        if (!manualEdits['ot_hourly_rate']) {
            setFieldValue('bill_rate.ot_hourly_rate', String(otHourlyRate));
        }
    }, [
        setFieldValue,
        values?.bill_rate,
        apiDataAfterAdd?.contract_details?.days_per_week,
        manualEdits,
    ]);

    const billRateColumnList: TableColumnsType = useMemo(() => {
        return [
            {
                title: 'Rate Type',
                dataIndex: 'label',
                key: 'label',
            },
            {
                title: 'Rate',
                render: (item: any) => {
                    const doNotDisable=item?.value === 'bill_rate_per_hour' ||
                        item?.value === 'daily_lodging_gsa' ||
                        item?.value === 'mi_daily' ||
                        item?.value === 'gross_hourly_pay_rate' ||
                        item?.value === 'vms_fee_bill_rate' ||
                        item?.value === 'state_burden_taxes' || item?.value === 'guaranteed_hours_weekly'
                    return (
                        <div>
                            <FormikInputComponent
                                name={`bill_rate.${item?.value}`}
                                type={'number'}
                                disabled={!doNotDisable}
                                onUpdate={(value: any) => handleInputChange(value, item.value)}
                            />
                        </div>
                    );
                },
            },
            {
                title: 'Final Rate',
                key: 'finalRate',
                width: '33%',
                render: (item: any) => {
                    return <div>{values?.bill_rate?.[`${item?.value}`] || 0}</div>;
                },
            },
        ];
    }, [values, handleInputChange]);

    return (
        <div className={'pay-rate-component'}>
            <Table dataSource={tableData} columns={billRateColumnList} pagination={false} />
        </div>
    );
};

export default BillRateComponent;
