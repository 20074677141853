import "./TagJobComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../../../helpers";
import {ENV} from "../../../../constants";
import FormLabel from "@material-ui/core/FormLabel";
import {CssTextField} from "../../../../constants/data/styles";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import LoaderComponent from "../../../../components/LoaderComponent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Lottie from "react-lottie";
import {Button} from "@material-ui/core";
import animationData from "../../../../assets/animations/NoData.json";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

interface TagJobComponentProps{
    staff_type:string[];
    id:string[];
    cancel:()=>void;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(3),
        },
        paper: {
            padding:"0px 26px 26px 26px",
            margin: "auto",
            marginTop:'10px'
        },
        assignNcActions: {
            margin: "auto",
            marginTop: "20px",
            textAlign: "center",
            justifyContent: "center",
        },
        title: {
            fontWeight: 450,
        },
    })
);


const TagJobComponent=(props:TagJobComponentProps)=>{
    const {id}=props
    const afterCancel = props?.cancel;
    const classes = useStyles();
    const [isJobListLoading,setIsJobListLoading]=useState<boolean>(false);
    const [jobList,setJobList]=useState<any>([])
    const [searchJob, setSearchJob] = useState<string>("");
    const [isSelectedCount, setIsSelectedCount] = useState<any>(-1)
    const [selectedJobs, setSelectedJobs] = useState<any>([]);

    const defaultOptions = {
        animationData,
    };

    const cancel = (resetForm: any) => {
        if (afterCancel) {
            afterCancel();
        }
    };

    const getJobList=useCallback(()=>{
            setIsJobListLoading(true);
            let payload:any={
                // staff_types:staff_type,
                status:['open']
            }
            if(searchJob!==""){
                payload.search=searchJob;
            }
            CommonService._api.post(ENV.API_URL + 'ats/jobs/listLite',payload).then((response:any)=>{
                const updatedJobList=response?.data?.map((item:any)=>({
                    _id:item?._id,
                    title:CommonService.capitalizeFirstLetter(item?.job_title) +" | "+ CommonService.formatTitleCase(item?.job_type)
                }));
                setJobList(updatedJobList)
                console.log('response',updatedJobList)
            }).catch((error:any)=>{
                console.log('error',error.error)
            }).finally(()=>{
                setIsJobListLoading(false)
            })
    },[searchJob])

    useEffect(() => {
        getJobList()
    }, [getJobList]);

    const isSelected = useCallback((_id: any) => {
        return selectedJobs?.indexOf(_id) !== -1;
    }, [selectedJobs]);

    const handleChange = (event: any) => {
        let index = selectedJobs.indexOf(event.target.value);
        let tempJobs = [];
        if (index > -1) {
            setIsSelectedCount(selectedJobs?.length - 1 === 0 ? -1 : selectedJobs?.length - 1)
            tempJobs = selectedJobs.filter((item: any) => item !== event.target.value);
        } else {
            setIsSelectedCount(selectedJobs?.length + 1)
            tempJobs = [...selectedJobs, event.target.value];
        }
        setSelectedJobs(tempJobs);
    };


    const handleAddJobsToStaff=useCallback(()=>{
        const payload={
            job_ids:selectedJobs,
            user_ids:id
        }
        CommonService._api.post(ENV.API_URL + 'ats/applyJobs',payload).then((response:any)=>{
            CommonService.showToast(response.message,"success")
            afterCancel();
        }).catch((error:any)=>{
            CommonService.showToast(error.error || 'Error',"error");
        })
    },[afterCancel,id,selectedJobs])


    return (
        <div>
            <div className={'pdd-left-20 pdd-right-20 pdd-bottom-20 mrg-top-20'}>
                {/*<h2 className={classes.title}>Add Staff to this Shift</h2>*/}
                <FormLabel component="legend" className="mrg-left-0">
                    List Of Jobs
                </FormLabel>
                <div id="alert-dialog-title">
                    <div className="mrg-top-20">
                        <div>
                            <div className="d-flex">
                                <div className="d-flex position-relative">
                                    <CssTextField
                                        defaultValue={""}
                                        onChange={(event) => {
                                            setSearchJob(event?.target?.value);
                                        }}
                                        className="tag-input-search"
                                        variant={"outlined"}
                                        size={"small"}
                                        type={"text"}
                                        placeholder={"Search Job"}
                                        value={searchJob}
                                    />
                                    {searchJob === "" ? (
                                        <div className={"search_icon"}>
                                            <SearchRounded/>
                                        </div>
                                    ) : (
                                        <div className={"search_icon"}>
                                            <ClearIcon onClick={(event) => setSearchJob("")} id="clear_hcp_search"/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mrg-top-20">
                        {isJobListLoading ?
                            <LoaderComponent position="block"/> :
                            <div>
                                {jobList?.length === 0 ?
                                    <div className={"display-flex flex-one mrg-top-20 mrg-bottom-20"}>
                                        <Lottie width={400} height={400} speed={1} options={defaultOptions}/>
                                    </div> :
                                        <>
                                            <div className="mrg-top-20 job-list-content">
                                                {jobList && jobList?.map((item: any) => {
                                                    const isItemSelected = isSelected(item?._id);
                                                    return (
                                                        <div>
                                                            <FormControlLabel value={item?._id} checked={isItemSelected}
                                                                              control={<Checkbox/>}
                                                                              onChange={(event) => handleChange(event)}
                                                                              label={item?.title}/>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className={classes.assignNcActions}>
                                                <Button type={"submit"} size="large" variant={"outlined"}
                                                        className={"normal"} onClick={cancel}>
                                                    Cancel
                                                </Button>
                                                <Button type={"submit"} color={"primary"} size="large"
                                                        disabled={isSelectedCount === -1}
                                                        className={'mrg-left-20'} variant={"contained"}
                                                        onClick={handleAddJobsToStaff}>
                                                    Tag Job
                                                </Button>
                                            </div>
                                        </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TagJobComponent