import "./ContractRateCardComponent.scss";
import {useCallback, useState} from "react";
import type {TabsProps} from "antd";
import PayRateTabComponent from "./pay-rate/PayRateComponent";
import BillRateTabComponent from "./bill-rate/BillRateComponent";
import {Form, Formik, FormikHelpers} from "formik";
import {ApiService, CommonService} from "../../../../../helpers";
import {ENV} from "../../../../../constants";
import {Tabs} from "antd";
import {useHistory} from "react-router-dom";
import * as Yup from "yup";

interface ContractRateCardComponentProps{
    apiDataAfterAdd:any;
    rate_details?:any;
}

const validationSchema = Yup.object().shape({

});

const ContractRateCardComponent=(props:ContractRateCardComponentProps)=>{
    const {apiDataAfterAdd,rate_details}=props;
    const history=useHistory();
    const [activeTab,setActiveTab]=useState<string>('pay_rate')

    const updatedInitialValues=useCallback(()=>({
        pay_rate:{
            total_pay_rate:rate_details?.contract_rate_card?.pay_rate?.total_pay_rate || '',
            travel_per_diems: rate_details?.contract_rate_card?.pay_rate?.travel_per_diems || [
                { type: 'Shift Rate', rate: '', week_rate: '' },
                { type: 'Housing', rate: '', week_rate: '' },
                { type: 'Meals', rate: '', week_rate: '' },
            ]
        },
        bill_rate:{
            bill_rate_per_hour: rate_details?.contract_rate_card?.bill_rate?.bill_rate_per_hour || '',
            daily_lodging_gsa: rate_details?.contract_rate_card?.bill_rate?.daily_lodging_gsa || '',
            mi_daily: rate_details?.contract_rate_card?.bill_rate?.mi_daily|| '',
            guaranteed_hours_weekly: rate_details?.contract_rate_card?.bill_rate?.guaranteed_hours_weekly || '',
            state_burden_taxes: rate_details?.contract_rate_card?.bill_rate?.state_burden_taxes || '',
            vms_fee_bill_rate: rate_details?.contract_rate_card?.bill_rate?.vms_fee_bill_rate || '',
            gross_hourly_pay_rate: rate_details?.contract_rate_card?.bill_rate?.gross_hourly_pay_rate || '',
            total_daily_stipend: rate_details?.contract_rate_card?.bill_rate?.total_daily_stipend || '',
            weekly_stipend: rate_details?.contract_rate_card?.bill_rate?.weekly_stipend || '',
            hourly_stipend: rate_details?.contract_rate_card?.bill_rate?.hourly_stipend || '',
            tax_free_hourly: rate_details?.contract_rate_card?.bill_rate?.hourly_stipend|| '',
            taxable_hourly_income: rate_details?.contract_rate_card?.bill_rate?.taxable_hourly_income || '',
            total_hourly: rate_details?.contract_rate_card?.bill_rate?.total_hourly || '',
            w2_hourly_rate: rate_details?.contract_rate_card?.bill_rate?.w2_hourly_rate || '',
            taxable_weekly_income: rate_details?.contract_rate_card?.bill_rate?.taxable_weekly_income || '',
            tax_free_weekly_income: rate_details?.contract_rate_card?.bill_rate?.tax_free_weekly_income || '',
            gross_total_weekly: rate_details?.contract_rate_card?.bill_rate?.gross_total_weekly || '',
            ot_hourly_rate: rate_details?.contract_rate_card?.bill_rate?.ot_hourly_rate || '',
        }
    }),[rate_details])

    const TabColumn: TabsProps['items'] = [
        {
            key: 'pay_rate',
            label: 'Pay Rate',
            children: <PayRateTabComponent/>,
        },
        {
            key: 'bill_rate',
            label: 'Bill Rate',
            children: <BillRateTabComponent apiDataAfterAdd={apiDataAfterAdd}/>,
        },

    ];

    const handleTabChange=useCallback((key: string)=>{
        setActiveTab(key)
    },[])

    const onSubmit=useCallback((values: any, { setErrors, setSubmitting }: FormikHelpers<any>)=>{
        setSubmitting(true);
        const payload={
            contract_rate_card:{
                ...values,
            },
            workflow_status:'completed',
        }
        ApiService.put(ENV.API_URL + `ats/${apiDataAfterAdd?._id}/ratecard`, payload)
            .then((resp: any) => {
                CommonService.showToast('Job Details Updated Successfully','success');
                history.push("/job");
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors,error)
            });
    },[apiDataAfterAdd,history])
    return (
        <div className={'contract-rate-card-component'}>
            <div className={'contract-left-rate-card'}>
            <Formik
                validationSchema={validationSchema}
                initialValues={
                    updatedInitialValues()
                }
                validateOnChange={false}
                validateOnBlur={true}
                validateOnMount={false}
                onSubmit={onSubmit}
            >
                {({
                      values,
                      validateForm,
                  }) => {
                    return (
                        <Form noValidate={true} id="add-job-rate-card">
                            <Tabs defaultActiveKey={activeTab} items={TabColumn} onChange={handleTabChange}/>
                        </Form>
                    );
                }}
            </Formik>
            </div>
            <div className={'contract-right-rate-card'}>
                <div className={'gross-rate-card'}>
                    <div className={'title'}>Gross Rates</div>
                    <div>-</div>
                </div>
                <div className={'guaranteed-week-card'}>
                    <div className={'title'}>Guaranteed weeks</div>
                    <div>-</div>
                </div>
                <div className={'company-gross-card'}>
                    <div className={'title'}>Guaranteed weeks</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Hourly</div>
                        <div>-</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Weekly</div>
                        <div>-</div>
                    </div>
                </div>
                <div className={'candidate-gross-card'}>
                    <div className={'title'}>Candidate gross pay</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Hourly</div>
                        <div>-</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Weekly</div>
                        <div>-</div>
                    </div>
                </div>
                <div className={'border'}> </div>
                <div className={'tax-amount-card'}>
                    <div className={'title'}>Taxable amount</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>W2 per hour rate</div>
                        <div>-</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Weekly</div>
                        <div>-</div>
                    </div>
                </div>
                <div className={'tax-amount-card'}>
                    <div className={'title'}>Non Taxable amount</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Hourly</div>
                        <div>-</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Weekly</div>
                        <div>-</div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ContractRateCardComponent