import React from 'react';
import {TimePicker} from 'antd';
import type {Dayjs} from 'dayjs';
import './TimePickerComponent.scss';
import dayjs from "dayjs";
import FormItemComponent, {CommonFormInputProps, extractCommonPropsFromInput} from "../formItem/FormItemComponent";

export interface CustomTimePickerProps extends CommonFormInputProps {
    className?: string;
    onChange?: (time: string | null) => void;
    format?: string;// Callback for selected time
    value: string | null;
    use12Hours?: boolean; // 12-hour format with AM/PM
    size?: 'large' | 'middle' | 'small';
    onSelectionClose?:() => void;
    isConfirm?:boolean;
}

const formatValueProps = (value: string | undefined | null, format: string) => {
    const isValidTime = value && dayjs(value, format).isValid();
    if (!isValidTime) {
        return null;
    }
    return dayjs(value, format)
}
const TimePickerComponent: React.FC<CustomTimePickerProps> = (props) => {
    const {
        className = '',
        format = 'HH:mm',
        value,
        use12Hours = false,
        onChange,
        size,
        onSelectionClose,
        isConfirm=false,
    } = props;

    const handleTimeChange = (_date: Dayjs | null, dateString: string | string[]) => {
        const timeString = Array.isArray(dateString) ? dateString.join(', ') : dateString;
        console.log('Selected Time:', timeString);
        // Callback for parent
        if (onChange) {
            onChange(timeString || null);
        }
    };

    return (
        <FormItemComponent {...extractCommonPropsFromInput(props)}>
            <TimePicker
                data-testid={'time-picker-component'}
                className={`time-picker-component ${className}`}
                onChange={handleTimeChange}
                format={format} // 12-hour format with AM/PM
                value={formatValueProps(value, format)}
                use12Hours={use12Hours}
                placeholder="Select a time"
                size={size}
                needConfirm={isConfirm}
                onOpenChange={(value: boolean) => {
                    if (!value && onSelectionClose) {
                        onSelectionClose();
                    }
                }}
            />
        </FormItemComponent>
    );
};

export default TimePickerComponent;
