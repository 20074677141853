import "./FormLabelComponent.scss"

interface FormLabelComponentProps {
    Label: string
}

const FormLabelComponent = (props: FormLabelComponentProps) => {
    const {Label} = props;
    return (
        <>
            <div className={'form-label-component'}>
                {Label}
            </div>
        </>
    )
}

export default FormLabelComponent
