import "./SendEmailComponent.scss";
import {Button} from "antd";
import {SendOutlined} from '@ant-design/icons'
import React, {useCallback, useState} from "react";
import {useHistory} from "react-router-dom";
import {ENV, ImageConfig} from "../../../constants";
import LeavePageConfirmationComponent from "../../../components/shared/LeavePageConfirmationComponent";
import EmailEditorComponent from "./email-editor/EmailEditorComponent";
import {ApiService, CommonService} from "../../../helpers";

const SendEmailComponent=()=>{
    const history=useHistory();
    const [isConfirmationOpen,setIsConfirmationOpen]=useState<boolean>(false);
    const [selectedRole,setSelectedRole]=useState<string[]>([]);
    const [selectedStaffType,setSelectedStaffType]=useState<string[]>([]);
    const [roleEmailList,setRoleEmailList]=useState<any>([]);
    const [selectedRoleEmails,setSelectedRoleEmails]=useState<any>([]);
    const [staffEmailList,setStaffEmailList]=useState<any>([]);
    const [selectedStaffEmails,setSelectedStaffEmails]=useState<any>([]);
    const [manualEmails,setManualEmails]=useState<any>([]);
    const [ccEmails,setCCEmails]=useState<any>([]);
    const [bccEmails,setBCCEmails]=useState<any>([]);
    const [subject,setSubject]=useState<string>("");
    const [emailMessage,setEmailMessage]=useState<any>(null);
    const [attachment,setAttachment]=useState<any>(null)
    const [isSubmitting,setIsSubmitting]=useState<boolean>(false);

    const openAdd = useCallback(() => {
        setIsConfirmationOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsConfirmationOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        history.push("/applicants");
    }, [history]);

    const handleSubmit=useCallback(()=>{
        setIsSubmitting(true)
        const combinedRecipientEmails = [...(selectedRoleEmails || []),...(selectedStaffEmails || []), ...(manualEmails || [])];
        const ccEmailsList = ccEmails || [];
        const bccEmailsList = bccEmails || [];
        const formData = new FormData();
        if (attachment) {
            formData.append('attachment', attachment);
        }
        combinedRecipientEmails?.forEach((email:any,index:number)=>{
            formData.append(`email[${index}]`,email || "");
        })
        ccEmailsList?.forEach((email:any,index:number)=>{
            formData.append(`cc[${index}]`,email || "");
        })
        bccEmailsList?.forEach((email:any,index:number)=>{
            formData.append(`bcc[${index}]`,email || "");
        })
        formData.append('subject',subject || "");
        formData.append('emailMessage',emailMessage || "");


        ApiService.upload(ENV.API_URL + "ats/bulkEmail",formData)
            .then((response)=>{
                CommonService.showToast(response.message,"success");
                confirmAdd();
            }).catch((error:any)=>{
            CommonService.showToast(error.error || error.message || "Error occurred while sending email",'error')
        }).finally(()=>{
            setIsSubmitting(false)
        })
    },[selectedRoleEmails,selectedStaffEmails,manualEmails,ccEmails,bccEmails,subject,emailMessage,confirmAdd,attachment])


    return (
        <div className={'add-job-form-container-whole email-container'}>
            <LeavePageConfirmationComponent
                visible={isConfirmationOpen}
                cancel={cancelAdd}
                confirm={confirmAdd}
                confirmationText="Are you sure you want to leave without saving?"
                notext="Cancel"
                yestext="Leave"
            />
            <div className={'add-header-wrapper'}>
                <div className={'add-header-back-wrapper'} onClick={(openAdd)}>
                    <img src={ImageConfig.BackIcon} alt="back"/>
                    <div className={'add-header-back'}>
                        Back
                    </div>
                </div>
                <div className={'add-header-title'}>Bulk Email</div>
            </div>

            <div className="email-content-wrapper">
                <EmailEditorComponent
                    selectedRole={selectedRole}
                    setSelectedRole={setSelectedRole}
                    selectedStaffType={selectedStaffType}
                    setSelectedStaffType={setSelectedStaffType}
                    roleEmailList={roleEmailList}
                    setRoleEmailList={setRoleEmailList}
                    selectedRoleEmails={selectedRoleEmails}
                    setSelectedRoleEmails={setSelectedRoleEmails}
                    staffEmailList={staffEmailList}
                    setStaffEmailList={setStaffEmailList}
                    selectedStaffEmails={selectedStaffEmails}
                    setSelectedStaffEmails={setSelectedStaffEmails}
                    manualEmails={manualEmails}
                    setManualEmails={setManualEmails}
                    ccEmails={ccEmails}
                    setCCEmails={setCCEmails}
                    bccEmails={bccEmails}
                    setBCCEmails={setBCCEmails}
                    subject={subject}
                    setSubject={setSubject}
                    emailMessage={emailMessage}
                    setEmailMessage={setEmailMessage}
                    setAttachment={setAttachment}
                    attachment={attachment}
                />
            </div>

            <div className="email-footer">
                <Button
                    type="primary"
                    className="email-save-button"
                    icon={<SendOutlined/>}
                    iconPosition={'end'}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                >
                    Send
                </Button>
            </div>
        </div>
    )
}

export default SendEmailComponent