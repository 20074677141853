import {ColumnsType} from "antd/es/table";

export const defaultTableColumnList:ColumnsType=[
    {
        title: "First Name",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Last Name",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Phone Number",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Email",
        width: 200,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "City/State",
        width: 200,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Start Date",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "End Date",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Shift",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Compliance Housing Benefits GP%",
        width: 300,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Client Name",
        width: 200,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "VMS",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Profession",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Speciality",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Sales Rep",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Recruiter",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Referral",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
    {
        title: "Time Off",
        width: 150,
        render: (item: any) => {
            return <>-</>
        },
    },
]