import "./FormikTimePickerComponent.scss";
import TimePickerComponent, {CustomTimePickerProps} from "../../../components/time-picker-component/TimePickerComponent";
import {useField} from "formik";
import React from "react";

interface FormikTimePickerComponentProps extends Omit<CustomTimePickerProps, 'value' | 'onChange'> {
    name: string;
}

const FormikTimePickerComponent = (props: FormikTimePickerComponentProps) => {
    const {name, ...restProps} = props;

    const [field, meta, fieldHelper] = useField(name);
    // Handle the onChange event for the AutoComplete dropdown
    const handleChange = (value: any) => {
        // Formik's setValue updates the value of the field
        fieldHelper.setValue(value);
    };

    // Handle the onBlur event for the AutoComplete dropdown
    const handleBlur = () => {
        // Formik's onBlur should be triggered to mark the field as touched
        setTimeout(() => fieldHelper.setTouched(true,true),0)
        // if (field.onBlur) {
        //     field.onBlur(event);
        // }
    };

    // Handle errors, if any, from Formik
    const hasError = meta.touched && meta.error;

    return (
        <TimePickerComponent
            className={'formik-time-picker-component'}
            onChange={handleChange}
            format="HH:mm"
            use12Hours={true}
            value={field.value}
            // onBlur={handleBlur}
            onSelectionClose={handleBlur}
            errorMessage={meta.error}
            hasError={Boolean(hasError)}
            {...restProps}
        />
    );

};

export default FormikTimePickerComponent;
