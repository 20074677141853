import "./ApplicantListComponent.scss";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Communications} from "../../../helpers";
import { Popover, TableColumnsType} from "antd";
import {Button} from "@material-ui/core";
import AntTableComponent from "../../../components/ant-table-component/AntTableComponent";
import {ENV, ImageConfig} from "../../../constants";
import ApplicantMenuListComponent from "../applicant-menu-list/ApplicantMenuListComponent";
import {Tooltip} from "@material-ui/core";
import moment from "moment/moment";
import CommonService from "../../../helpers/common-service";
import {AddRounded, SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import {CssTextField} from "../../../constants/data/styles";
import DrawerComponent from "../../../components/drawer/DrawerComponent";
import ApplicantFilterDrawerComponent from "./applicant-filter-drawer/ApplicantFilterDrawerComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {useSessionStorage} from "../../../components/useSessionStorage";
import {Link, useHistory} from "react-router-dom";

const ApplicantListComponent=()=>{
    const history=useHistory();
    const [filterData,setFilterData]=useSessionStorage<any>("vitaApplicantFilterData",{
        search:'',
        sort: {},
        applied_from:["monster_jobs", "company_portal","bulk_upload"],
        limit: 10,
        page:1
    })
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [selectedApplicants,setSelectedApplicants]=useState<any>([])
    const [openFilterDrawer,setOpenFilterDrawer]=useState<boolean>(false);

    useEffect(() => {
        Communications.pageTitleSubject.next("Applicants");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    const hcpListColumns:TableColumnsType=useMemo(()=>{
        return [
            {
                title:'Applicant Name',
                width:200,
                sorter: (a: any, b: any) => {
                    const fullNameA = (a.first_name + " " + a.last_name).toLowerCase();
                    const fullNameB = (b.first_name + " " + b.last_name).toLowerCase();
                    return fullNameA?.localeCompare(fullNameB);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {(item?.first_name?.length + item?.last_name?.length)>20 ?
                                <Tooltip title={item?.first_name + " " + item?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.first_name + " " + item?.last_name}
                                    </div>
                                </Tooltip>:
                                <div className='facility-name'>
                                    {(item?.first_name + " " + item?.last_name) || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Applied For',
                width:200,
                sorter: (a: any, b: any) => {
                    return a.hcp_type?.localeCompare(b.hcp_type);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.hcp_type?.length > 20 ?
                                <Tooltip title={item?.hcp_type}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.hcp_type}
                                    </div>
                                </Tooltip>
                                :
                                <div className='facility-name'>
                                    {item?.hcp_type || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Applicant Came From',
                width:180,
                sorter: (a: any, b: any) => {
                    return a?.applied_from?.localeCompare(b?.applied_from?.slice(-7));
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {CommonService.formatTitleCase(item?.applied_from) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Email',
                width:200,
                sorter: (a: any, b: any) => {
                    return a.email?.localeCompare(b.email);
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {item?.email?.length > 20 ?
                                <Tooltip title={item?.email}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item?.email}
                                    </div>
                                </Tooltip>
                                :
                                <div className='facility-name'>
                                    {item?.email || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title:'Created On',
                width:130,
                sorter: (a: any, b: any) => {
                    return moment(a.created_at).unix() - moment(b.created_at).unix();
                },
                render:(item:any)=>{
                    return (
                        <div>
                            {moment(item.created_at).utc().format("MM-DD-YYYY") || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Contact Number',
                width:160,
                render:(item:any)=>{
                    return (
                        <div>
                            {CommonService.formatPhoneNumber(item?.contact_number?.replace(/^\+1/,'')) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Status',
                width:120,
                render:(item:any)=>{
                    return (
                        <div>{CommonService.formatTitleCase(item?.status) || 'N/A'}</div>
                    )
                }
            },
            {
                title:'View Details',
                width:130,
                fixed:'right',
                render:(item:any)=>{
                    return (
                        <div className={'display-center'}>
                            {/*to={`/hcp/view/${item._id}`}*/}
                            <div onClick={()=>{
                                history.push(`/hcp/view/${item?._id}`,{
                                    from:history.location.pathname,
                                })
                            }}
                                  style={{color: '#1e1e1e'}}
                            >
                                <ImageConfig.ArrowRightOutlinedIcon/>
                            </div>
                        </div>
                    )
                }
            }
        ]
    }, [history]);

    const clearFilterValues=useCallback(()=>{
        setFilterData((prevFilterData:any)=>{
            const updatedFilterData={
                ...prevFilterData,
            }
            const keysToRemove=[
                "staff_name",
                "status",
                "hcp_types",
            ]
            keysToRemove.forEach((key:any)=> delete updatedFilterData[key])

            return updatedFilterData
        })
    },[setFilterData])

    const resetFilters = () => {
        clearFilterValues();
    };

    return (
        <>
            <DrawerComponent isOpen={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                <ApplicantFilterDrawerComponent
                    filterData={filterData}
                    setFilterData={setFilterData}
                    resetFilters={resetFilters}
                    hcpTypes={hcpTypes}
                    setOpenFilterDrawer={setOpenFilterDrawer}
                />
            </DrawerComponent>
            <div className={"facility-list screen crud-layout pdd-30 pdd-top-10"}>
                <div className="custom-border pdd-10 pdd-top-20 pdd-bottom-0">
                    <div className="header">
                        <div className="mrg-left-5 filter">
                            <div>
                                <div className="d-flex">
                                    <div className="d-flex position-relative">
                                        {!filterData.search ? (
                                            <div className={"search_icon"}>
                                                <SearchRounded/>
                                            </div>
                                        ) : (
                                            <div className={"search_icon"}>
                                                <ClearIcon
                                                    onClick={(event) => {
                                                        setFilterData({...filterData, search: '', page: 1})
                                                    }}
                                                    id="clear_facility_search"
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <CssTextField
                                                defaultValue={""}
                                                className="search-cursor searchField"
                                                id="input_search_facility"
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    setFilterData({...filterData, search: value, page: 1});
                                                }}
                                                value={filterData.search}
                                                variant={"outlined"}
                                                size={"small"}
                                                type={"text"}
                                                placeholder={"Search Applicants"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Popover trigger={'click'} placement={'bottom'}
                                     content={<ApplicantMenuListComponent/>}
                            >
                                <div className={'applicant-box'}>
                                    <ImageConfig.EditIcon className={'applicant-edit-icon'}/>
                                </div>
                            </Popover>
                        </div>
                        <div className={'mrg-left-20'}>
                            <Button
                                variant={"outlined"} id={'add_facility_btn'}
                                color={"primary"}
                                onClick={() => {
                                    setOpenFilterDrawer(true)
                                }}
                            >
                                &nbsp;&nbsp;Filters&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div className="mrg-left-10">
                            <Button component={Link} to={"/applicant/bulk_upload"} variant={"contained"}
                                    color={"primary"}>
                                &nbsp;&nbsp;Bulk Upload&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div className="mrg-left-20">
                            <Button
                                variant={"contained"} id={'add_facility_btn'}
                                color={"primary"}
                                onClick={() => {
                                    CommonService.showToast('Coming Soon!', 'info')
                                }}
                            >
                                <AddRounded/>
                                &nbsp;&nbsp;Add New&nbsp;&nbsp;
                            </Button>
                        </div>
                    </div>
                    <div className={'facility-table-component mrg-top-20'}>
                        <AntTableComponent
                            isBordered={true}
                            url={ENV.API_URL + "ats/applicantList"}
                            method={'post'}
                            extraPayload={filterData}
                            columns={hcpListColumns}
                            noDataText={'No Applicants Found.'}
                            isRowSelection={true}
                            selectedRowKeys={selectedApplicants}
                            setSelectedRowKeys={setSelectedApplicants}
                        />
                    </div>
                </div>
            </div>
        </>
)
}

export default ApplicantListComponent