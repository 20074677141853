import React, {useCallback, useEffect, useState} from 'react';
import {Radio, Checkbox, InputNumber, Divider} from 'antd';
import './ShiftRateComponent.scss';


interface shiftRateProps {
    shiftDetails?: any
    currentHCPType?: any
    setTotalShiftRate?: any
    setOtherRate?: any
    totalShiftRate?: any
    facilityData?: any
}

const ShiftRateComponent = (props: shiftRateProps) => {
    const {shiftDetails, setTotalShiftRate, setOtherRate, facilityData} = props;
    const [shiftType, setShiftType] = useState<string>(shiftDetails?.requirement?.is_holiday ? "holiday" : "regular");
    const [shiftTiming, setShiftTiming] = useState<string[]>([]);
    const [hazardTimings, setHazardTimings] = useState<string[]>([]);
    const [rushRate, setRushRate] = useState<any>(Number(shiftDetails?.requirement?.hcp_rush_rate) || "");
    const [NOCDiff, setNOCDiff] = useState<any>(Number(shiftDetails?.requirement?.hcp_noc_diff) || "");
    const [PMDiff, setPMDiff] = useState<any>(Number(shiftDetails?.requirement?.hcp_pm_diff) || "");
    const [weekendRate, setWeekendRate] = useState<any>(Number(shiftDetails?.requirement?.hcp_weekend_rate) || "");
    const [hazardRate, setHazardRate] = useState<any>(Number(shiftDetails.requirement?.hcp_hazard_rate) || "");
    const [shiftRate, setShiftRate] = useState<any>(shiftDetails?.requirement?.base_shift_rate || 0);


    useEffect(() => {
        if (shiftDetails?.requirement?.is_rush_rate) {
            setHazardTimings((prevTimings) => [...prevTimings, "Rush"]);
        }
        if (shiftDetails?.requirement?.is_noc_diff) {
            setShiftTiming((prevTimings) => [...prevTimings, "NOC"])
        }
        if (shiftDetails?.requirement?.is_pm_diff) {
            setShiftTiming((prevTimings) => [...prevTimings, "PM"])
        }
        if (shiftDetails?.requirement?.is_weekend_rate) {
            setShiftTiming((prevTimings) => [...prevTimings, "Weekend"])
        }
        if (shiftDetails?.requirement?.is_hazard_rate) {
            setHazardTimings((prevTimings) => [...prevTimings, "Hazard"]);
        }

    }, [shiftDetails]);

    useEffect(() => {
        if (!shiftTiming?.includes("PM")) {
            setPMDiff(Number(shiftDetails?.requirement?.hcp_pm_diff) || 0)
        }
        if (!shiftTiming?.includes("NOC")) {
            setNOCDiff(Number(shiftDetails?.requirement?.hcp_noc_diff) || 0)
        }

        if (!shiftTiming?.includes("Weekend")) {
            setWeekendRate(Number(shiftDetails?.requirement?.hcp_weekend_rate) || 0)
        }

        if (!hazardTimings?.includes("Rush")) {
            setRushRate(Number(shiftDetails?.requirement?.hcp_rush_rate) || 0)
        }

        if (!hazardTimings?.includes("Hazard")) {
            setHazardRate(Number(shiftDetails?.requirement?.hcp_hazard_rate) || 0)
        }
    }, [hazardTimings, shiftTiming, shiftDetails]);

    useEffect(() => {
        setOtherRate((prevState: any) => ({
            ...prevState,
            pm: PMDiff,
            noc: NOCDiff,
            weekend: weekendRate,
            rush: rushRate,
            hazard: hazardRate,
            base_shift_rate: shiftRate,
            is_rush_rate: hazardTimings?.includes("Rush"),
            is_noc_diff: shiftTiming?.includes("NOC"),
            is_pm_diff: shiftTiming?.includes("PM"),
            is_weekend_rate: shiftTiming?.includes("Weekend"),
            is_hazard_rate: hazardTimings?.includes("Hazard"),
            is_holiday: shiftType === "holiday"
        }));
    }, [PMDiff, NOCDiff, weekendRate, rushRate, hazardRate, setOtherRate, shiftType, hazardTimings, shiftTiming, shiftRate]);

    const calculateTotalShiftRate = useCallback(() => {
        // Coerce all values to numbers
        const baseShiftRate = Number(shiftRate);
        const holidayRate = Number(facilityData?.pay_hourly_base_rates?.holiday || 0);
        const hazardRates = Number(hazardRate || 0);

        let totalShiftRatesInitial = 0;

        if (shiftTiming?.includes("PM")) {
            totalShiftRatesInitial += PMDiff;
        }
        if (shiftTiming?.includes("NOC")) {
            totalShiftRatesInitial += NOCDiff;
        }

        if (shiftTiming?.includes("Weekend")) {
            totalShiftRatesInitial += weekendRate;
        }

        if (hazardTimings?.includes("Rush")) {
            totalShiftRatesInitial += rushRate;
        }

        if (hazardTimings?.includes("Hazard")) {
            totalShiftRatesInitial += hazardRates;
        }

        if (shiftType === "holiday") {
            totalShiftRatesInitial += (holidayRate * baseShiftRate);
        }
        if (shiftType === "regular") {
            totalShiftRatesInitial += baseShiftRate;
        }


        setTotalShiftRate(totalShiftRatesInitial);


        // if (isRushRate) totalShiftRate += rushRateValue;
        // if (isNocRate) totalShiftRate += nocDiff;
        // if (isPmRate) totalShiftRate += pmDiff;
        // if (isWeekendRate) totalShiftRate += weekendDiff;
        return totalShiftRatesInitial
    }, [
        setTotalShiftRate,
        rushRate,
        NOCDiff,
        PMDiff,
        weekendRate,
        hazardRate,
        shiftRate,
        shiftType,
        shiftTiming,
        hazardTimings,
        facilityData
    ]);

    // const calculateFinalRate = () => {
    //     const baseRate = shiftType === 'holiday' ? rates.base * rates.multiplier : rates.base;
    //     const timingRate = shiftTiming === 'PM' ? rates.pm : rates[shiftTiming?.toLowerCase() as keyof typeof rates];
    //     const hazardRate = hazardTimings?.reduce((sum, timing) => sum + rates[timing?.toLowerCase() as keyof typeof rates], 0);
    //
    //     return baseRate + timingRate + hazardRate;
    // };

    const handleShiftTimingChange = (checkedValues: string[]) => {
        setShiftTiming(checkedValues); // Update state with multiple selected timings
    };

    const handleHazardShiftTimingChange = (checkedValues: string[]) => {
        setHazardTimings(checkedValues); // Update state with multiple selected timings
    };


    return (
        <div className="shift-rate-edit-approved-container">
            <div style={{width: "100%"}}>
                {/*<div>Shift rate</div>*/}
                {/* Shift Type */}
                <div className={'d-flex justify-content-space-between'}>
                    <div>
                        <div className={'shift-header-holiday'} onClick={() => {
                            console.log(shiftDetails?.requirement)
                        }}>Shift type
                        </div>
                        <Radio.Group
                            className="custom-radio"
                            value={shiftType}
                            onChange={(e) => setShiftType(e.target.value)}
                            style={{display: 'flex', gap: '1rem', marginTop: '0.5rem'}}
                        >
                            <Radio value="regular">Regular shift</Radio>
                            <Radio value="holiday">Holiday multiplier Rate</Radio>
                        </Radio.Group>
                    </div>
                    <div className={'d-flex mrg-top-25'}>
                        <div className={`${shiftType === "holiday" ? "mrg-right-5" : ""}`}>
                            <InputNumber
                                className={'custom-antd-input-number'}
                                prefix="$"
                                value={shiftRate}
                                style={{width: '100%'}}
                                onChange={(e) => {
                                    setShiftRate(e)
                                }}
                            />
                        </div>
                        {shiftType === "holiday" &&
                            <div className={'mrg-right-5 '}>
                                < InputNumber
                                    className="custom-antd-input-number"
                                    disabled
                                    value={`x ${facilityData?.pay_hourly_base_rates?.holiday || 0}`}
                                    style={{width: '100%'}}
                                />
                            </div>}
                        {shiftType === "holiday" &&
                            <div className={''}>
                                <InputNumber
                                    className="custom-antd-input-number"
                                    disabled
                                    prefix="$"
                                    value={Number(facilityData?.pay_hourly_base_rates?.holiday || 0) * shiftRate}
                                    style={{width: '100%'}}
                                />
                            </div>
                        }
                    </div>
                </div>
                {/*<*/}
                <Divider/>


                {/* Shift Timing */}
                <div className={'d-flex justify-content-space-between'}>
                    <div style={{width: "100%"}}>
                        {/*<div>*/}
                        {/*    <b style={{color: "black", marginBottom: "10px"}}>Shift timing (Multiple*/}
                        {/*        select):</b>*/}
                        {/*</div>*/}
                        <Checkbox.Group
                            className="custom-checkbox"
                            value={shiftTiming}
                            onChange={handleShiftTimingChange}
                            style={{display: "flex", flexDirection: "column", gap: '1rem', marginTop: '0.5rem'}}
                        >
                            <div className={'d-flex justify-content-space-between'}>
                                <div>
                                    <Checkbox value="PM">PM</Checkbox>
                                </div>
                                <div className={`${hazardTimings?.includes("PM") ? "" : ""}`}>
                                    <InputNumber
                                        className="custom-antd-input-number"
                                        min={0} // Set minimum to 0
                                        max={1000}
                                        disabled={!shiftTiming?.includes("PM")}
                                        prefix="$"
                                        value={PMDiff}
                                        style={{width: '100%'}}
                                        onChange={(e) => {
                                            setPMDiff(e)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={'d-flex justify-content-space-between'}>
                                <div>
                                    <Checkbox value="NOC">NOC</Checkbox>
                                </div>
                                <div className={`${hazardTimings?.includes("NOC") ? "" : ""}`}>
                                    <InputNumber
                                        className="custom-antd-input-number"
                                        min={0} // Set minimum to 0
                                        max={1000}
                                        disabled={!shiftTiming?.includes("NOC")}
                                        prefix="$"
                                        value={NOCDiff}
                                        style={{width: '100%'}}
                                        onChange={(e) => {
                                            setNOCDiff(e)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={'d-flex justify-content-space-between'}>
                                <div>
                                    <Checkbox value="Weekend">Weekend</Checkbox>
                                </div>
                                <div
                                    className={`${hazardTimings?.includes("Weekend") ? "" : ""}`}>
                                    <InputNumber
                                        className="custom-antd-input-number"
                                        min={0} // Set minimum to 0
                                        max={1000}
                                        disabled={!shiftTiming?.includes("Weekend")}
                                        prefix="$"
                                        value={weekendRate}
                                        style={{width: '100%'}}
                                        onChange={(e) => {
                                            setWeekendRate(e)
                                        }}
                                    />
                                </div>
                            </div>
                        </Checkbox.Group>
                    </div>
                </div>

                {/*<Divider/>*/}
                {/* Hazard Shift Timing */}

                <div className={'d-flex justify-content-space-between'}>
                    <div style={{width: "100%", marginTop: '0.5rem'}}>
                        {/*<div>*/}
                        {/*    <b style={{color: "black", marginBottom: "10px"}}>Hazard Shift Timing (Multiple*/}
                        {/*        select):</b>*/}
                        {/*</div>*/}
                        <Checkbox.Group
                            className="custom-checkbox"
                            value={hazardTimings}
                            onChange={handleHazardShiftTimingChange}
                            style={{display: 'flex', flexDirection: "column", gap: '1rem', marginTop: '0.5rem'}}
                        >
                            <div className={'d-flex justify-content-space-between'}>
                                <div>
                                    <Checkbox value="Rush">Rush Rate</Checkbox>
                                </div>
                                <div className={`${hazardTimings?.includes("Rush") ? "" : ""}`}>
                                    <InputNumber
                                        className="custom-antd-input-number"
                                        min={0} // Set minimum to 0
                                        max={1000}
                                        disabled={!hazardTimings?.includes("Rush")}
                                        prefix="$"
                                        value={rushRate}
                                        style={{width: '100%'}}
                                        onChange={(e) => {
                                            setRushRate(e)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={'d-flex justify-content-space-between'}>
                                <div>
                                    <Checkbox value="Hazard">Hazard Rate</Checkbox>
                                </div>
                                <div className={`${hazardTimings?.includes("Hazard") ? "" : ""}`}>
                                    <InputNumber
                                        className="custom-antd-input-number"
                                        min={0} // Set minimum to 0
                                        max={1000}
                                        prefix="$"
                                        disabled={!hazardTimings?.includes("Hazard")}
                                        value={hazardRate}
                                        style={{width: '100%'}}
                                        onChange={(e) => {
                                            setHazardRate(e)
                                        }}
                                    />
                                </div>
                            </div>

                        </Checkbox.Group>
                    </div>
                </div>
                <Divider/>
                {/* Final Rate */}

                <div className={"d-flex justify-content-space-between"}>
                    <div className={'d-flex justify-content-center align-items-center'}>
                        <b style={{color: "black"}}>Final shift rate</b>
                    </div>
                    <div className={''}>
                        {/*<InputNumber*/}
                        {/*    className="custom-antd-input-number"*/}
                        {/*    disabled*/}
                        {/*    prefix="$"*/}
                        {/*    value={calculateTotalShiftRate()}*/}
                        {/*    style={{width: '100%'}}*/}
                        {/*    onChange={(e) => {*/}
                        {/*        setTotalShiftRate(e)*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <div style={{
                            width: '105px',
                            border: "1px solid #41D6C3",
                            backgroundColor: "#F2FEFF",
                            height: "31px",
                            borderRadius: "6px",
                            fontWeight: 700
                        }}>
                            <div className={'mrg-top-5 mrg-left-10'}> $ {calculateTotalShiftRate()}</div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShiftRateComponent;
