import "./JobNotesComponent.scss";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import React, {useCallback, useEffect, useState} from "react";
import TextEditorComponent from "../../../../components/text-editor/TextEditorComponent";
import {ENV} from "../../../../constants";
import {ApiService, CommonService} from "../../../../helpers";
import LoaderComponent from "../../../../components/loader/LoaderComponent";
import moment from "moment/moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";

interface JobNotesComponentProps{
    id:string;
    jobDetails:any;
}
const JobNotesComponent=(props:JobNotesComponentProps)=>{
    const {id}=props

    const [isNotesFormOpen, setIsNotesFormOpen] = useState<any>(false);
    const [notes,setNotes]=useState<any>("");
    const [notesList, setNotesList] = useState<any>([]);
    const [isNotesListLoading, setIsNotesListLoading] = useState<any>(false);
    const [isSubmitting,setIsSubmitting]=useState<boolean>(false);
    const [editableNoteId, setEditableNoteId] = useState(null);
    const [addedById, setAddedById] = useState<any>(null);
    const [notesOwner,setNotesOwner]=useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const {user} = useSelector((state: StateParams) => state.auth);
    const [userRole, setUserRole] = useState<any>(null);


    const getNotesDetails=useCallback(()=>{
        setIsNotesListLoading(true);
        CommonService._api.get(ENV.API_URL + `job/${id}/notes`)
            .then((response:any)=>{
                setNotesList(response?.data?.reverse())
        }).catch((error:any)=>{
            CommonService.showToast(error.error,'error');
        }).finally(()=>{
            setIsNotesListLoading(false);
        })
    },[id])

    useEffect(() => {
        getNotesDetails()
    }, [getNotesDetails]);


    const handleNotesAdd=useCallback(()=>{
        const payload={
            notes:notes || ""
        }
        setIsSubmitting(true)
        const apiUrl = editableNoteId
            ? ENV.API_URL + `job/${id}/note/${editableNoteId}`
            : ENV.API_URL + `job/${id}/notes`;
        const apiMethod = editableNoteId ? CommonService._api.put : CommonService._api.post;
        apiMethod(apiUrl,payload)
            .then((response:any)=>{
                CommonService.showToast(response.message,"success")
                getNotesDetails()
            }).catch((error:any)=>{
                CommonService.showToast(error.error,'error');
            }).finally(()=>{
            setIsSubmitting(false);
            setNotes("");
            setIsNotesFormOpen(false);
            setEditableNoteId(null);
        })
    },[id,notes,getNotesDetails,editableNoteId])

    const handleClose = useCallback((note: any) => {
        setAnchorEl(null);
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, note: any) => {
        setAnchorEl(event.currentTarget);
        setEditableNoteId(note._id); // Set the ID of the note being edited
        setNotes(note.notes); // Prefill the text editor with the selected note's content
    };

    const handleNotesClose = useCallback(() => {
        setNotes(''); // Reset the notes state
        setIsNotesFormOpen(false);
        setEditableNoteId(null); // Reset the editable note ID
    }, []);

    const onDeleteNotes = useCallback(() => {
        setAnchorEl(null);
        ApiService.delete(ENV.API_URL + `job/${id}/note/${addedById}`)
            .then((resp: any) => {
                setIsNotesListLoading(true);
                if (resp) {
                    CommonService.showToast(resp?.msg || "Job notes removed", "success");
                    setIsNotesFormOpen(false);
                    setIsNotesListLoading(false);
                    getNotesDetails();
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
                CommonService.showToast(err.error || "Error", "error");
            }).finally(()=>{
                setIsNotesListLoading(false);
        })
    }, [addedById, getNotesDetails,id]);

    useEffect(() => {
        if (user?.role === "super_admin" || user?.role === 'teric_admin') {
            setUserRole(user?.role);
        } else {
            setUserRole(null);
        }
    }, [user, notesOwner]);

    return (
        <div className={'job-notes-component'}>
            <div className={'job-notes-header'}>
                <div className={'notes-heading'}>Notes</div>
                <Button
                    type="primary"
                    className="add-job-button"
                    icon={<PlusOutlined />}
                    iconPosition={'start'}
                    disabled={isNotesFormOpen}
                    onClick={() => {
                        setIsNotesFormOpen(true);
                        setNotes('');
                        setEditableNoteId(null);
                    }}
                >Add Notes</Button>
            </div>
            <div className={'job-notes-content-holder'}>
                {isNotesFormOpen &&
                        <>
                            <TextEditorComponent
                                className={'notes-text-editor'}
                                value={notes}
                                onChange={(value:any)=>setNotes(value)}
                            />
                            <div className={'job-notes-btn-wrapper'}>
                                <Button onClick={handleNotesClose} className="job-button">Cancel</Button>
                                <Button onClick={handleNotesAdd} className="job-button" loading={isSubmitting}>Save</Button>
                            </div>
                        </>
                }
                {!isNotesListLoading && notesList ?
                    <div className={'job-notes'}>
                        {notesList?.map((note: any) => {
                            const m = moment(note?.created_at);
                            return (
                                <div className={'notes-card'}>
                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                        <div dangerouslySetInnerHTML={{__html: note.notes}}
                                             className={'notes-card-content'}>
                                        </div>
                                        <Button disabled={isNotesFormOpen} style={{cursor: "pointer"}}
                                                onClick={(event: any) => {
                                                    handleClick(event,note);
                                                    setAddedById(note?._id);
                                                    if(note?.user_details?._id === user?._id) {
                                                        setNotesOwner(true);
                                                    }else {
                                                        setNotesOwner(false);
                                                        if (!userRole) {
                                                            CommonService.showToast('Your are not the notes writer', 'error');
                                                        }
                                                    }
                                                }}>
                                            <MoreVertIcon/>
                                        </Button>
                                        {(notesOwner || userRole?.length > 0) && <>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                {notesOwner && <>
                                                    <MenuItem onClick={() => {
                                                        setIsNotesFormOpen(true);
                                                        setAnchorEl(null);
                                                        window.scrollTo({top: 0, behavior: "smooth"});
                                                    }}>
                                                        <div>
                                                            Edit
                                                        </div>
                                                    </MenuItem>
                                                </>}
                                                {(userRole === 'super_admin' || userRole === 'teric_admin') && <>
                                                    <MenuItem onClick={() => {
                                                        onDeleteNotes();
                                                    }}>Delete</MenuItem>
                                                </>}
                                            </Menu>
                                        </>}
                                    </div>
                                    <div className={'notes-author'}>
                                        <div>Added
                                            By &nbsp;:&nbsp; {note?.user_details?.first_name} {note?.user_details?.last_name} &nbsp;|&nbsp; {m.format("dddd")} &nbsp;|&nbsp; {m.format("MMMM Do YYYY")} &nbsp;|&nbsp; {m.format("h:mm:ss a")}</div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div> :
                    <div className={'notes-loading-container'}>
                        <LoaderComponent type={'spinner'} color={'primary'} size={'sm'}/>
                    </div>
                }
                <div>

                </div>
            </div>
        </div>
    )
}

export default JobNotesComponent;