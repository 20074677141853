import React, {useCallback, useEffect, useState} from "react";
import {Steps, Button} from "antd";
import "./JobEditComponent.scss";
import {useHistory, useParams} from "react-router-dom";
import JobEditBasicDetailsComponent from "./job-edit-basic-details/JobEditBasicDetailsComponent";
import JobEditCredentialComponent from "./job-edit-credential/JobEditCredentialComponent";
import {ApiService, CommonService} from "../../../helpers";
import {ENV} from "../../../constants";
import LoaderComponent from "../../../components/loader/LoaderComponent";
import JobRateTable from "../add-job/job-rates/JobRateComponent";
const {Step} = Steps;

const JobEditComponent: React.FC = () => {
    const history = useHistory();
    const {id}=useParams<any>();
    const [current, setCurrent] = useState(0);
    const [buttonText, setButtonText] = useState<any>("Next");
    const [isJobDetailsLoading,setIsJobDetailsLoading]=useState<boolean>(false);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    const [modifiedNcList, setModifiedNcList] = useState<any | null>([]);
    const [isFacilityLoading,setIsFacilityLoading]=useState<boolean>(false);
    const [jobDetails,setJobDetails]=useState<any>(null);
    const [jobRateDetails, setJobRateDetails] = React.useState<any>(null);
    const [isNcListLoading,setIsNcListLoading]=useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [current]);

    const getNcList=useCallback(()=>{
        setIsNcListLoading(true);
        CommonService._api.get(ENV.API_URL + `user/lite?is_active=true&roles=account_manager&roles=nurse_champion&roles=hr&roles=operational_manager&roles=finance&roles=super_admin&roles=teric_admin`).then((response:any)=>{
            console.log(response?.data,"DATA")
            const transformedData = response?.data?.map((item:any) => ({
                code: item?._id,
                title: item?.first_name + " " + item?.last_name,
            }));
            setModifiedNcList(transformedData || []);
        }).catch((error:any)=>{
            console.log(error)
        }).finally(()=>{
            setIsNcListLoading(false);
        })
    },[])

    useEffect(() => {
        getNcList()
    }, [getNcList]);


    const getFacilityData = useCallback(() => {
        let payload: any = {};
        setIsFacilityLoading(true)
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                //@ts-ignore
                const transformedData = res?.data?.map(({_id, name, ...rest}) => ({
                    code: _id,
                    title: name,
                    ...rest, // Include other properties if needed
                }));
                setFacilityList(transformedData || []);
            })
            .catch((err) => {
                console.log(err);
            }).finally(()=>{
            setIsFacilityLoading(false)
        })
    }, []);

    useEffect(() => {
        getFacilityData()
    }, [getFacilityData]);

    const getJobDetails = useCallback(() => {
        setIsJobDetailsLoading(true);
        CommonService._api
            .get(ENV.API_URL + `ats/${id}/job`)
            .then((resp) => {
                setJobDetails(resp.data);
                setIsJobDetailsLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setIsJobDetailsLoading(false)
            });
    }, [id]);

    const getJobRateDetails = useCallback(() => {
        CommonService._api
            .get(ENV.API_URL + `ats/${id}/ratecard`)
            .then((resp) => {
                setJobRateDetails(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [id])

    useEffect(() => {
        getJobDetails();
        getJobRateDetails();
    }, [getJobDetails,getJobRateDetails]);

    useEffect(() => {
        if (current === 0) {
            setButtonText("Next")
        }
        if (current === 2) {
            setButtonText("Submit")
        }
    }, [current]);

    const next = useCallback(() => {
        setCurrent(current + 1);
    }, [current]);


    const steps = [
        {
            title: "Basic Details",
            content: (
                <>
                    <JobEditBasicDetailsComponent
                        next={next}
                        jobDetails={jobDetails}
                        facilityList={facilityList}
                        modifiedNcList={modifiedNcList}
                        getJobDetails={getJobDetails}
                    />
                </>
            ),
        },
        {
            title: "Credentialing",
            content: (
                <>
                    <JobEditCredentialComponent
                        setButtonText={setButtonText}
                        next={next}
                        jobDetails={jobDetails}
                    />
                </>
            ),
        },
        {
            title: "Rate Card",
            content: (
                <>
                    <JobRateTable
                        apiDataAfterAdd={jobDetails}
                        rate_details={jobRateDetails}
                    />
                </>
            ),
        },
    ];

    const handleSubmit=useCallback(()=>{
        const formId =
            current === 0
                ? "add-job-basic-details"
                : current === 1
                    ? "add-job-credentialing"
                    : "add-job-rate-card";
        const formElement = document.getElementById(formId) as HTMLFormElement;
        formElement?.requestSubmit(); // Ensure Formik's submit handler gets triggered
    },[current])


    return (
        <>
            <div className={'add-job-form-container-whole'}>
                <div className={'form-header'}>
                    <div className="header-title pdd-10">Edit Job</div>
                    {/*<Button className="next-button" ghost type={'default'}>Save as draft</Button>*/}
                </div>
                <div className={'mrg-top-30'}>
                    <div className="d-flex justify-content-center align-items-center">
                        <div style={{width: "50%"}}>
                            <Steps current={current}>
                                {steps.map((step, index) => (
                                    <Step key={index} title={step.title}/>
                                ))}
                            </Steps>
                        </div>
                    </div>
                </div>
                <div className="form-body">
                    {
                       (isJobDetailsLoading || isFacilityLoading || isNcListLoading) ?
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <LoaderComponent type={'spinner'} color={'primary'}/>
                            </div> :
                            <div>{steps[current].content}</div>
                    }
                </div>

                {/* Fixed Footer with 'Next' button */}
                <div className="form-footer">
                    <Button type="default" className="exit-button" onClick={() => {
                        history.push(`/job/${id}`);
                    }}>
                        Exit
                    </Button>
                    <Button
                        type="primary"
                        className="next-button"
                        onClick={handleSubmit}
                    >
                        {buttonText}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default JobEditComponent;
