import {List, ListItem, ListItemIcon, ListItemText, ListSubheader} from "@material-ui/core";
import HomeOutlined from "@material-ui/icons/HomeOutlined";
import React from "react";
import {NavLink} from "react-router-dom";
import {clearFacilityFilterValues} from "../app/facilityManagement/filters/FacilityFiltersComponent";
import {clearHcpFilterValues} from "../app/hcpManagement/filters/HcpFiltersComponent";
import {clearShiftFilterValues} from "../app/shiftManagement/filters/ShiftFilter";
import {
    ColorDashboard,
    ColorEmployeeOnboardingIcon,
    ColorfacilityMaster, ColorGroupAdd,
    // ColorGroupAdd,
    ColorHCPManagement,
    ColorHCPOnboarding,
    ColorNotification,
    ColorPendingShiftIcon,
    ColorShiftRequirement,
    ColorShiftsCancelled,
    ColorShiftsClosed,
    ColorShiftsCompleted,
    ColorShiftsInprogress,
    ColorShiftsMaster,
    ColorShiftsPending, ColorSMSBlast,
    // ColorSMSBlast,
    Dashboard,
    EmployeeOnboardingIcon,
    FacilityMaster,
    GroupAdd,
    HCPManagement,
    HCPOnboarding,
    Notification,
    PendingShiftIcon,
    ShiftRequirement,
    ShiftsCancelled,
    ShiftsClosed,
    ShiftsCompleted,
    ShiftsInprogress,
    ShiftsMaster,
    ShiftsPending, SMSBlast,
    // SMSBlast,
} from "../constants/ImageConfig";

import {
    ACCOUNTMANAGER,
    ADMIN,
    FINANCE,
    HUMANRESOURCE,
    NURSECHAMPION,
    OPERATIONALMANAGER, TERICADMIN,
    VMSUSER
} from "../helpers/common-service";
import AccessControlComponent from "./AccessControl";
import {useDispatch} from "react-redux";
import {getFacilityDocuments} from "../store/actions/meta.action";

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: any;
    children: any;
    clearLocalFilters?: () => void;
    isClearFilter?: boolean;
    allowed_roles: ("super_admin" | "account_manager" | "nurse_champion" | "hr" | "finance" | 'operational_manager' | 'teric_admin'| 'vms_user')[];
}

function clearLocalFilters() {
    clearFacilityFilterValues()
    clearShiftFilterValues()
    clearHcpFilterValues()
}


const MenuItemsComponent = (props: any) => {
    const dispatch = useDispatch()
    const MENUITEMS: Menu[] = [
        {
            state: "",
            name: "Dashboard",
            type: "",
            icon: <HomeOutlined/>,
            allowed_roles: [ADMIN, ACCOUNTMANAGER, NURSECHAMPION, FINANCE, HUMANRESOURCE, TERICADMIN, VMSUSER],
            children: [
                {
                    state: "/dashboard",
                    name: "Dashboard",
                    type: "link",
                    icon: Dashboard,
                    coloredIcon: ColorDashboard,
                },
            ],
        },
        {
            state: "",
            isClearFilter: true,
            clearLocalFilters: clearLocalFilters,
            name: "Facility",
            type: "",
            icon: <HomeOutlined/>,
            allowed_roles: [ADMIN, ACCOUNTMANAGER, NURSECHAMPION, OPERATIONALMANAGER, FINANCE, TERICADMIN, VMSUSER],
            children: [
                {
                    state: "/facility/list",
                    name: "Facility Management",
                    type: "link",
                    icon: FacilityMaster,
                    coloredIcon: ColorfacilityMaster,
                },
            ],
        },
        {
            state: "",
            isClearFilter: true,
            clearLocalFilters: clearLocalFilters,
            name: "ATS",
            type: "",
            icon: <HomeOutlined/>,
            allowed_roles: [ADMIN,TERICADMIN,VMSUSER],
            children: [
                {
                    state: "/job",
                    name: "Job",
                    type: "link",
                    icon: ShiftRequirement,
                    coloredIcon: ColorShiftRequirement,
                },
                {
                    state: "/applicants",
                    name: "Applicants",
                    type: "link",
                    icon: PendingShiftIcon,
                    coloredIcon: ColorPendingShiftIcon,
                },
                {
                    state: "/pipeline",
                    name: "My Pipeline",
                    type: "link",
                    icon: ShiftsMaster,
                    coloredIcon: ColorShiftRequirement,
                },
                // {
                //     state: "/awaitingShifts/list",
                //     name: "Placements",
                //     type: "link",
                //     icon: PendingShiftIcon,
                //     coloredIcon: ColorPendingShiftIcon,
                // },
            ],
        },
        // {
        //     state: "",
        //     isClearFilter: true,
        //     clearLocalFilters: clearLocalFilters,
        //     name: "Book of Business",
        //     type: "",
        //     icon: <HomeOutlined/>,
        //     allowed_roles: [ADMIN,TERICADMIN,VMSUSER],
        //     children: [
        //         {
        //             state: "/pipeline",
        //             name: "My Pipeline",
        //             type: "link",
        //             icon: ShiftsMaster,
        //             coloredIcon: ColorShiftRequirement,
        //         },
        //
        //     ],
        // },
        {
            state: "",
            isClearFilter: true,
            clearLocalFilters: clearLocalFilters,
            name: "Applications",
            type: "",
            icon: <HomeOutlined/>,
            allowed_roles: [ADMIN, HUMANRESOURCE, TERICADMIN],
            children: [
                {
                    state: "/hcp/list",
                    name: "Application Tracking System (ATS)",
                    type: "link",
                    icon: HCPOnboarding,
                    coloredIcon: ColorHCPOnboarding,
                },
            ],
        },
        {
            state: "",
            isClearFilter: true,
            clearLocalFilters: clearLocalFilters,
            name: "Licensed Users",
            type: "",
            icon: <HomeOutlined/>,
            allowed_roles: [ADMIN, NURSECHAMPION, ACCOUNTMANAGER, HUMANRESOURCE, OPERATIONALMANAGER, FINANCE, TERICADMIN],
            children: [
                {
                    state: "/hcp/user/list",
                    name: "Approved Users",
                    type: "link",
                    icon: HCPManagement,
                    coloredIcon: ColorHCPManagement,
                },
            ],
        },
        {
            state: "",
            isClearFilter: true,
            clearLocalFilters: clearLocalFilters,
            name: "Team Management",
            type: "",
            icon: <HomeOutlined/>,
            allowed_roles: [ADMIN, OPERATIONALMANAGER, TERICADMIN],
            children: [
                {
                    state: "/employee/list",
                    name: "Team Members",
                    type: "link",
                    icon: EmployeeOnboardingIcon,
                    coloredIcon: ColorEmployeeOnboardingIcon,
                }
            ],
        },
        {
            state: "",
            isClearFilter: true,
            clearLocalFilters: clearLocalFilters,
            name: "Communication",
            type: "",
            icon: <HomeOutlined/>,
            allowed_roles: [ADMIN, ACCOUNTMANAGER, NURSECHAMPION, FINANCE, TERICADMIN],
            children: [
                {
                    state: "/group/list",
                    name: "Create Group",
                    type: "link",
                    icon: GroupAdd,
                    coloredIcon: ColorGroupAdd,
                },
                {
                    state: "/sendSmsBlast",
                    name: "Send SMS Blast",
                    type: "link",
                    icon: SMSBlast,
                    coloredIcon: ColorSMSBlast,
                },
            ],
        },

        {
            state: "",
            isClearFilter: true,
            clearLocalFilters: clearLocalFilters,
            name: "Maintenance Module",
            type: "",
            icon: <HomeOutlined/>,
            allowed_roles: [ADMIN, TERICADMIN],
            children: [
                {
                    state: "/maintenance/notification",
                    name: "Notifications",
                    type: "link",
                    icon: Notification,
                    coloredIcon: ColorNotification,
                },

            ],
        },


        {
            state: "",
            isClearFilter: true,
            clearLocalFilters: clearLocalFilters,
            name: "Shift Management",
            type: "",
            icon: <HomeOutlined/>,
            allowed_roles: [ADMIN, ACCOUNTMANAGER, NURSECHAMPION, FINANCE,TERICADMIN],
            children: [
                {
                    state: "/shiftrequirementMaster/list",
                    name: "Open",
                    type: "link",
                    icon: ShiftRequirement,
                    coloredIcon: ColorShiftRequirement,
                },
                {
                    state: "/pendingShifts/list",
                    name: "Pending",
                    type: "link",
                    icon: PendingShiftIcon,
                    coloredIcon: ColorPendingShiftIcon,
                },
                {
                    state: "/awaitingShifts/list",
                    name: "Awaiting",
                    type: "link",
                    icon: PendingShiftIcon,
                    coloredIcon: ColorPendingShiftIcon,
                },
                {
                    state: "/approvedShifts/list",
                    name: "Approved",
                    type: "link",
                    icon: ShiftsPending,
                    coloredIcon: ColorShiftsPending,
                },
                {
                    state: "/inprogessShifts/list",
                    name: "Inprogress",
                    type: "link",
                    icon: ShiftsInprogress,
                    coloredIcon: ColorShiftsInprogress,
                },
                {
                    state: "/completedShifts/list",
                    name: "Completed",
                    type: "link",
                    icon: ShiftsCompleted,
                    coloredIcon: ColorShiftsCompleted,
                },
                {
                    state: "/closedShifts/list",
                    name: "Closed",
                    type: "link",
                    icon: ShiftsClosed,
                    coloredIcon: ColorShiftsClosed,
                },
                {
                    state: "/cancelledShifts/list",
                    name: "Cancelled",
                    type: "link",
                    icon: ShiftsCancelled,
                    coloredIcon: ColorShiftsCancelled,
                },
                {
                    state: "/shiftMaster/list",
                    name: "Master",
                    type: "link",
                    icon: ShiftsMaster,
                    coloredIcon: ColorShiftsMaster,
                },
            ],
        },
        {
            state: "",
            isClearFilter: true,
            clearLocalFilters: clearLocalFilters,
            name: "Accounting Module",
            type: "",
            icon: <HomeOutlined/>,
            allowed_roles: [ADMIN, FINANCE,TERICADMIN],
            //  allowed_roles:[],
            children: [
                {
                    state: "/accounting",
                    name: "Accounting",
                    type: "link",
                    icon: Notification,
                    coloredIcon: ColorNotification,
                },
            ],
        },
    ];

    return (
        <List>
            {MENUITEMS &&
                MENUITEMS.length > 0 &&
                MENUITEMS.map((item: any, index) => {
                    return (
                        <AccessControlComponent
                            key={index + "-menu-item"}
                            role={item.allowed_roles}
                        >
                            <div onClick={() => item?.isClearFilter && item.clearLocalFilters()}>
                                <ListSubheader>{item.name}</ListSubheader>
                                {item.children &&
                                    item.children.length > 0 &&
                                    item.children.map((subItem: any, index: any) => {
                                        return (
                                            <ListItem button component={NavLink} to={subItem.state}
                                                      id={"menu-item-" + subItem.name} key={index + "sub-menu-item"}
                                                      onClick={() => {
                                                          if (subItem.name === "Application Tracking System (ATS)" || subItem.name === "Staff Approved") {
                                                              dispatch(getFacilityDocuments(false))
                                                          } else if (subItem.name === "Facility Management") {
                                                              dispatch(getFacilityDocuments(true))
                                                          }
                                                          console.log(subItem.name);
                                                      }}>
                                                <ListItemIcon className={'active-icon'}><img src={subItem.icon}
                                                                                             alt="icon"/></ListItemIcon>
                                                <ListItemIcon className={'inactive-icon'}><img src={subItem.coloredIcon}
                                                                                               alt="filled-icon"/></ListItemIcon>

                                                <ListItemText primary={subItem.name}/>
                                            </ListItem>
                                        );
                                    })}
                            </div>
                        </AccessControlComponent>
                    );
                })}

        </List>
    );
};

export default MenuItemsComponent;
