import "./JobFilterDrawerComponent.scss";
import {makeStyles} from "@material-ui/core/styles";
import {ImageConfig} from "../../../../constants";
import DatePicker from "react-datepicker";
import {DateRangeOutlined} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, {useCallback} from "react";
import moment from "moment";

interface JobFilterDrawerComponentProps{
    filterData:any;
    setFilterData:any;
    setOpenFilterDrawer:any;
    hcpTypes:any[];
    isFacilityListLoading:boolean;
    facilityList:any[];
    resetFilters:any;
}

const useStyles = makeStyles((theme) => ({
    option: {
        '&[aria-selected="true"]': {
            backgroundColor: '#41D6C3',
            color: 'white',
        },
    },
}));

const statusList=[
    {title: 'Open', code: 'open'},
    {title: 'Closed', code: 'closed'},
    {title: 'Filled', code: 'filled'},
    {title: 'Hold', code: 'hold'},
    {title: 'In Progress', code: 'in_progress'},
]

const jobTypeList=[
    {title: 'Per Diem', code: 'per_diem'},
    {title: 'Contract', code: 'contract'},
    {title: 'Permanent', code: 'permanent'},
]

const JobFilterDrawerComponent=(props:JobFilterDrawerComponentProps)=>{
    const {filterData,setFilterData,setOpenFilterDrawer,hcpTypes,isFacilityListLoading,resetFilters}=props;
    const classes=useStyles();

    const facilityList: any[] = props?.facilityList
        ? props?.facilityList.map((item: any) => ({code: item?._id, name: item?.name}))
        : [];


    const formatFilterMenuList = useCallback((options: any[],filterData:any,key:string): any[] => {
        const selectedValues = filterData[key] || []; // Get the selected values for the key
        const allOptions = options?.map((option: any) => option?.code);

        let menuList = [...allOptions];

        if (selectedValues.length === allOptions.length) {
            // All options are selected, show "Clear All"
            menuList = ["Clear All", ...menuList];
        } else {
            // Not all options are selected, show "Select All"
            menuList = ["Select All", ...menuList];
        }

        return menuList;
    }, []);

    const formatFilterMenuListToHandleObj = useCallback(
        (options: any[], filterData: any, key: string): any[] => {
            const allOptions = options.filter(
                (item) => item.code !== "Select All" && item.code !== "Clear All"
            );

            const selectedValues = filterData[key] || []; // Get selected values for the key
            const allCodes = allOptions.map((item) => item.code);

            let menuList = allOptions;

            // Check if all options are selected
            if (selectedValues.length === allCodes.length) {
                menuList = [{ name: "Clear All", code: "Clear All" }, ...allOptions];
            } else {
                // If not all are selected, show "Select All"
                menuList = [{ name: "Select All", code: "Select All" }, ...allOptions];
            }

            return menuList;
        },
        []
    );


    const handleFilterValueSelect = useCallback(
        (
            newValues: any,
            options: any[],
            event: any,
            key: string
        ) => {

            // Extract the 'code' from each selected object in newValues
            const selectedValues = newValues.map((value: any) =>
                typeof value === "string" ? value : value.code
            );

            if (selectedValues.includes("Select All") || selectedValues.includes("Clear All")) {
                if (selectedValues.includes("Select All")) {
                    const allOptions = options.map((item: any) => item.code);

                    // Update filterData for Select All
                    setFilterData((prevData: any) => ({
                        ...prevData,
                        [key]: allOptions,
                    }));
                }

                if (selectedValues.includes("Clear All")) {

                    // Clear filterData for Clear All
                    setFilterData((prevData: any) => {
                        const { [key]: removedKey, ...rest } = prevData; // Remove the key from the object
                        return rest;
                    });
                }

                return;
            }

            // Dynamically add the key to filterData only when values are selected
            setFilterData((prevData: any) => {
                if (selectedValues.length === 0) {
                    const { [key]: removedKey, ...rest } = prevData;
                    return rest;
                }

                // Special case for "hcp_types"
                if (key === "hcp_types") {
                    const combinedTitleIndex = selectedValues.indexOf("CNA/CHHA");

                    // If "CNA/CHHA" is selected, split it into "CNA" and "CHHA"
                    if (combinedTitleIndex !== -1) {
                        const processedValues = [
                            ...selectedValues.filter((title: any) => title !== "CNA/CHHA"), // Exclude "CNA/CHHA"
                            "CNA", // Add "CNA"
                            "CHHA", // Add "CHHA"
                            "CNA/CHHA", // Keep "CNA/CHHA" as is
                        ];
                        return {
                            ...prevData,
                            [key]: processedValues,
                        };
                    }
                }

                // Default case for other keys
                return {
                    ...prevData,
                    [key]: selectedValues,
                };
            });
        },
        [setFilterData]
    );



    const handleFilterValueSelectToHandleObj = useCallback(
        (
            newValues: any,
            options: any[],
            key: string, // The key to dynamically update in filterData
        ) => {
            const isSelectAll = newValues.some((f: any) => f.code === "Select All");
            const isClearAll = newValues.some((f: any) => f.code === "Clear All");

            if (newValues.length === 0) {
                // Remove the key from filterData if no values are selected
                setFilterData((prevData: any) => {
                    const {[key]: removedKey, ...rest} = prevData;
                    return rest;
                });
            }

            if (isSelectAll || isClearAll) {
                if (isSelectAll) {
                    const allOptions = options.filter((item) => {
                        return item.code !== "Select All" && item.code !== "Clear All";
                    });

                    // Add all options to filterData under the specified key
                    setFilterData((prevData: any) => ({
                        ...prevData,
                        [key]: allOptions.map((item) => item.code), // Map to codes
                    }));
                }

                if (isClearAll) {

                    // Remove the key from filterData
                    setFilterData((prevData: any) => {
                        const {[key]: removedKey, ...rest} = prevData;
                        return rest;
                    });
                }

                return;
            }

            // For single select

            // Dynamically update filterData with the selected values
            setFilterData((prevData: any) => {
                // If no new values, remove the key from filterData
                if (newValues.length === 0) {
                    const {[key]: removedKey, ...rest} = prevData;
                    return rest;
                }

                // Otherwise, add or update the key in filterData
                return {
                    ...prevData,
                    [key]: newValues.map((item: any) => item.code), // Map to codes
                };
            });
        },
        [setFilterData]
    );

    const handleDateRangeSelection = useCallback((dateRange: [Date | null, Date | null]) => {
        const [newStartDate, newEndDate] = dateRange || [];

        const startDateFormatted = newStartDate
            ? moment(newStartDate).format("YYYY-MM-DD")
            : null;
        const endDateFormatted = newEndDate
            ? moment(newEndDate || newStartDate).format("YYYY-MM-DD")
            : null;

        const updatedFilterData={...filterData}


        if (startDateFormatted) {
            updatedFilterData.start_date = startDateFormatted;
            // Clear the end_date if the user picks a new start_date
            if (filterData.start_date !== startDateFormatted) {
                delete updatedFilterData.end_date;
                delete updatedFilterData.new_shifts;
            }
        }

        if(endDateFormatted){
            updatedFilterData.end_date=endDateFormatted;
        }


        if (!startDateFormatted && !endDateFormatted) {
            delete updatedFilterData.start_date;
            delete updatedFilterData.end_date;
            // updatedFilterData.new_shifts=CommonService.changeToUtcDate(new Date())
        }


        setFilterData(updatedFilterData);
    }, [filterData, setFilterData]);

    const selectedHcpCodes = filterData?.staff_types || [];
    const updatedSelectedHcp = hcpTypes?.filter(({ code }) => selectedHcpCodes.includes(code));

    const selectedStatusCodes=filterData?.status || [];
    const updatedSelectedStatus=statusList?.filter(({code})=>selectedStatusCodes.includes(code));

    const selectedJobTypeCodes=filterData?.job_types || [];
    const updatedSelectedJobType=jobTypeList?.filter(({code})=>selectedJobTypeCodes.includes(code));


    return (
        <div className={'facility-filter-component'}>
            <div className={'facility-filter-header-wrapper'}>
                <div className={'facility-filter-header'}>
                    <div className={'facility-filter-title'}>Filters</div>
                    <div className={'facility-filter-clear-all'}
                         onClick={() => {
                             resetFilters();
                             setOpenFilterDrawer(false);
                         }}
                    >Clear All</div>
                </div>
                <div
                    className={'facility-filter-close-btn'}
                    onClick={() => setOpenFilterDrawer(false)}
                >
                    <img src={ImageConfig.CrossIcon} alt={'cross-icon'}/>
                </div>
            </div>
            <div className={'facility-filter-fields-wrapper'}>
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        disabled={isFacilityListLoading}
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        getOptionLabel={(option) => option?.name}
                        getOptionSelected={(option, value) => option.name === value?.name}
                        id="input_select_facility"
                        // value={selectedFacilities}
                        value={facilityList?.filter((item: any) => filterData?.facilities?.includes(item.code))}
                        options={formatFilterMenuListToHandleObj(facilityList, filterData, 'facilities')}
                        onChange={(e, newValue) => {
                            if (newValue) {
                                handleFilterValueSelectToHandleObj(
                                    newValue,
                                    facilityList,
                                    "facilities"
                                );
                            }
                        }}
                        // renderTags={() => null}
                        classes={{
                            option: classes.option,
                        }}
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                            {selectedCount} selected
                                        </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.facilities?.length === 0 || !filterData?.facilities
                                ? "Facilities"
                                : "";
                            return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                        }}
                        disableCloseOnSelect
                    />
                    {isFacilityListLoading && (
                        <div className="loading-text-wrapper">
                            <span className="loading-text">loading...</span>
                        </div>
                    )}
                </div>
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_hcps"
                        value={updatedSelectedHcp?.map((staff: any) => staff.code)}
                        classes={{
                            option: classes.option,
                        }}
                        options={formatFilterMenuList(hcpTypes, filterData, "staff_types")}
                        onChange={(e, newValue) =>
                            handleFilterValueSelect(
                                newValue,
                                hcpTypes,
                                e,
                                "staff_types"
                            )
                        }
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                    {selectedCount} selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.staff_types?.length === 0 || !filterData?.staff_types
                                ? "Staff Types"
                                : "";
                            return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                        }}
                        disableCloseOnSelect
                    />
                </div>
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_status"
                        value={updatedSelectedStatus?.map((status: any) => status.code)}
                        classes={{
                            option: classes.option,
                        }}
                        options={formatFilterMenuList(statusList, filterData, "staff_types")}
                        onChange={(e, newValue) =>
                            handleFilterValueSelect(
                                newValue,
                                hcpTypes,
                                e,
                                "status"
                            )
                        }
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                    {selectedCount} selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.status?.length === 0 || !filterData?.status
                                ? "Status"
                                : "";
                            return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                        }}
                        disableCloseOnSelect
                    />
                </div>
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_job_type"
                        value={updatedSelectedJobType?.map((jobType: any) => jobType.code)}
                        classes={{
                            option: classes.option,
                        }}
                        options={formatFilterMenuList(jobTypeList, filterData, "job_types")}
                        onChange={(e, newValue) =>
                            handleFilterValueSelect(
                                newValue,
                                jobTypeList,
                                e,
                                "job_types"
                            )
                        }
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                    {selectedCount} selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.job_types?.length === 0 || !filterData?.job_types
                                ? "Job Type"
                                : "";
                            return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                        }}
                        disableCloseOnSelect
                    />
                </div>
                <div className="mrg-top-10">
                    <label className="label-wrapper">
                        <DatePicker
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select Date"
                            className="shift-custom-input"
                            selectsRange={true}
                            startDate={filterData.start_date && new Date(`${filterData.start_date}T00:00:00`)}
                            endDate={filterData.end_date && new Date(`${filterData.end_date}T00:00:00`)}
                            onChange={(update) => {
                                // setDateRange(update);
                                handleDateRangeSelection(update);
                            }}
                            isClearable={true}
                        />
                        {!filterData?.start_date && !filterData?.end_date && (
                            <DateRangeOutlined className="date-icon" fontSize="medium" color="action"/>
                        )}
                    </label>
                </div>
            </div>
        </div>
    )
}

export default JobFilterDrawerComponent