import "./ApplicantMenuListComponent.scss";
import {SendMailIcon} from "../../../constants/ImageConfig";
// AddGroupIcon,AddNotesIcon,ActivateAccountIcon
import {Link} from "react-router-dom";


interface ApplicantMenuListComponentProps{

}

const MENU_ITEMS=[
    // {
    //     label:'Add Notes',
    //     id:'add_notes',
    //     icon:AddNotesIcon,
    //     link:'/applicants',
    // },
    {
        label:"Bulk Email",
        id:'bulk_mail',
        icon:SendMailIcon,
        link:'/bulk-email',
    },
    // {
    //     label:"De-Activate Account",
    //     id:'de-activate',
    //     icon:ActivateAccountIcon,
    //     link:'/applicants',
    // },
    // {
    //     label:"Add to Group",
    //     id:'add_group',
    //     icon:AddGroupIcon,
    //     link:'/applicants',
    // }
]

const ApplicantMenuListComponent=(props:ApplicantMenuListComponentProps)=>{
    return (
        <div className={'applicant-menu-list-component'}>
            {MENU_ITEMS?.map((item:any)=>{
                return (
                    <Link to={item.link} key={item.id} className={'applicant-menu-item'}>
                        <img src={item.icon} alt={item.id}/>
                        <span>
                            {item.label}
                        </span>
                    </Link>
                )
            })}
        </div>
    )
}

export default ApplicantMenuListComponent