import "./ContractRateCardDetailsComponent.scss";
import {Divider} from "antd";
import DataLabelValueComponent from "../../../../components/data-label-value/DataLabelValueComponent";

interface ContractRateCardDetailsComponentProps{
    rateDetails?:any;
}

const ContractRateCardDetailsComponent=(props:ContractRateCardDetailsComponentProps)=>{
    const {rateDetails}=props;
    return (
        <div className={'mrg-top-20 contract-rate-card-details'}>
            <div className='contract-rate-left-card'>
                <div className={'rate-type-header'}>Pay Rate Details</div>
                {/*<div className={'mrg-top-20'}>*/}
                {/*    <DataLabelValueComponent label={'Pay Rate'}>*/}
                {/*        {rateDetails?.pay_rate?.total_pay_rate ?*/}
                {/*            <>*/}
                {/*                $ {rateDetails?.pay_rate?.total_pay_rate}*/}
                {/*            </> : 'N/A'*/}
                {/*        }*/}
                {/*    </DataLabelValueComponent>*/}
                {/*</div>*/}
                {/*<div className={'d-flex align-items-center mrg-bottom-15 mrg-top-15'}>*/}
                {/*    <div className={'rate-type-header'}>Rates</div>*/}
                {/*</div>*/}
                <div className={'d-flex mrg-top-20'}>
                    <div className={'flex-equal'}>
                        <DataLabelValueComponent label={'Type'}>

                            {
                                rateDetails?.pay_rate?.travel_per_diems?.map((item: any, index: any) => {
                                    return (
                                        <div className={'mrg-bottom-20'}>
                                            {item?.type}
                                        </div>
                                    )
                                })
                            }

                        </DataLabelValueComponent>
                    </div>
                    <div className={'flex-equal'}>
                        <DataLabelValueComponent
                            label={'Rate'}
                        >
                            {
                                rateDetails?.pay_rate?.travel_per_diems?.map((item: any, index: any) => {
                                    return (
                                        <div
                                            className={'d-flex ts-justify-content-between mrg-bottom-20'}>
                                            <div>
                                                {item?.rate ? <>
                                                    $ {item?.rate} {index === 0 ? "/hr" : "/day"}
                                                </> : "N/A"
                                                }
                                            </div>
                                            {/*<div*/}
                                            {/*    className={'days-rate-text-orientation '}>*/}
                                            {/*    {`(${shiftRequirementDetails?.day_per_week}days  *  $${item?.rate})`}*/}
                                            {/*</div>*/}
                                        </div>
                                    )
                                })
                            }

                        </DataLabelValueComponent>
                    </div>
                    <div className={'flex-equal'}>
                        <DataLabelValueComponent
                            label={'Rate/week'}
                        >
                            {
                                rateDetails?.pay_rate?.travel_per_diems?.map((item: any, index: any) => {
                                    return (
                                        <div
                                            className={'d-flex ts-justify-content-between mrg-bottom-20'}>
                                            <div>
                                                {item?.week_rate ?
                                                    <>
                                                        $ {item?.week_rate} /week
                                                    </>: "N/A"
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </DataLabelValueComponent>
                    </div>
                </div>
                <Divider className={'divider'}/>
                <div className={'rate-type-header'}>Bill Rate Details</div>
                <div className={'mrg-top-20 mrg-bottom-20'}>
                    <div className={'d-flex mrg-bottom-20'}>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Bill Rate Per Hour'}>
                                {rateDetails?.bill_rate?.bill_rate_per_hour ?
                                    <>
                                        $ {rateDetails?.bill_rate?.bill_rate_per_hour}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Daily Lodging - Daily (GSA)'}>
                                {rateDetails?.bill_rate?.daily_lodging_gsa ?
                                    <>
                                        {rateDetails?.bill_rate?.daily_lodging_gsa}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'M&I Daily'}>
                                {rateDetails?.bill_rate?.mi_daily ?
                                    <>
                                        {rateDetails?.bill_rate?.mi_daily}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                    </div>
                    <div className={'d-flex mrg-bottom-20'}>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Guaranteed Hours-Weekly'}>
                                {rateDetails?.bill_rate?.guaranteed_hours_weekly ?
                                    <>
                                        {rateDetails?.bill_rate?.guaranteed_hours_weekly}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'VMS Fee of Bill Rate'}>
                                {rateDetails?.bill_rate?.vms_fee_bill_rate ?
                                    <>
                                        $ {rateDetails?.bill_rate?.vms_fee_bill_rate}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'State Burden-State Taxes'}>
                                {rateDetails?.bill_rate?.state_burden_taxes ?
                                    <>
                                        $ {rateDetails?.bill_rate?.state_burden_taxes}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                    </div>
                    <div className={'d-flex mrg-bottom-20'}>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Gross Hourly Pay Rate(All Inclusive)'}>
                                {rateDetails?.bill_rate?.gross_hourly_pay_rate ?
                                    <>
                                        $ {rateDetails?.bill_rate?.gross_hourly_pay_rate}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Total Daily Stipend'}>
                                {rateDetails?.bill_rate?.total_daily_stipend ?
                                    <>
                                        $ {rateDetails?.bill_rate?.total_daily_stipend}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Weekly Stipend'}>
                                {rateDetails?.bill_rate?.weekly_stipend ?
                                    <>
                                        $ {rateDetails?.bill_rate?.weekly_stipend}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                    </div>
                    <div className={'d-flex mrg-bottom-20'}>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Hourly Stipend'}>
                                {rateDetails?.bill_rate?.hourly_stipend ?
                                    <>
                                        $ {rateDetails?.bill_rate?.hourly_stipend}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Tax Free'}>
                                {rateDetails?.bill_rate?.tax_free_hourly ?
                                    <>
                                        $ {rateDetails?.bill_rate?.tax_free_hourly}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Taxable Hourly Income'}>
                                {rateDetails?.bill_rate?.taxable_hourly_income ?
                                    <>
                                        $ {rateDetails?.bill_rate?.taxable_hourly_income}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                    </div>
                    <div className={'d-flex mrg-bottom-20'}>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Total Hourly'}>
                                {rateDetails?.bill_rate?.total_hourly ?
                                    <>
                                        $ {rateDetails?.bill_rate?.total_hourly}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'W2 Rate-Hourly'}>
                                {rateDetails?.bill_rate?.w2_hourly_rate ?
                                    <>
                                        $ {rateDetails?.bill_rate?.w2_hourly_rate}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Taxable Weekly Income'}>
                                {rateDetails?.bill_rate?.taxable_weekly_income ?
                                    <>
                                        $ {rateDetails?.bill_rate?.taxable_weekly_income}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                    </div>
                    <div className={'d-flex mrg-bottom-20'}>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Tax Free Weekly Income'}>
                                {rateDetails?.bill_rate?.tax_free_weekly_income ?
                                    <>
                                        $ {rateDetails?.bill_rate?.tax_free_weekly_income}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'Gross Total Weekly'}>
                                {rateDetails?.bill_rate?.gross_total_weekly ?
                                    <>
                                        $ {rateDetails?.bill_rate?.gross_total_weekly}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                        <div className={'flex-equal'}>
                            <DataLabelValueComponent label={'OT Rate'}>
                                {rateDetails?.bill_rate?.ot_hourly_rate ?
                                    <>
                                        $ {rateDetails?.bill_rate?.ot_hourly_rate}
                                    </> : 'N/A'
                                }
                            </DataLabelValueComponent>
                        </div>
                    </div>
                </div>
            </div>
            <div className='contract-rate-right-card'>
                <div className={'gross-rate-card'}>
                    <div className={'title'}>Gross Rates</div>
                    <div>-</div>
                </div>
                <div className={'guaranteed-week-card'}>
                    <div className={'title'}>Guaranteed weeks</div>
                    <div>-</div>
                </div>
                <div className={'company-gross-card'}>
                    <div className={'title'}>Guaranteed weeks</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Hourly</div>
                        <div>-</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Weekly</div>
                        <div>-</div>
                    </div>
                </div>
                <div className={'candidate-gross-card'}>
                    <div className={'title'}>Candidate gross pay</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Hourly</div>
                        <div>-</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Weekly</div>
                        <div>-</div>
                    </div>
                </div>
                <div className={'border'}></div>
                <div className={'tax-amount-card'}>
                    <div className={'title'}>Taxable amount</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>W2 per hour rate</div>
                        <div>-</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Weekly</div>
                        <div>-</div>
                    </div>
                </div>
                <div className={'tax-amount-card'}>
                    <div className={'title'}>Non Taxable amount</div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Hourly</div>
                        <div>-</div>
                    </div>
                    <div className={'sub'}>
                        <div className={'sub-title'}>Weekly</div>
                        <div>-</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContractRateCardDetailsComponent;