import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent"
import {extensionTableColumnList} from "../extensionTableColumnList";

const ExtensionsComponent=()=>{
    return (
        <div className={'pipeline-table-wrapper'}>
            <AntTableComponent
                data={[]}
                columns={extensionTableColumnList || []}
                extraPayload={[]}
                noDataText={'No Extensions Data Found.'}
            />
        </div>
    )
}
export default ExtensionsComponent;