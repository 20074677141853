import "./ApplicantFilterDrawerComponent.scss";
import {makeStyles} from "@material-ui/core/styles";
import { ImageConfig} from "../../../../constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, {useCallback} from "react";

interface ApplicantFilterDrawerComponentProps{
    filterData:any;
    setFilterData:any;
    setOpenFilterDrawer:any;
    hcpTypes:any[];
    resetFilters:any;
}

const useStyles = makeStyles((theme) => ({
    option: {
        '&[aria-selected="true"]': {
            backgroundColor: '#41D6C3',
            color: 'white',
        },
    },
}));

const statusList=[
    {title: 'Pending', code: 'pending'},
    {title: 'Approved', code: 'approved'},
    {title:'Rejected',code:'rejected'},
]

const ApplicantFilterDrawerComponent=(props:ApplicantFilterDrawerComponentProps)=>{
    const {filterData,setFilterData,setOpenFilterDrawer,hcpTypes,resetFilters}=props;
    const classes=useStyles();
    // const [approvedHcps, setApprovedHcps] = useState<any>([]);
    // const [isStaffLoading,setIsStaffLoading]=useState<boolean>(false);

    // const getAllApprovedHcps = useCallback(() => {
    //     // setIsStaffLoading(true);
    //     let payload: any = {
    //         is_approved: true,
    //     };
    //     if (filterData?.salary_credit?.length > 0) {
    //         // payload.salary_credit = selectedSalaryCreditTypes.map((item: any) => item?.code);
    //         payload.salary_credit=filterData?.salary_credit;
    //     }
    //     CommonService._api
    //         .post(ENV.API_URL + "hcp/lite", payload)
    //         .then((resp) => {
    //             const approvedHcps = resp?.data?.map((item: any) => ({
    //                 name: `${item?.first_name} ${item?.last_name}`,
    //                 code: item?.user_id,
    //             }));
    //             setApprovedHcps(approvedHcps || []);
    //             setIsStaffLoading(false);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             setIsStaffLoading(false);
    //         });
    // },[filterData])
    //
    // useEffect(() => {
    //     getAllApprovedHcps()
    // }, [getAllApprovedHcps]);

    const formatFilterMenuList = useCallback((options: any[],filterData:any,key:string): any[] => {
        const selectedValues = filterData[key] || []; // Get the selected values for the key
        const allOptions = options?.map((option: any) => option?.code);

        let menuList = [...allOptions];

        if (selectedValues.length === allOptions.length) {
            // All options are selected, show "Clear All"
            menuList = ["Clear All", ...menuList];
        } else {
            // Not all options are selected, show "Select All"
            menuList = ["Select All", ...menuList];
        }

        return menuList;
    }, []);

    // const formatFilterMenuListToHandleObj = useCallback(
    //     (options: any[], filterData: any, key: string): any[] => {
    //         const allOptions = options.filter(
    //             (item) => item.code !== "Select All" && item.code !== "Clear All"
    //         );
    //
    //         const selectedValues = filterData[key] || []; // Get selected values for the key
    //         const allCodes = allOptions.map((item) => item.code);
    //
    //         let menuList = allOptions;
    //
    //         // Check if all options are selected
    //         if (selectedValues.length === allCodes.length) {
    //             menuList = [{ name: "Clear All", code: "Clear All" }, ...allOptions];
    //         } else {
    //             // If not all are selected, show "Select All"
    //             menuList = [{ name: "Select All", code: "Select All" }, ...allOptions];
    //         }
    //
    //         return menuList;
    //     },
    //     []
    // );


    const handleFilterValueSelect = useCallback(
        (
            newValues: any,
            options: any[],
            event: any,
            key: string
        ) => {

            // Extract the 'code' from each selected object in newValues
            const selectedValues = newValues.map((value: any) =>
                typeof value === "string" ? value : value.code
            );

            if (selectedValues.includes("Select All") || selectedValues.includes("Clear All")) {
                if (selectedValues.includes("Select All")) {
                    const allOptions = options.map((item: any) => item.code);

                    // Update filterData for Select All
                    setFilterData((prevData: any) => ({
                        ...prevData,
                        [key]: allOptions,
                    }));
                }

                if (selectedValues.includes("Clear All")) {

                    // Clear filterData for Clear All
                    setFilterData((prevData: any) => {
                        const { [key]: removedKey, ...rest } = prevData; // Remove the key from the object
                        return rest;
                    });
                }

                return;
            }

            // Dynamically add the key to filterData only when values are selected
            setFilterData((prevData: any) => {
                if (selectedValues.length === 0) {
                    const { [key]: removedKey, ...rest } = prevData;
                    return rest;
                }

                // Special case for "hcp_types"
                if (key === "hcp_types") {
                    const combinedTitleIndex = selectedValues.indexOf("CNA/CHHA");

                    // If "CNA/CHHA" is selected, split it into "CNA" and "CHHA"
                    if (combinedTitleIndex !== -1) {
                        const processedValues = [
                            ...selectedValues.filter((title: any) => title !== "CNA/CHHA"), // Exclude "CNA/CHHA"
                            "CNA", // Add "CNA"
                            "CHHA", // Add "CHHA"
                            "CNA/CHHA", // Keep "CNA/CHHA" as is
                        ];
                        return {
                            ...prevData,
                            [key]: processedValues,
                        };
                    }
                }

                // Default case for other keys
                return {
                    ...prevData,
                    [key]: selectedValues,
                };
            });
        },
        [setFilterData]
    );



    // const handleFilterValueSelectToHandleObj = useCallback(
    //     (
    //         newValues: any,
    //         options: any[],
    //         key: string, // The key to dynamically update in filterData
    //     ) => {
    //         const isSelectAll = newValues.some((f: any) => f.code === "Select All");
    //         const isClearAll = newValues.some((f: any) => f.code === "Clear All");
    //
    //         if (newValues.length === 0) {
    //             // Remove the key from filterData if no values are selected
    //             setFilterData((prevData: any) => {
    //                 const {[key]: removedKey, ...rest} = prevData;
    //                 return rest;
    //             });
    //         }
    //
    //         if (isSelectAll || isClearAll) {
    //             if (isSelectAll) {
    //                 const allOptions = options.filter((item) => {
    //                     return item.code !== "Select All" && item.code !== "Clear All";
    //                 });
    //
    //                 // Add all options to filterData under the specified key
    //                 setFilterData((prevData: any) => ({
    //                     ...prevData,
    //                     [key]: allOptions.map((item) => item.code), // Map to codes
    //                 }));
    //             }
    //
    //             if (isClearAll) {
    //
    //                 // Remove the key from filterData
    //                 setFilterData((prevData: any) => {
    //                     const {[key]: removedKey, ...rest} = prevData;
    //                     return rest;
    //                 });
    //             }
    //
    //             return;
    //         }
    //
    //         // For single select
    //
    //         // Dynamically update filterData with the selected values
    //         setFilterData((prevData: any) => {
    //             // If no new values, remove the key from filterData
    //             if (newValues.length === 0) {
    //                 const {[key]: removedKey, ...rest} = prevData;
    //                 return rest;
    //             }
    //
    //             // Otherwise, add or update the key in filterData
    //             return {
    //                 ...prevData,
    //                 [key]: newValues.map((item: any) => item.code), // Map to codes
    //             };
    //         });
    //     },
    //     [setFilterData]
    // );


    const selectedHcpCodes = filterData?.hcp_types || [];
    const updatedSelectedHcp = hcpTypes?.filter(({ code }) => selectedHcpCodes.includes(code));

    const selectedStatusCodes=filterData?.status || [];
    const updatedSelectedStatus=statusList?.filter(({code})=>selectedStatusCodes.includes(code));


    return (
        <div className={'facility-filter-component'}>
            <div className={'facility-filter-header-wrapper'}>
                <div className={'facility-filter-header'}>
                    <div className={'facility-filter-title'}>Filters</div>
                    <div className={'facility-filter-clear-all'}
                         onClick={() => {
                             resetFilters();
                             setOpenFilterDrawer(false);
                         }}
                    >Clear All</div>
                </div>
                <div
                    className={'facility-filter-close-btn'}
                    onClick={() => setOpenFilterDrawer(false)}
                >
                    <img src={ImageConfig.CrossIcon} alt={'cross-icon'}/>
                </div>
            </div>
            <div className={'facility-filter-fields-wrapper'}>
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_hcps"
                        value={updatedSelectedHcp?.map((staff: any) => staff.code)}
                        classes={{
                            option: classes.option,
                        }}
                        options={formatFilterMenuList(hcpTypes, filterData, "hcp_types")}
                        onChange={(e, newValue) =>
                            handleFilterValueSelect(
                                newValue,
                                hcpTypes,
                                e,
                                "hcp_types"
                            )
                        }
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                    {selectedCount} selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.hcp_types?.length === 0 || !filterData?.hcp_types
                                ? "Staff Type"
                                : "";
                            return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                        }}
                        disableCloseOnSelect
                    />
                </div>
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_status"
                        value={updatedSelectedStatus?.map((status: any) => status.code)}
                        classes={{
                            option: classes.option,
                        }}
                        options={formatFilterMenuList(statusList, filterData, "status")}
                        onChange={(e, newValue) =>
                            handleFilterValueSelect(
                                newValue,
                                hcpTypes,
                                e,
                                "status"
                            )
                        }
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                    {selectedCount} selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.status?.length === 0 || !filterData?.status
                                ? "Status"
                                : "";
                            return <TextField {...params} variant="outlined" placeholder={placeholder}/>;
                        }}
                        disableCloseOnSelect
                    />
                </div>
                {/*<div className="form-field-item">*/}
                {/*    <Autocomplete*/}
                {/*        disableClearable*/}
                {/*        disabled={isStaffLoading}*/}
                {/*        className="mrg-top-10"*/}
                {/*        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}*/}
                {/*        multiple*/}
                {/*        id="input_select_hcps"*/}
                {/*        getOptionLabel={(option) => option?.name}*/}
                {/*        getOptionSelected={(option, value) => option.name === value?.name}*/}
                {/*        // value={selectedApprovedHcps}*/}
                {/*        value={approvedHcps?.filter((item: any) => filterData?.hcp_user_ids?.includes(item?.code))}*/}
                {/*        options={formatFilterMenuListToHandleObj(approvedHcps, filterData, "hcp_user_ids")}*/}
                {/*        onChange={(e, newValue) => {*/}
                {/*            if (newValue) {*/}
                {/*                handleFilterValueSelectToHandleObj(*/}
                {/*                    newValue,*/}
                {/*                    approvedHcps,*/}
                {/*                    "hcp_user_ids"*/}
                {/*                );*/}
                {/*            }*/}
                {/*        }}*/}
                {/*        // renderTags={() => null}*/}
                {/*        classes={{*/}
                {/*            option: classes.option,*/}
                {/*        }}*/}
                {/*        renderTags={(value, getTagProps) => {*/}
                {/*            const selectedCount = value.length;*/}
                {/*            return selectedCount > 0 ? (*/}
                {/*                <span {...getTagProps({index: 0})}>*/}
                {/*                            {selectedCount} selected*/}
                {/*                        </span>*/}
                {/*            ) : null;*/}
                {/*        }}*/}
                {/*        renderInput={(params) => {*/}
                {/*            const placeholder = filterData?.hcp_user_ids?.length === 0 || !filterData?.hcp_user_ids*/}
                {/*                ? "Staff Name"*/}
                {/*                : "";*/}
                {/*            return <TextField {...params} variant="outlined" placeholder={placeholder}/>;*/}
                {/*        }}*/}
                {/*        disableCloseOnSelect*/}
                {/*    />*/}
                {/*    {isStaffLoading && (*/}
                {/*        <div className="loading-text-wrapper">*/}
                {/*            <span className="loading-text">loading...</span>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default ApplicantFilterDrawerComponent