// src/components/FormikInputComponent.tsx
import React from 'react';
import {useField} from 'formik';
import './FormikInputComponent.scss';
import InputComponent, {InputComponentProps} from '../input/InputComponent';

interface FormikInputComponentProps extends Omit<InputComponentProps, "onChange" | "value" | "hasError" | "errorMessage"> {
    onUpdate?: (event: any) => void;
    name: any;
}

const FormikInputComponent: React.FC<FormikInputComponentProps> = (props) => {
    const {
        name,
        onUpdate,
        ...restProps
    } = props
    // Formik hook to manage the field
    const [field, meta, fieldHelper] = useField(name);

    // Handle the onChange event for the AutoComplete dropdown
    const handleChange = (value: any) => {
        // Formik's setValue updates the value of the field
        if (onUpdate) {
            onUpdate(value)
        }

        fieldHelper.setValue(value);
        // Mark field as touched only when the user types
        if (!meta.touched) {
            fieldHelper.setTouched(true);
        }
        if (meta.error) {
            fieldHelper.setError(undefined);
        }
    };

    // Handle the onBlur event for the AutoComplete dropdown
    const handleBlur = (event: React.FocusEvent<any>) => {
        // Formik's onBlur should be triggered to mark the field as touched
        fieldHelper.setTouched(true);
        if (field.onBlur) {
            field.onBlur(event);
        }
    };

    // Handle errors, if any, from Formik
    const hasError = meta.touched && meta.error;

    return (
        <InputComponent
            className={'formik-input-component'} name={name}
            value={field.value}
            onChange={handleChange}
            errorMessage={meta.error}
            disabled={false}
            onBlur={handleBlur}
            hasError={Boolean(hasError)}
            {...restProps}
        />
    );
};

export default FormikInputComponent;

// USE CASE

// import {Formik, Form} from 'formik';
// import * as Yup from 'yup';
// import FormikInputComponent from '../../../components/custom-input-component/AntFormikInputComponentComponent';
// import AntButtonComponent from "../../../components/button-component/ButtonComponent.tsx";
//
// const validationSchema = Yup.object({
//     username: Yup.string().required('Username is required'),
//     password: Yup.string().required('Password is required'),
//     age: Yup.number().required('Age is required').min(1, 'Age must be at least 1'),
//     bio: Yup.string().optional(),
// });
//
// const InputForm = () => {
//     return (
//         <Formik
//             initialValues={{username: '', password: '', age: '', bio: ''}}
//             validationSchema={validationSchema}
//             onSubmit={(values) => {
//                 console.log(values);
//             }}
//         >
//             <Form>
//                 <FormikInputComponent name="username" label="Username" inputType="text"/>
//                 <FormikInputComponent name="password" label="Password" inputType="password"/>
//                 <FormikInputComponent name="age" label="Age" inputType="number"/>
//                 <FormikInputComponent name="bio" label="Bio" inputType="textarea"/>
//                 <AntButtonComponent gradient={true} htmlType="submit">
//                     Submit
//                 </AntButtonComponent>
//             </Form>
//         </Formik>
//     );
// };
//
// export default InputForm;
