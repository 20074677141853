import './TextEditorComponent.scss';
import ReactQuill from "react-quill";
import React from "react";
import 'react-quill/dist/quill.snow.css';


interface TextFormattingComponentProps {
    value: string;
    onChange: (value: string) => void; // Remove `?` to make it required
    placeholder?: string;
    readOnly?: boolean;
    toolbarOptions?: any;
    className?:any;
}

const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
];


const TextEditorComponent = (props: TextFormattingComponentProps) => {
    const {
        value,
        onChange = () => {}, // Default handler does nothing
        placeholder,
        readOnly,
        className,
    } = props;

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
    ];
    const modules = {
        toolbar: readOnly ? false : toolbarOptions,
    };

    return (
        <ReactQuill
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            modules={modules}
            formats={formats}
            className={className}
        />
    );
};


export default TextEditorComponent;
