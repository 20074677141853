import {Tooltip} from "@material-ui/core";
import moment from 'moment';
import React, {PropsWithChildren, useCallback, useState,useMemo} from 'react';
import { useParams} from 'react-router-dom';
import {ENV} from '../../../../../constants';
import {CommonService} from '../../../../../helpers';
import './UnApprovedHcpApplicationComponent.scss';
import Button from "@material-ui/core/Button";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";

export interface UnApprovedHcpApplicationComponentProps {
    isAddOpen: Boolean;
}

const UnApprovedHcpApplicationComponent = (props: PropsWithChildren<UnApprovedHcpApplicationComponentProps>) => {
    const isAddOpen = props?.isAddOpen;
    const param = useParams<any>();
    const {id} = param;
    const [refreshToken,setRefreshToken]=useState<number>(0);
    // const [isMovingToPendingLoading, setIsMovingToPendingLoading] = useState(new Array(list?.table.data.length).fill(false));



    const moveToPending = useCallback((facilityId: any,index:any) => {
        // const newLoadingStates = [...isMovingToPendingLoading];
        // newLoadingStates[index] = true;
        // setIsMovingToPendingLoading(newLoadingStates);

        CommonService._api.patch(ENV.API_URL + "application/" + facilityId + "/reapply").then((resp) => {
            CommonService.showToast("Success", "success");
            setRefreshToken((prev:number)=>prev+1);
        })
            .catch((err) => {
                console.log(err);
                CommonService.showToast(err || "Error", "error");
            });
    }, []);

    // useEffect(() => {
    //     init();
    // }, [init, isAddOpen]);

    const rejectedListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title: 'Staff Name',
                dataIndex: 'hcp_details',
                key: 'hcp_details',
                width:200,
                render: (hcp_details: any) => {
                    return (
                        <div>
                            {(hcp_details?.first_name + hcp_details?.last_name)?.length > 20 ?
                                <Tooltip title={hcp_details?.first_name + " " + hcp_details?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {hcp_details?.first_name + " " + hcp_details?.last_name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {hcp_details?.first_name + " " + hcp_details?.last_name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Applied On',
                dataIndex: 'created_at',
                key: 'created_at',
                width:120,
                render: (created_at: any) => {
                    return <span>{moment(created_at).format("MM-DD-YYYY") || 'N/A'}</span>
                }
            },
            {
                title: 'Staff Rate',
                dataIndex: 'hcp_details',
                key: 'hcp_details',
                width:120,
                render: (hcp_details: any) => {
                    return <span>{hcp_details?.contract_details?.rate_per_hour ? hcp_details?.contract_details?.rate_per_hour : "N/A"}</span>
                }
            },
            {
                title: 'Rejected By',
                dataIndex: 'rejected_by',
                key: 'rejected_by',
                width:200,
                render: (rejected_by: any) => {
                    return (
                        <div>
                            {(rejected_by?.first_name + rejected_by?.last_name)?.length > 20 ?
                                <Tooltip title={rejected_by?.first_name + " " + rejected_by?.last_name}>
                                    <div className={'ellipses-for-table-data'}>
                                        {rejected_by?.first_name + " " + rejected_by?.last_name || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {rejected_by?.first_name + " " + rejected_by?.last_name || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Reason',
                dataIndex: 'rejected_reason',
                key: 'rejected_reason',
                width:200,
                render: (item: any) => {
                    return (
                        <div>
                            {(item)?.length > 20 ?
                                <Tooltip title={item}>
                                    <div className={'ellipses-for-table-data'}>
                                        {item || 'N/A'}
                                    </div>
                                </Tooltip> :
                                <div>
                                    {item || 'N/A'}
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                width: 200,
                render: (text: any, record: any) => {
                    return <Button variant={"contained"} color={"primary"}
                                   onClick={() => {
                        moveToPending(record["_id"], record)
                    }}>
                        Move to pending
                        </Button>
                }
            }
        ]
    }, [moveToPending]);

    return <div className="unapproved-hcps-list">
            <AntTableComponent
                key={refreshToken}
                url={ENV.API_URL + 'requirement/' + id + '/application?status=rejected'}
                method={'get'}
                columns={rejectedListColumn}
                // data={list?.table?.data}
                extraPayload={isAddOpen}
                noDataText={'No Staff Rejected Found.'}
            />
    </div>;
};

export default UnApprovedHcpApplicationComponent;
