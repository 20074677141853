import "./FormikDatePickerComponent.scss";
import {useField} from "formik";
import {useCallback} from "react";
import DatePickerComponent, {DatePickerComponentProps} from "../datepicker-component/DatePickerComponent";

interface FormikDatePickerComponentProps extends Omit<DatePickerComponentProps, 'value' | 'onChange'> {
    name: string;
}

const FormikDatePickerComponent = (props: FormikDatePickerComponentProps) => {
    const {name, ...restProps} = props;

    const [field, meta, formikHelpers] = useField(name);

    const handleBlur = useCallback(() => {
        formikHelpers.setTouched(true, true);
    }, [formikHelpers]);

    const handleChange = useCallback((date: any, dateString: any) => {
        formikHelpers.setTouched(true);
        formikHelpers.setValue(dateString);
    }, [formikHelpers])

    // Handle errors, if any, from Formik
    const hasError = meta.touched && meta.error;

    return (
        <DatePickerComponent
            className={'formik-date-picker-component'}
            value={field.value}
            // onBlur={handleBlur}
            onSelectionClose={handleBlur}
            onChange={handleChange}
            errorMessage={meta.error}
            hasError={Boolean(hasError)}
            {...restProps}
        />
    );

};

export default FormikDatePickerComponent;

// Usage:
//<Field name="date"
//       component={FormikDatePickerComponent}
//       label="Date"
//       format="DD/MM/YYYY"
//       placeholder="Select a date"
//       disabledDate={(currentDate) => currentDate && currentDate < new Date()}
// />
