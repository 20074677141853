// import "./FormikSelectDropdownComponent.scss";
// import SelectDropdownComponent, {SelectDropdownComponentProps} from "../select/SelectComponent";
// import {useField} from "formik";
// import React from "react";
//
// interface FormikSelectDropdownComponentProps extends Omit<SelectDropdownComponentProps, 'onChange' | 'value'> {
//     onUpdate?: (value: any) => void;
//     name: string;
// }
//
// const FormikSelectDropdownComponent = (props: FormikSelectDropdownComponentProps) => {
//     const {name, onUpdate, ...restProps} = props;
//
//     // Formik hook to manage the field
//     const [field, meta, fieldHelper] = useField(name);
//
//     // Handle the onChange event for the AutoComplete dropdown
//     const handleChange = (value: any) => {
//         // Formik's setValue updates the value of the field
//         if (onUpdate) {
//             onUpdate(value)
//         }
//         fieldHelper.setValue(value);
//     };
//
//     // Handle the onBlur event for the AutoComplete dropdown
//     const handleBlur = () => {
//         // Formik's onBlur should be triggered to mark the field as touched
//         fieldHelper.setTouched(true, true);
//         // if (field.onBlur) {
//         //     field.onBlur(event);
//         // }
//     };
//
//     // Handle errors, if any, from Formik
//     const hasError = meta.touched && meta.error;
//     return <SelectDropdownComponent
//         className={'formik-select-dropdown-component'}
//         value={field.value}
//         onChange={handleChange}
//         errorMessage={meta.error}
//         onBlur={handleBlur}
//         hasError={Boolean(hasError)}
//         {...restProps}/>
//
// };
//
// export default FormikSelectDropdownComponent;


import "./FormikSelectDropdownComponent.scss";
import SelectDropdownComponent, { SelectDropdownComponentProps } from "../select/SelectComponent";
import { useField } from "formik";
import React, { useCallback } from "react";

interface FormikSelectDropdownComponentProps
    extends Omit<SelectDropdownComponentProps, "onChange" | "value"> {
    onUpdate?: (value: any) => void;
    name: string;
}

const FormikSelectDropdownComponent = (props: FormikSelectDropdownComponentProps) => {
    const { name, onUpdate, ...restProps } = props;

    // Formik hook to manage the field
    const [field, meta, helpers] = useField(name);

    // Handle the onChange event for the Select dropdown
    const handleChange = (value: any) => {
        if (onUpdate) {
            onUpdate(value);
        }
        helpers.setValue(value); // Set the field-specific value
    };

    // Handle the onBlur event for the Select dropdown
    const handleBlur = useCallback(() => {
        helpers.setTouched(true); // Mark only this field as touched
    }, [helpers]);

    // Handle errors, if any, from Formik
    const hasError = meta.touched && meta.error;

    return (
        <SelectDropdownComponent
            className="formik-select-dropdown-component"
            value={field.value}
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={meta.error}
            hasError={Boolean(hasError)}
            {...restProps}
        />
    );
};

export default FormikSelectDropdownComponent;
