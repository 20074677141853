import React from "react";
import "./JobRateComponent.scss";
import PermanentRateCardComponent from "./permanent-rate-card/PermanentRateCardComponent";
import PerDiemRateCardComponent from "./per-diem-rate-card/PerDiemRateCardComponent";
import ContractRateCardComponent from "./contract-rate-card/ContractRateCardComponent";


interface JobRateTableProps {
    apiDataAfterAdd?: any
    rate_details?:any;
}


const JobRateTable = (props: JobRateTableProps) => {
    const {apiDataAfterAdd,rate_details} = props;

    return (
        <div>
            {apiDataAfterAdd?.job_type === 'per_diem' &&
                <PerDiemRateCardComponent apiDataAfterAdd={apiDataAfterAdd} rate_details={rate_details}/>
            }
            {apiDataAfterAdd?.job_type === 'permanent' &&
                <PermanentRateCardComponent apiDataAfterAdd={apiDataAfterAdd} rate_details={rate_details}/>
            }
            {apiDataAfterAdd?.job_type === 'contract' &&
                <ContractRateCardComponent apiDataAfterAdd={apiDataAfterAdd} rate_details={rate_details}/>
            }
        </div>
    );
};

export default JobRateTable;