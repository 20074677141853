// import CheckIcon from "@material-ui/icons/Check";
import {  Tooltip } from "@material-ui/core";
// import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import React, {PropsWithChildren, useMemo, useState} from "react";
import { useParams } from "react-router-dom";
// import DialogComponent from "../../../../../components/DialogComponent";
// import LoaderComponent from "../../../../../components/LoaderComponent";
// import NoDataCardComponent from "../../../../../components/NoDataCardComponent";
import { ENV } from "../../../../../constants";
// import { ApiService } from "../../../../../helpers";
// import RejectHcpApplicationComponent from "../../../pending/rejectHcp/RejectHcpApplicationComponent";
import "./PendingHcpApplicationComponent.scss";
import {TableColumnsType} from "antd";
import AntTableComponent from "../../../../../components/ant-table-component/AntTableComponent";

export interface PendingHcpApplicationComponentProps {
  isAddOpen: Boolean;
  status: string;
  tabValue?:string;
}

const PendingHcpApplicationComponent = (props: PropsWithChildren<PendingHcpApplicationComponentProps>) => {
  // const status = props?.status;
  const isAddOpen = props?.isAddOpen;
  const currentValue=props?.tabValue;
  const param = useParams<any>();
  const { id } = param;
  // const [isRejectOpen, setIsRejectOpen] = useState<boolean>(false);
  // const [applicationId, setApplicationId] = useState<string>("");

  // eslint-disable-next-line
  const [hcpId, setHcpId] = useState<string>("");


  // const openRejectApplication = useCallback((applicationId: any) => {
  //   setApplicationId(applicationId);
  //   setIsRejectOpen(true);
  // }, []);

  // const cancelRejectApplication = useCallback(() => {
  //   setIsRejectOpen(false);
  // }, []);
  //
  // const confirmRejectApplication = useCallback(() => {
  //   setIsRejectOpen(false);
  //   setRefreshToken((prev:any)=>prev+1);
  // }, []);

  const pendingListColumn:TableColumnsType=useMemo(()=>{
    return [
        {
            title: 'Staff Name',
            key: 'hcp_details',
            dataIndex: 'hcp_details',
            width:200,
            render: (hcp_details: any) => {
              return(
                  <div>
                    {(hcp_details?.first_name + hcp_details?.last_name)?.length > 20 ?
                      <Tooltip title={hcp_details?.first_name + " " + hcp_details?.last_name}>
                        <div className={'ellipses-for-table-data'}>
                          {hcp_details?.first_name + " " + hcp_details?.last_name || 'N/A'}
                        </div>
                      </Tooltip>:
                        <div>
                          {hcp_details?.first_name + " " + hcp_details?.last_name || 'N/A'}
                        </div>
                    }
                  </div>
              );
            }
        },
        {
            title: 'Email',
            key: 'hcp_details',
            dataIndex: 'hcp_details',
            width:200,
            render: (item: any) => {
              return (
                  <div className={'text-lowercase'}>
                    {(item?.email)?.length > 20 ?
                        <Tooltip title={item?.email}>
                          <div className={'ellipses-for-table-data'}>
                            {item?.email || 'N/A'}
                          </div>
                        </Tooltip> :
                        <div>
                          {item?.email || 'N/A'}
                        </div>
                    }
                  </div>
              )
            }
        },
      {
        title: 'Applied On',
        width:120,
        render:
            (created_at: any) => {
              return moment(created_at).format("MM-DD-YYYY") || 'N/A';
            }
      }
      ,
      {
        title: 'Staff Type',
        key: 'hcp_details',
        dataIndex: 'hcp_details',
        width:200,
        render:(item:any)=>{
          return (
              <div>
                {(item?.hcp_type)?.length > 20 ?
                    <Tooltip title={item?.hcp_type}>
                      <div className={'ellipses-for-table-data'}>
                        {item?.hcp_type || 'N/A'}
                      </div>
                    </Tooltip> :
                    <div>
                      {item?.hcp_type || 'N/A'}
                    </div>
                }
              </div>
          )
        }
      },
      {
        title: 'Staff Rate',
        key: 'hcp_details',
        dataIndex: 'hcp_details',
        width:100,
        render:
            (hcp_details: any) => {
              return hcp_details?.contract_details?.rate_per_hour || 'N/A';
            }
      },
      // {
      //   title: 'Actions',
      //   width:100,
      //   render: (actions: any, record: any) => {
      //     return (
      //         <div>
      //           <Tooltip title={`Reject ${record["hcp_details"]?.first_name} ${record["hcp_details"]?.last_name} Application`}>
      //               <IconButton onClick={() => openRejectApplication(record["_id"])} disabled={status === "cancelled"}>
      //               <CancelIcon className="delete-icon" />
      //               </IconButton>
      //           </Tooltip>
      //           </div>
      //       );
      //       }
      //   }
    ]
  },[])

  return (
    <div className="pending-shift-applications-hcps-list">
      {/*<DialogComponent open={isRejectOpen} cancel={cancelRejectApplication} headerLabel={'Rejection Request'}>*/}
      {/*  <RejectHcpApplicationComponent cancel={cancelRejectApplication} confirm={confirmRejectApplication} requirementId={id} applicationId={applicationId} />*/}
      {/*</DialogComponent>*/}
      <div>
        <AntTableComponent
            url={ENV.API_URL + "requirement/" + id + `/application?status=${currentValue}`}
            method={'get'}
            // data={list?.table?.data}
            extraPayload={isAddOpen}
            columns={pendingListColumn}
            noDataText={currentValue === 'pending' ? 'No Pending Staff Found.' :' No Awaiting Staff Found.'}
        />
      </div>
    </div>
  );
};

export default PendingHcpApplicationComponent;
