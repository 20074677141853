import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {DateRangeOutlined} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import {ENV, ImageConfig} from "../../../constants";
import {
    AllShiftStatusList,
    facilityConfirmation,
    OpenShiftsStatusList,
    pendingShiftsStatusList,
    salaryCreditTypesForFilters,
    timesheet,
    timeTypesForFilters,
    pendingConcern, HcpPaymentMode, requestedByMenu, requirementTypeList, awaitingShiftsStatusList
} from "../../../constants/data";
import {CommonService, localStore} from "../../../helpers";
import "./ShiftFilterDrawerComponent.scss";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import {makeStyles} from '@material-ui/core/styles';
import moment from "moment/moment";

const useStyles = makeStyles((theme) => ({
    option: {
        '&[aria-selected="true"]': {
            backgroundColor: '#41D6C3',
            color: 'white',
        },
    },
}));

export interface ShiftFilterDrawerProps {
    filterData?: any;
    setFilterData?: any;
    hcpTypes: any;
    facilityList: any;
    setOpenFilterDrawer?: any;
    noStatus?: boolean;
    //type of shifts
    isMaster?: boolean;
    isCompleted?: boolean;
    isOpenShift?: boolean;
    isInProgress?: boolean;
    isClosed?: boolean;
    isPending?: boolean;
    isCancelled?: boolean;
    isApproved?: boolean;
    resetFilters: any;
    isFacilityListLoading: boolean;
    resetSelectedCount?: () => void;
    isAwaiting?: boolean;
}

const ShiftFilterDrawerComponent = (props: PropsWithChildren<ShiftFilterDrawerProps>) => {
    const [approvedHcps, setApprovedHcps] = useState([]);
    const [isApprovedHcpsLoading, setisApprovedHcpsLoading] = useState(false);
    //states for select all
    const statusList = props?.isMaster ? AllShiftStatusList : props.isPending ? (props?.isAwaiting ? awaitingShiftsStatusList : pendingShiftsStatusList) : OpenShiftsStatusList;
    //shift status types
    const classes = useStyles();
    const filterData = props.filterData;
    const setFilterData = props.setFilterData;
    const isMaster = props?.isMaster;
    const isCompleted = props?.isCompleted;
    const isClosed = props?.isClosed;
    const isPending = props?.isPending;
    const isCancelled = props?.isCancelled;
    const isApproved = props?.isApproved;
    const isInProgress = props?.isInProgress;
    const isOpenShift = props?.isOpenShift;
    const setOpenFilterDrawer = props?.setOpenFilterDrawer;
    const resetFilters = props?.resetFilters;



    //show filter fields
    const SHOW_SALARY_CREDIT_FIELD_FOR_SHIFT_STATUS_TYPES = isCompleted || isClosed || isMaster || isCancelled || isApproved || isInProgress;
    const SHOW_IS_HCP_SENT_HOME_FIELD_FOR_SHIFT_STATUS_TYPES = isCompleted || isClosed || isMaster || isCancelled;

    const facilityList: any[] = props?.facilityList
        ? props?.facilityList.map((item: any) => ({code: item?._id, name: item?.name}))
        : [];

    const hcpTypes: any[] = props?.hcpTypes ? props?.hcpTypes : [];


    const isFacilityListLoading = props?.isFacilityListLoading;


    const formatFilterMenuList = useCallback((options: any[],filterData:any,key:string): any[] => {
        // let menuList = options?.map((option: any) => option?.code);
        //
        // if (isAllMenuItemSelected) {
        //     menuList = ["Clear All", ...menuList];
        // }
        // if (!isAllMenuItemSelected) {
        //     menuList = ["Select All", ...menuList];
        // }
        // return menuList;
        const selectedValues = filterData[key] || []; // Get the selected values for the key
        const allOptions = options?.map((option: any) => option?.code);

        let menuList = [...allOptions];

        if (selectedValues.length === allOptions.length) {
            // All options are selected, show "Clear All"
            menuList = ["Clear All", ...menuList];
        } else {
            // Not all options are selected, show "Select All"
            menuList = ["Select All", ...menuList];
        }

        return menuList;
    }, []);

    const formatFilterMenuListToHandleObj = useCallback(
        (options: any[], filterData: any, key: string): any[] => {
            const allOptions = options.filter(
                (item) => item.code !== "Select All" && item.code !== "Clear All"
            );

            const selectedValues = filterData[key] || []; // Get selected values for the key
            const allCodes = allOptions.map((item) => item.code);

            let menuList = allOptions;

            // Check if all options are selected
            if (selectedValues.length === allCodes.length) {
                menuList = [{ name: "Clear All", code: "Clear All" }, ...allOptions];
            } else {
                // If not all are selected, show "Select All"
                menuList = [{ name: "Select All", code: "Select All" }, ...allOptions];
            }

            return menuList;
        },
        []
    );


    const handleFilterValueSelect = useCallback(
        (
            newValues: any,
            options: any[],
            event: any,
            key: string
        ) => {

            // Extract the 'code' from each selected object in newValues
            const selectedValues = newValues.map((value: any) =>
                typeof value === "string" ? value : value.code
            );

            if (selectedValues.includes("Select All") || selectedValues.includes("Clear All")) {
                if (selectedValues.includes("Select All")) {
                    const allOptions = options.map((item: any) => item.code);

                    // Update filterData for Select All
                    setFilterData((prevData: any) => ({
                        ...prevData,
                        [key]: allOptions,
                    }));
                }

                if (selectedValues.includes("Clear All")) {

                    // Clear filterData for Clear All
                    setFilterData((prevData: any) => {
                        const { [key]: removedKey, ...rest } = prevData; // Remove the key from the object
                        return rest;
                    });
                }

                return;
            }

            // Dynamically add the key to filterData only when values are selected
            setFilterData((prevData: any) => {
                if (selectedValues.length === 0) {
                    const { [key]: removedKey, ...rest } = prevData;
                    return rest;
                }

                // Special case for "hcp_types"
                if (key === "hcp_types") {
                    const combinedTitleIndex = selectedValues.indexOf("CNA/CHHA");

                    // If "CNA/CHHA" is selected, split it into "CNA" and "CHHA"
                    if (combinedTitleIndex !== -1) {
                        const processedValues = [
                            ...selectedValues.filter((title: any) => title !== "CNA/CHHA"), // Exclude "CNA/CHHA"
                            "CNA", // Add "CNA"
                            "CHHA", // Add "CHHA"
                            "CNA/CHHA", // Keep "CNA/CHHA" as is
                        ];
                        return {
                            ...prevData,
                            [key]: processedValues,
                        };
                    }
                }

                // Default case for other keys
                return {
                    ...prevData,
                    [key]: selectedValues,
                };
            });
        },
        [setFilterData]
    );



    const handleFilterValueSelectToHandleObj = useCallback(
        (
            newValues: any,
            options: any[],
            key: string, // The key to dynamically update in filterData
        ) => {
            const isSelectAll = newValues.some((f: any) => f.code === "Select All");
            const isClearAll = newValues.some((f: any) => f.code === "Clear All");

            if (newValues.length === 0) {
                // Remove the key from filterData if no values are selected
                setFilterData((prevData: any) => {
                    const {[key]: removedKey, ...rest} = prevData;
                    return rest;
                });
            }

            if (isSelectAll || isClearAll) {
                if (isSelectAll) {
                    const allOptions = options.filter((item) => {
                        return item.code !== "Select All" && item.code !== "Clear All";
                    });

                    // Add all options to filterData under the specified key
                    setFilterData((prevData: any) => ({
                        ...prevData,
                        [key]: allOptions.map((item) => item.code), // Map to codes
                    }));
                }

                if (isClearAll) {

                    // Remove the key from filterData
                    setFilterData((prevData: any) => {
                        const {[key]: removedKey, ...rest} = prevData;
                        return rest;
                    });
                }

                return;
            }

            // For single select

            // Dynamically update filterData with the selected values
            setFilterData((prevData: any) => {
                // If no new values, remove the key from filterData
                if (newValues.length === 0) {
                    const {[key]: removedKey, ...rest} = prevData;
                    return rest;
                }

                // Otherwise, add or update the key in filterData
                return {
                    ...prevData,
                    [key]: newValues.map((item: any) => item.code), // Map to codes
                };
            });
        },
        [setFilterData]
    );

    const getAllApprovedHcps = useCallback(() => {
        setisApprovedHcpsLoading(true);
        let payload: any = {
            is_approved: true,
        };
        if (filterData?.salary_credit?.length > 0) {
            // payload.salary_credit = selectedSalaryCreditTypes.map((item: any) => item?.code);
            payload.salary_credit=filterData?.salary_credit;
        }
        CommonService._api
            .post(ENV.API_URL + "hcp/lite", payload)
            .then((resp) => {
                const approvedHcps = resp?.data?.map((item: any) => ({
                    name: `${item?.first_name} ${item?.last_name}`,
                    code: item?.user_id,
                }));
                setApprovedHcps(approvedHcps || []);
                setisApprovedHcpsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setisApprovedHcpsLoading(false);
            });
    },[filterData])

    useEffect(() => {
        getAllApprovedHcps();
        // eslint-disable-next-line
    }, [filterData?.salary_credit]);


    const handleIsFacilityPayment = useCallback((value: any) => {
        // setIsFacilityPayment(value);
        let updatedFilterData = {...filterData}
        if(value?.value === ''){
            delete updatedFilterData.is_billed
        }else {
            updatedFilterData.is_billed = value?.value;
        }
        setFilterData(updatedFilterData);
    }, [filterData, setFilterData])

    const handleRequestedBy = useCallback((selectedOption: any) => {
        // setRequestedBy(selectedOption);
        let updatedFilterData = {...filterData}
        if (selectedOption?.value === '') {
            // Remove the `requested_by` key when "All" is selected
            delete updatedFilterData.requested_by;
            setFilterData(updatedFilterData);
        } else {
            // Update the `requested_by` key for other options
            updatedFilterData.requested_by = selectedOption.value;
            setFilterData(updatedFilterData);
        }
    }, [setFilterData, filterData])

    const handleTimeSheetUploaded = useCallback((selectedOption: any) => {
        // setIsTimeSheetUploaded(selectedOption);
        let updatedFilterData = {...filterData};
        if (selectedOption.value === '') {
            delete updatedFilterData.is_timesheet_uploaded
            setFilterData(updatedFilterData);
        } else {
            updatedFilterData.is_timesheet_uploaded = selectedOption.value;
            setFilterData(updatedFilterData);
        }
    }, [filterData, setFilterData])

    const handleIsFacilityApproved = useCallback((selectedOption: any) => {
        // setIsFacilityApproved(selectedOption);
        let updatedFilterData = {...filterData};
        if (selectedOption.value === '') {
            delete updatedFilterData.is_facility_approved
            setFilterData(updatedFilterData);
        } else {
            updatedFilterData.is_facility_approved = selectedOption.value;
            setFilterData(updatedFilterData);
        }
    }, [filterData, setFilterData])

    const handleShowShiftRate = useCallback((selectedOption: any) => {
        // setIsShowShiftRate(selectedOption);
        let updatedFilterData = {...filterData};
        if (selectedOption.value === '') {
            delete updatedFilterData.is_shift_rate
            setFilterData(updatedFilterData);
        } else {
            updatedFilterData.is_shift_rate = selectedOption.value;
            setFilterData(updatedFilterData);
        }
    }, [filterData, setFilterData])

    const handleIsPendingConcern = useCallback((selectedOption: any) => {
        // setIsPendingConcern(selectedOption);
        let updatedFilterData = {...filterData};
        if (selectedOption.value === '') {
            delete updatedFilterData.is_pending_concern
            setFilterData(updatedFilterData);
        } else {
            updatedFilterData.is_pending_concern = selectedOption.value;
            setFilterData(updatedFilterData);
        }
    }, [filterData, setFilterData])

    const handleHcpPayment = useCallback((selectedOption: any) => {
        // setHcpPaymentValue(selectedOption);
        let updatedFilterData = {...filterData};
        if (selectedOption.value === '') {
            delete updatedFilterData.gusto_type
            setFilterData(updatedFilterData);
        } else {
            updatedFilterData.gusto_type = selectedOption.value;
            setFilterData(updatedFilterData);
        }
    }, [filterData, setFilterData])

    const handleIsHcpSentHome = useCallback((selectedOption: any) => {
        // setIsHcpSentHome(selectedOption);
        let updatedFilterData = {...filterData};
        if (selectedOption.value === '') {
            delete updatedFilterData.is_sent_home
            setFilterData(updatedFilterData);
        } else {
            updatedFilterData.is_sent_home = selectedOption.value;
            setFilterData(updatedFilterData);
        }
    }, [filterData, setFilterData])

    const handleIsHcpPayment = useCallback((selectedOption: any) => {
        // setIsHcpPayment(selectedOption);
        let updatedFilterData = {...filterData};
        if (selectedOption.value === '') {
            delete updatedFilterData.is_hcp_paid
            setFilterData(updatedFilterData);
        } else {
            updatedFilterData.is_hcp_paid = selectedOption.value;
            setFilterData(updatedFilterData);
        }
    }, [filterData, setFilterData])

    const handleHcpPaymentModeForClosedShifts = useCallback((selectedOption: any) => {
        // setHcpPaymentModeValueForClosedShifts(selectedOption);
        let updatedFilterData = {...filterData};
        if (selectedOption.value === '') {
            delete updatedFilterData.gusto_type
            setFilterData(updatedFilterData);
        } else {
            updatedFilterData.gusto_type = selectedOption.value;
            setFilterData(updatedFilterData);
        }
    }, [filterData, setFilterData])

    const handleRequirementType = useCallback((selectedOption: any) => {
        // setRequirementType(selectedOption);
        let updatedFilterData = {...filterData};
        if (selectedOption.value === '') {
            delete updatedFilterData.type
            setFilterData(updatedFilterData);
        } else {
            updatedFilterData.type = selectedOption.value;
            setFilterData(updatedFilterData);
        }
    }, [filterData, setFilterData])

    const handleIsPendingConcernForClosedShift = useCallback((selectedOption: any) => {
        // setIsPendingConcernForClosedShift(selectedOption);
        let updatedFilterData = {...filterData};
        if (selectedOption.value === '') {
            delete updatedFilterData.is_pending_concern
            setFilterData(updatedFilterData);
        } else {
            updatedFilterData.is_pending_concern = selectedOption.value;
            setFilterData(updatedFilterData);
        }
    }, [filterData, setFilterData])

    const handleDateRangeSelection = useCallback((dateRange: [Date | null, Date | null]) => {
        const [newStartDate, newEndDate] = dateRange || [];

        const startDateFormatted = newStartDate
            ? moment(newStartDate).format("YYYY-MM-DD")
            : null;
        const endDateFormatted = newEndDate
            ? moment(newEndDate || newStartDate).format("YYYY-MM-DD")
            : null;

        const updatedFilterData={...filterData}


        if (startDateFormatted) {
            updatedFilterData.start_date = startDateFormatted;
            // Clear the end_date if the user picks a new start_date
            if (filterData.start_date !== startDateFormatted) {
                delete updatedFilterData.end_date;
                delete updatedFilterData.new_shifts;
            }
        }

        if(endDateFormatted){
            updatedFilterData.end_date=endDateFormatted;
        }


        if (!startDateFormatted && !endDateFormatted) {
            delete updatedFilterData.start_date;
            delete updatedFilterData.end_date;
            updatedFilterData.new_shifts=CommonService.changeToUtcDate(new Date())
        }


        setFilterData(updatedFilterData);
    }, [filterData, setFilterData]);

    const handlePaymentDateRangeSelection = useCallback((dateRange: [Date | null, Date | null]) => {
        const [newStartDate, newEndDate] = dateRange || [];

        const startDateFormatted = newStartDate
            ? moment(newStartDate).format("YYYY-MM-DD")
            : null;
        const endDateFormatted = newEndDate
            ? moment(newEndDate || newStartDate).format("YYYY-MM-DD")
            : null;

        const updatedFilterData = {
            ...filterData,
        };

        if (startDateFormatted) {
            updatedFilterData.hcp_pay_start_date = startDateFormatted;
            // Clear the end_date if the user picks a new start_date
            if (filterData.hcp_pay_start_date !== startDateFormatted) {
                delete updatedFilterData.hcp_pay_end_date;
            }
        }

        if(endDateFormatted){
            updatedFilterData.hcp_pay_end_date=endDateFormatted;
        }


        if (!startDateFormatted && !endDateFormatted) {
            delete updatedFilterData.hcp_pay_start_date;
            delete updatedFilterData.hcp_pay_end_date;
        }

        setFilterData(updatedFilterData);
    }, [filterData, setFilterData]);

    const selectedHcpCodes = filterData?.hcp_types || [];
    const updatedSelectedHcp = hcpTypes?.filter(({ code }) => selectedHcpCodes.includes(code));

    const selectedShiftCodes=filterData?.shift_types || [];
    const updatedSelectedShifts=timeTypesForFilters?.filter(({code})=>selectedShiftCodes.includes(code));

    const selectedStatusCodes=filterData?.status || [];
    const updatedSelectedStatus=statusList?.filter(({code})=>selectedStatusCodes.includes(code));

    const selectedPendingStatusCodes=filterData?.status || [];
    const updatedSelectedPendingStatus=statusList?.filter(({code})=>selectedPendingStatusCodes.includes(code));

    const selectedMasterStatusCodes=filterData?.status || [];
    const updatedSelectedMasterStatus=statusList?.filter(({code})=>selectedMasterStatusCodes.includes(code));


    return (
        <div className={'facility-filter-component'}>
            <div className={'facility-filter-header-wrapper'}>
                <div className={'facility-filter-header'}>
                    <div className={'facility-filter-title'}>Filters</div>
                    <div
                        className={`facility-filter-clear-all`}
                        onClick={() => {
                            resetFilters();
                            setOpenFilterDrawer(false);
                        }}
                    >Clear All
                    </div>
                </div>
                <div
                    className={'facility-filter-close-btn'}
                    onClick={() => setOpenFilterDrawer(false)}
                >
                    <img src={ImageConfig.CrossIcon} alt={'cross-icon'}/>
                </div>
            </div>
            <div className="facility-filter-fields-wrapper">
                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_hcps"
                        // value={selectedHcps}
                        value={updatedSelectedHcp?.map((hcp:any) => hcp.code)}
                        classes={{
                            option: classes.option,
                        }}
                        options={formatFilterMenuList(hcpTypes, filterData, "hcp_types")}
                        onChange={(e, newValue) =>
                            handleFilterValueSelect(
                                newValue,
                                hcpTypes,
                                e,
                                "hcp_types"
                            )
                        }
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                    {selectedCount} selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.hcp_types?.length === 0 || !filterData?.hcp_types
                                ? "Staff Types"
                                : "";
                            return <TextField {...params}  variant="outlined" placeholder={placeholder} />;
                        }}
                        disableCloseOnSelect
                    />
                </div>

                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        id="input_select_time_types"
                        classes={{
                            option: classes.option,
                        }}
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                    {selectedCount} selected
                                </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.shift_types?.length === 0 || !filterData?.shift_types
                                ? "Shift Types"
                                : "";
                            return <TextField {...params}  variant="outlined" placeholder={placeholder} />;
                        }}
                        disableCloseOnSelect
                        // value={selectedTimeTypes}
                        value={updatedSelectedShifts?.map((shift:any) => shift.code)}
                        options={formatFilterMenuList(timeTypesForFilters, filterData,'shift_types')}
                        onChange={(e, newValue) =>
                            handleFilterValueSelect(
                                newValue,
                                timeTypesForFilters,
                                e,
                                "shift_types",
                            )
                        }
                    />
                </div>
                {isOpenShift && (
                    <>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                className="mrg-top-10 "
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                multiple
                                id="input_select_status"
                                classes={{
                                    option: classes.option,
                                }}
                                // value={selectedStatusTypes}
                                value={updatedSelectedStatus?.map((status:any) => status.code)}
                                options={formatFilterMenuList(statusList,filterData,'status')}
                                onChange={(e, newValue) =>
                                    handleFilterValueSelect(newValue,
                                        statusList,
                                        e,
                                        "status",
                                    )
                                }
                                // renderTags={() => null}
                                renderTags={(value, getTagProps) => {
                                    const selectedCount = value.length;
                                    return selectedCount > 0 ? (
                                        <span {...getTagProps({index: 0})}>
                                            {selectedCount} selected
                                        </span>
                                    ) : null;
                                }}
                                renderInput={(params) => {
                                    const placeholder = filterData?.status?.length === 0 || !filterData?.status
                                        ? "Status"
                                        : "";
                                    return <TextField {...params}  variant="outlined" placeholder={placeholder} />;
                                }}
                                disableCloseOnSelect
                            />
                        </div>

                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper
                                    style={{color: "#1e1e1e"}}>{children}</Paper>}
                                // value={requestedBy}
                                value={requestedByMenu?.find((item:any)=> item?.value === filterData?.requested_by) || undefined}
                                options={requestedByMenu}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Time Sheet"}
                                id="input_select_status"
                                className="mrg-top-10"
                                classes={{
                                    option: classes.option,
                                }}
                                onChange={($event, value) => {
                                    handleRequestedBy(value);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} id="select_requested_by" variant="outlined"
                                               placeholder={"Requested By"}/>
                                )}
                                disableCloseOnSelect
                            />
                        </div>
                    </>

                )}


                {isPending && (
                    <div className="form-field-item">
                        <Autocomplete
                            disableClearable
                            className="mrg-top-10 "
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            multiple
                            id="input_select_status"
                            // value={selectedStatusTypes}
                            value={updatedSelectedPendingStatus?.map((status:any) => status.code)}
                            classes={{
                                option: classes.option,
                            }}
                            options={formatFilterMenuList(statusList,filterData,'status')}
                            onChange={(e, newValue) =>
                                handleFilterValueSelect(
                                    newValue,
                                    statusList,
                                    e,
                                    "status"
                                )
                            }
                            // renderTags={() => null}
                            renderTags={(value, getTagProps) => {
                                const selectedCount = value.length;
                                return selectedCount > 0 ? (
                                    <span {...getTagProps({index: 0})}>
                                            {selectedCount} selected
                                        </span>
                                ) : null;
                            }}
                            renderInput={(params) => {
                                const placeholder = filterData?.status?.length === 0 || !filterData?.status
                                    ? "Status"
                                    : "";
                                return <TextField {...params}  variant="outlined" placeholder={placeholder} />;
                            }}
                            disableCloseOnSelect
                        />
                    </div>
                )}

                {isMaster && (
                    <>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                className="mrg-top-10"
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                multiple
                                id="input_select_status"
                                // value={selectedStatusTypes}
                                // value={statusList?.filter((item:any)=>filterData?.status?.includes(item?.code))}
                                value={updatedSelectedMasterStatus?.map((status:any) => status.code)}
                                // getOptionLabel={(option) => option?.name}
                                // getOptionSelected={(option, value) => option.name === value?.name}
                                options={formatFilterMenuList(statusList, filterData,"status")}
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        handleFilterValueSelect(
                                            newValue,
                                            statusList,
                                            e,
                                            "status"
                                        );
                                    }
                                }}
                                // renderTags={() => null}
                                classes={{
                                    option: classes.option,
                                }}
                                renderTags={(value, getTagProps) => {
                                    const selectedCount = value.length;
                                    return selectedCount > 0 ? (
                                        <span {...getTagProps({index: 0})}>
                                            {selectedCount} selected
                                        </span>
                                    ) : null;
                                }}
                                renderInput={(params) => {
                                    const placeholder = filterData?.status?.length === 0 || !filterData?.status
                                        ? "Status"
                                        : "";
                                    return <TextField {...params}  variant="outlined" placeholder={placeholder} />;
                                }}
                                disableCloseOnSelect
                            />
                        </div>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                // value={isFacilityPayment}
                                value={timesheet?.find((item:any) => item.value === filterData.is_billed)}
                                options={timesheet}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Facility Billed"}
                                id="input_select_fac_payment_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    handleIsFacilityPayment(value);
                                }}
                                classes={{
                                    option: classes.option,
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="select_fac_payment_status"
                                        variant="outlined"
                                        placeholder={"Facility Billed"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>
                        <>
                            <div className="form-field-item">
                                <Autocomplete
                                    disableClearable
                                    PaperComponent={({children}) => <Paper
                                        style={{color: "#1e1e1e"}}>{children}</Paper>}
                                    // value={isTimeSheetUploaded}
                                    value={timesheet?.find((item:any) => item.value === filterData.is_timesheet_uploaded)}
                                    options={timesheet}
                                    getOptionLabel={(option: any) => option.label}
                                    placeholder={"Time Sheet"}
                                    id="input_select_status"
                                    className="mrg-top-10"
                                    onChange={($event, value) => {
                                        handleTimeSheetUploaded(value);
                                    }}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} id="select_status" variant="outlined"
                                                   placeholder={"Time Sheet"}/>
                                    )}
                                    disableCloseOnSelect
                                />
                            </div>

                            <div className="form-field-item">
                                <Autocomplete
                                    disableClearable
                                    PaperComponent={({children}) => <Paper
                                        style={{color: "#1e1e1e"}}>{children}</Paper>}
                                    // value={isFacilityApproved}
                                    value={facilityConfirmation?.find((item:any) => item.value === filterData.is_facility_approved)}
                                    options={facilityConfirmation}
                                    getOptionLabel={(option: any) => option.label}
                                    placeholder={"Facility Confirmation"}
                                    id="input_select_status"
                                    className="mrg-top-10"
                                    onChange={($event, value) => {
                                        handleIsFacilityApproved(value);
                                    }}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="select_fac_confirm"
                                            variant="outlined"
                                            placeholder={"Facility Confirmation"}
                                        />
                                    )}
                                    disableCloseOnSelect
                                />
                            </div>
                        </>
                    </>
                )}

                {isOpenShift && (
                    <>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                // value={isShowShiftRate}
                                value={timesheet?.find((item:any) => item.value === filterData.is_shift_rate)}
                                options={timesheet}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Show Shift Rate"}
                                id="input_select_show_shift_rate"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    handleShowShiftRate(value);
                                }}
                                classes={{
                                    option: classes.option,
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="input_select_show_shift_rate_val"
                                        variant="outlined"
                                        placeholder={"Show Shift Rate"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>
                    </>
                )}

                {isCompleted && (
                    <>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                value={timesheet?.find((item:any) => item.value === filterData.is_timesheet_uploaded)}
                                options={timesheet}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Time Sheet"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {

                                    handleTimeSheetUploaded(value);
                                }}
                                classes={{
                                    option: classes.option,
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} id="select_status" variant="outlined"
                                               placeholder={"Time Sheet"}/>
                                )}
                                disableCloseOnSelect
                            />
                        </div>

                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                // value={isFacilityApproved}
                                value={facilityConfirmation?.find((item:any) => item.value === filterData.is_facility_approved)}
                                options={facilityConfirmation}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Facility Confirmation"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    handleIsFacilityApproved(value);
                                }}
                                classes={{
                                    option: classes.option,
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="select_fac_confirm"
                                        variant="outlined"
                                        placeholder={"Facility Confirmation"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>

                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                // value={isPendingConcern}
                                value={pendingConcern?.find((item:any) => item.value === filterData.is_pending_concern)}
                                options={pendingConcern}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Facility Confirmation"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    handleIsPendingConcern(value);
                                }}
                                classes={{
                                    option: classes.option,
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="select_fac_confirm"
                                        variant="outlined"
                                        placeholder={"Pending Concern"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                // value={hcpPaymentModeValue}
                                value={HcpPaymentMode?.find((item:any) => item.value === filterData.gusto_type)}
                                options={HcpPaymentMode}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Facility Confirmation"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    handleHcpPayment(value);
                                }}
                                classes={{
                                    option: classes.option,
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="select_fac_confirm"
                                        variant="outlined"
                                        placeholder={"Staff Payment Mode"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>
                    </>
                )}

                {
                    SHOW_IS_HCP_SENT_HOME_FIELD_FOR_SHIFT_STATUS_TYPES && <div className="form-field-item">
                        <Autocomplete
                            disableClearable
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            // value={isHcpSentHome}
                            value={facilityConfirmation?.find((item:any) => item.value === filterData.is_sent_home)}
                            options={facilityConfirmation}
                            getOptionLabel={(option: any) => option.label}
                            placeholder={"Staff Sent Home"}
                            id="input_select_hcp_sent_home"
                            className="mrg-top-10"
                            classes={{
                                option: classes.option,
                            }}
                            onChange={($event, value) => {
                                // setIsHcpSentHome(value);
                                handleIsHcpSentHome(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="select_hcp_sent_home"
                                    variant="outlined"
                                    placeholder={"Staff Sent Home"}
                                />
                            )}
                            disableCloseOnSelect
                        />
                    </div>
                }

                {SHOW_SALARY_CREDIT_FIELD_FOR_SHIFT_STATUS_TYPES && (
                    <div className="form-field-item">
                        <Autocomplete
                            disableClearable
                            className="mrg-top-10"
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            multiple
                            id="input_select_salary_credit"
                            // renderTags={() => null}
                            classes={{
                                option: classes.option,
                            }}
                            renderTags={(value, getTagProps) => {
                                const selectedCount = value.length;
                                return selectedCount > 0 ? (
                                    <span {...getTagProps({index: 0})}>
                                            {selectedCount} selected
                                        </span>
                                ) : null;
                            }}
                            renderInput={(params) => {
                                const placeholder = filterData?.salary_credit?.length === 0 || !filterData?.salary_credit
                                    ? "Salary Credit Day"
                                    : "";
                                return <TextField {...params}  variant="outlined" placeholder={placeholder} />;
                            }}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option?.name}
                            getOptionSelected={(option, value) => option.name === value?.name}

                            // value={selectedSalaryCreditTypes}
                            value={salaryCreditTypesForFilters?.filter((item:any)=>filterData?.salary_credit?.includes(item.code))}
                            options={formatFilterMenuListToHandleObj(salaryCreditTypesForFilters, filterData,"salary_credit")}
                            onChange={(e, newValue) => {
                                if (newValue) {
                                    handleFilterValueSelectToHandleObj(
                                        newValue,
                                        salaryCreditTypesForFilters,
                                        "salary_credit"
                                    );
                                }
                            }
                            }
                        />
                    </div>
                )}

                {isClosed && (
                    <>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                // value={isHcpPayment}
                                value={timesheet?.find((item?:any)=>item.value === filterData?.is_hcp_paid)}
                                options={timesheet}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Staff Paid"}
                                id="input_select_Payment_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    handleIsHcpPayment(value);
                                }}
                                classes={{
                                    option: classes.option,
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} id="select_payment_status" variant="outlined"
                                               placeholder={"Staff Paid"}/>
                                )}
                                disableCloseOnSelect
                            />
                        </div>
                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                // value={hcpPaymentModeValueForClosedShifts}
                                value={HcpPaymentMode?.find((item?:any)=>item.value === filterData?.gusto_type)}
                                options={HcpPaymentMode}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Facility Confirmation"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    handleHcpPaymentModeForClosedShifts(value);
                                }}
                                classes={{
                                    option: classes.option,
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="select_fac_confirm"
                                        variant="outlined"
                                        placeholder={"Staff Payment Mode"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>

                        <div className="form-field-item">
                            <Autocomplete
                                disableClearable
                                PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                                // value={isPendingConcernForClosedShift}
                                value={pendingConcern?.find((item?:any)=>item.value === filterData?.is_pending_concern)}
                                options={pendingConcern}
                                getOptionLabel={(option: any) => option.label}
                                placeholder={"Facility Confirmation"}
                                id="input_select_status"
                                className="mrg-top-10"
                                onChange={($event, value) => {
                                    handleIsPendingConcernForClosedShift(value);
                                }}
                                classes={{
                                    option: classes.option,
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="select_fac_confirm"
                                        variant="outlined"
                                        placeholder={"Pending Concern"}
                                    />
                                )}
                                disableCloseOnSelect
                            />
                        </div>

                        <div className="mrg-top-10">
                            <label>
                                <DatePicker
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Payment Date"
                                    className="custom-date-wrapper"
                                    selectsRange={true}
                                    startDate={filterData.hcp_pay_start_date && new Date(`${filterData.hcp_pay_start_date}T00:00:00`) }
                                    endDate={filterData.hcp_pay_end_date && new Date(`${filterData.hcp_pay_end_date}T00:00:00`)}
                                    onChange={(update) => {
                                        // setPaymentDateRange(update);
                                        handlePaymentDateRangeSelection(update);
                                    }}
                                    isClearable={true}
                                />
                                {!filterData?.hcp_pay_start_date && !filterData?.hcp_pay_end_date && (
                                    <DateRangeOutlined className="date-icon" fontSize="medium" color="action"/>
                                )}
                            </label>
                        </div>
                    </>
                )}
                <div className="mrg-top-10">
                    <label className="label-wrapper">
                        <DatePicker
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select Date"
                            className="shift-custom-input"
                            selectsRange={true}
                            startDate={filterData.start_date && new Date(`${filterData.start_date}T00:00:00`) }
                            endDate={filterData.end_date && new Date(`${filterData.end_date}T00:00:00`)}
                            onChange={(update) => {
                                // setDateRange(update);
                                handleDateRangeSelection(update);
                            }}
                            isClearable={true}
                        />
                        {!filterData?.start_date && !filterData?.end_date && (
                            <DateRangeOutlined className="date-icon" fontSize="medium" color="action"/>
                        )}
                    </label>
                </div>
                <div className="form-field-item">
                    <div style={{position: "relative"}}>
                        <Autocomplete
                            disableClearable
                            PaperComponent={({children}) => (
                                <Paper style={{color: "#1e1e1e"}}>{children}</Paper>
                            )}
                            // value={shiftRequirementType}
                            value={requirementTypeList?.find((item:any)=>item.value === filterData?.type)}
                            options={requirementTypeList}
                            getOptionLabel={(option: any) => option.label}
                            id="input_select_status"
                            className="mrg-top-10"
                            onChange={($event, value) => {
                                handleRequirementType(value);
                            }}
                            classes={{
                                option: classes.option,
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" placeholder={"Requirement Type"}/>
                            )}
                            disableCloseOnSelect
                        />
                        {filterData?.type && (
                            <IconButton
                                onClick={() => {
                                    let updatedFilterData={...filterData}
                                    delete updatedFilterData.type
                                    setFilterData(updatedFilterData)
                                }}
                                style={{
                                    position: "absolute",
                                    right: 30,
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    padding: 0,
                                }}
                            >
                                <ClearIcon/>
                            </IconButton>
                        )}
                    </div>
                </div>

                <div className="form-field-item">
                    <Autocomplete
                        disableClearable
                        disabled={isFacilityListLoading}
                        className="mrg-top-10"
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        multiple
                        getOptionLabel={(option) => option?.name}
                        getOptionSelected={(option, value) => option.name === value?.name}
                        id="input_select_facility"
                        // value={selectedFacilities}
                        value={facilityList?.filter((item:any)=>filterData?.facilities?.includes(item.code))}
                        options={formatFilterMenuListToHandleObj(facilityList,filterData,'facilities')}
                        onChange={(e, newValue) => {
                            if (newValue) {
                                handleFilterValueSelectToHandleObj(
                                    newValue,
                                    facilityList,
                                    "facilities"
                                );
                            }
                        }}
                        // renderTags={() => null}
                        classes={{
                            option: classes.option,
                        }}
                        renderTags={(value, getTagProps) => {
                            const selectedCount = value.length;
                            return selectedCount > 0 ? (
                                <span {...getTagProps({index: 0})}>
                                            {selectedCount} selected
                                        </span>
                            ) : null;
                        }}
                        renderInput={(params) => {
                            const placeholder = filterData?.facilities?.length === 0 || !filterData?.facilities
                                ? "Facilities"
                                : "";
                            return <TextField {...params}  variant="outlined" placeholder={placeholder} />;
                        }}
                        disableCloseOnSelect
                    />
                    {isFacilityListLoading && (
                        <div className="loading-text-wrapper">
                            <span className="loading-text">loading...</span>
                        </div>
                    )}
                </div>


                {!isOpenShift && (
                    <div className="form-field-item">
                        <Autocomplete
                            disableClearable
                            disabled={isApprovedHcpsLoading}
                            className="mrg-top-10"
                            PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                            multiple
                            id="input_select_hcps"
                            getOptionLabel={(option) => option?.name}
                            getOptionSelected={(option, value) => option.name === value?.name}
                            // value={selectedApprovedHcps}
                            value={approvedHcps?.filter((item:any)=> filterData?.hcp_user_ids?.includes(item?.code))}
                            options={formatFilterMenuListToHandleObj(approvedHcps,filterData,"hcp_user_ids")}
                            onChange={(e, newValue) => {
                                if (newValue) {
                                    handleFilterValueSelectToHandleObj(
                                        newValue,
                                        approvedHcps,
                                        "hcp_user_ids"
                                    );
                                }
                            }}
                            // renderTags={() => null}
                            classes={{
                                option: classes.option,
                            }}
                            renderTags={(value, getTagProps) => {
                                const selectedCount = value.length;
                                return selectedCount > 0 ? (
                                    <span {...getTagProps({index: 0})}>
                                            {selectedCount} selected
                                        </span>
                                ) : null;
                            }}
                            renderInput={(params) => {
                                const placeholder = filterData?.hcp_user_ids?.length === 0 || !filterData?.hcp_user_ids
                                    ? "Staff"
                                    : "";
                                return <TextField {...params}  variant="outlined" placeholder={placeholder} />;
                            }}
                            disableCloseOnSelect
                        />
                        {isApprovedHcpsLoading && (
                            <div className="loading-text-wrapper">
                                <span className="loading-text">loading...</span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};


const clearFieldsFiltersFromLocalStorage = () => {
    localStore.removeItem("selectedFacilities");
    localStore.removeItem("selectedApprovedHcps");
    localStore.removeItem("selectedHcps");
    localStore.removeItem("statusType");
    localStore.removeItem("selectedStatusTypes");
    localStore.removeItem("selectedStatusTypesInPending");
    localStore.removeItem("selectedStatusTypesInOpenShifts");
    localStore.removeItem("dateRange");
    localStore.removeItem("selectedTimeTypes");
    localStore.removeItem("isFacilityApproved");
    localStore.removeItem("isPendingConcernForCompletedShift");
    localStore.removeItem("hcpPaymentModeValue");
    localStore.removeItem("isTimeSheetUploaded");
    localStore.removeItem("dateRange");
    localStore.removeItem("paymentDateRange");
    localStore.removeItem("isHcpPayment");
    localStore.removeItem("isFacilityPayment");
    localStore.removeItem("isShowShiftRate");
    localStore.removeItem("isHcpSentHome");
    localStore.removeItem("selectedSalaryCreditTypes");
    localStore.removeItem("hcpPaymentModeValueForClosedShift");
    localStore.removeItem("isPendingConcernForClosedShift");
    localStore.removeItem("requested-by-open-shift");
};


const clearFieldsSelectAllOptionFromLocalStorage = () => {
    localStore.removeItem("isAllHcpTypeSelected");
    localStore.removeItem("isAllTimeTypesSelected");
    localStore.removeItem("isAllFacilitiesSelected");
    localStore.removeItem("isAllStatusTypesSelected");
    localStore.removeItem("isAllApprovedHcpsSelected");
    localStore.removeItem("isAllSalaryCreditTypeSelected");
};


export const clearShiftFilterValues = () => {
    clearFieldsFiltersFromLocalStorage();
    clearFieldsSelectAllOptionFromLocalStorage();
};


export default ShiftFilterDrawerComponent;
