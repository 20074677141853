import {Button, FormControlLabel, MenuItem, Radio, Tooltip, Box, InputAdornment} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import {Field, Form, Formik} from "formik";
import {CheckboxWithLabel, RadioGroup, TextField} from "formik-material-ui";
import {DatePicker} from "formik-material-ui-pickers";
import React, {useCallback, useEffect, useState} from "react";
import {ScrollToError} from "../../../../components/ScrollToError";
import {
    boolAcknowledge,
    contactType,
    covidPreference,
    genderTypes,
    hcpPaymentModeOptions,
    moreImportant,
    salaryCredit,
    shiftTypePreference,
    travelDistancePreference,
    vaccine
} from "../../../../constants/data";
// import HcpAddAttachmentsComponent from "../AddAtachments/HcpAddAttachmentsComponent";
import {AddHcpInitialValues, hcpFormValidation, HcpItemAddType} from "../AddHcpValuesValidations";
import {CommonService} from "../../../../helpers";
import FileUploadComponent from "../../../../components/file-upload/FileUploadComponent";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import HcpAttachmentComponentANTD from "../hcp-attachment-component/HcpAttachmentComponentANTD";

const AddHcpBasicDetailsComponent = (props: any) => {
    const [vaccineStatus, setVaccineStatus] = useState<string>("");
    const [isFirstShotVisible, setIsFirstShotVisible] = useState<boolean>(false);
    const [isLastShotVisible, setIsLastShotVisible] = useState<boolean>(false);

    const contractFile = props?.contractFile;
    const uploadedFiles = props?.uploadedFiles;
    const setUploadedFiles = props?.setUploadedFiles;
    // const state = props?.state;
    const fileUpload = props?.fileUpload;
    const setPreviewFile = props?.setPreviewFile;
    const setOpen = props?.setOpen;
    const onAdd = props?.onAdd;
    const hcpTypes = props?.hcpTypes;
    const regions = props?.regions;
    const specialities = props?.specialities;
    const expInYears = props?.expInYears;
    // const required_attachments = props?.required_attachments;
    // const setRequiredAttachments = props?.setRequiredAttachments;
    const OnContractFileUpload = props?.OnContractFileUpload;
    const deleteContractFile = props?.deleteContractFile;
    // const setFileUpload = props?.setFileUpload;
    // const setOtherDocuments = props?.setOtherDocuments;
    // const otherDocuments = props?.otherDocuments;

    let hcpInitialState: HcpItemAddType = AddHcpInitialValues;


    const showDropDownBelowField = {
        MenuProps: {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
            getContentAnchorEl: null,
        },
    };
    const previewFile = useCallback(
        (index: any, type: any) => {
            if (type === "contract") {
                setPreviewFile(contractFile?.wrapper[0]);
            } else {
                setPreviewFile(fileUpload?.wrapper[index]);
            }
            setOpen(true);
        },
        [fileUpload, contractFile?.wrapper, setPreviewFile, setOpen]
    );

    useEffect(() => {
        if (vaccineStatus === "full") {
            setIsFirstShotVisible(true);
            setIsLastShotVisible(true);
        }
        if (vaccineStatus === "half") {
            setIsFirstShotVisible(true);
            setIsLastShotVisible(false);
        }
        if (vaccineStatus === "exempted") {
            setIsFirstShotVisible(false);
            setIsLastShotVisible(false);
        }
        if (vaccineStatus === "") {
            setIsFirstShotVisible(false);
            setIsLastShotVisible(false);
        }
    }, [vaccineStatus]);

    async function getLatLong(street: any, city: any, state: any, zipcode: any) {
        // const address = `${street}, ${city}, ${state}, ${zipcode}, USA`;
        // const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&addressdetails=1&limit=1`;
        const url = `https://nominatim.openstreetmap.org/search?postalcode=${zipcode}&countrycodes=us&format=json&limit=1`;
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.length > 0) {
                const location = data[0];
                return {
                    latitude: location.lat,
                    longitude: location.lon,
                };
            } else {
                throw new Error('Geocoding failed: No results found');
            }
        } catch (error) {
            console.error('Error fetching geolocation:', error);
            return null;
        }
    }


    return (
        <div>
            <Formik initialValues={hcpInitialState} validateOnChange={true} validationSchema={hcpFormValidation}
                    onSubmit={onAdd}>
                {({isSubmitting, isValid, resetForm, errors, handleChange, values, setFieldValue}) => (
                    <Form id="add-hcp-form" className={"form-holder"}>
                        <div>
                            <div className="custom-border">
                                <p className="card-header">Basic Details</p>
                                <div className="input-container">
                                    <Field variant="outlined" name="first_name" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           id="input_hcp_add_first_name" label="First Name*" fullWidth
                                           autoComplete="off"/>
                                    <Field variant="outlined" name="last_name" id="input_hcp_add_last_name"
                                           type={"text"} component={TextField} label="Last Name*" fullWidth
                                           InputLabelProps={{shrink: true}}
                                           autoComplete="off"/>
                                </div>
                                <div className={'input-container'}>
                                    <Field
                                        variant="outlined"
                                        component={TextField} type={"email"} fullWidth InputLabelProps={{shrink: true}}
                                        autoComplete="off" className="flex-1" label="Email*" name="email"
                                        id="input_hcp_add_email"
                                    />
                                    <div className="flex-1 d-flex">
                                        <Field
                                            inputProps={{ maxLength: 10,
                                                onInput: (e:any) => {
                                                    if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10);
                                                },
                                            }}
                                            className="phone_number"
                                            variant="outlined"
                                            name="contact_number"
                                            type={"number"}
                                            component={TextField}
                                            label="Phone Number*"
                                            fullWidth
                                            autoComplete="off"
                                            id="input_facility_member_add_phone_number"
                                            InputLabelProps={{shrink: true}}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        +1
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>

                                {/*<div className="flex-1">*/}
                                {/*    <Field required name={"contact_number"} className="flex-1">*/}
                                {/*        {(field: FieldProps) => {*/}
                                {/*            return <PhoneInputComponent field={field}*/}
                                {/*                                        placeholder={"Enter Phone number*"}/>;*/}
                                {/*        }}*/}
                                {/*    </Field>*/}
                                {/*</div>*/}
                                <div className="input-container">
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel className={"form-label"}>{"Gender"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="gender" id="radio_doctor_add_gender">
                                                <div className="d-flex">
                                                    {genderTypes.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel key={"gender_type_" + index}
                                                                                  value={item.value}
                                                                                  control={<Radio
                                                                                      disabled={isSubmitting}/>}
                                                                                  disabled={isSubmitting}
                                                                                  onChange={(event) => handleChange(event)}
                                                                                  label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <Field
                                            SelectProps={showDropDownBelowField}
                                            variant="outlined"
                                            onChange={(e: any) => {
                                                const hcpType = e.target.value;
                                                setFieldValue("hcp_type", hcpType);
                                            }}
                                            component={TextField}
                                            type={"text"}
                                            select
                                            label="Staff Type*"
                                            id="menu_hcp_add_hcp_type"
                                            name="hcp_type"
                                            fullWidth
                                            autoComplete="off"
                                            InputLabelProps={{shrink: true}}
                                        >
                                            {hcpTypes.map((item: any, index: number) => (
                                                <MenuItem value={item.code} key={"hcp_type_" + index}
                                                          id={"menu_hcp_add_hcp_type" + item.title}>
                                                    {item?.title}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </div>
                                    {/*<div className={"flex-1"}>*/}
                                    {/*    <Field variant="outlined" name="hcp_uniqueId" type={"text"}*/}
                                    {/*           component={TextField} InputLabelProps={{shrink: true}}*/}
                                    {/*           label="Staff ID*" fullWidth id="input_hcp_edit_uniqueId"*/}
                                    {/*           autoComplete="off"/>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="input-container">
                                    <Field variant="outlined" name="address.street" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="Street*" id="input_hcp_add_street" fullWidth autoComplete="off"/>
                                    <Field variant="outlined" name="address.city" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           id="input_hcp_add_city" label="City*" fullWidth autoComplete="off"/>
                                </div>
                                <div className="input-container">
                                    <Field SelectProps={showDropDownBelowField} variant="outlined" component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           type={"text"} select label="State*" name="address.state"
                                           id="menu_hcp_add_state" fullWidth autoComplete="off">
                                        {regions &&
                                            regions.map((item: any, index: any) => (
                                                <MenuItem value={item.code} key={"state_" + index}
                                                          id={"menu_hcp_add_state" + item.code}>
                                                    {item.title}
                                                </MenuItem>
                                            ))}
                                    </Field>
                                    <Field
                                        variant="inline"
                                        orientation="landscape"
                                        openTo="date"
                                        format="MM/dd/yyyy"
                                        views={["year", "month", "date"]}
                                        inputVariant="outlined"
                                        component={DatePicker}
                                        placeholder="MM/DD/YYYY"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true}}
                                        label="DOB"
                                        name="dob"
                                    />
                                </div>

                                {/*<div className="input-container">*/}
                                {/*    <Field inputProps={{*/}
                                {/*        maxLength: 6,*/}
                                {/*    }} type="text" variant="outlined" fullWidth name="address.zip_code"*/}
                                {/*           component={TextField} id="input_hcp_add_zip" label="Zip*"*/}
                                {/*           autoComplete="off"/>*/}

                                {/*</div>*/}

                                <div className="input-container d-flex">
                                    {/*<div style={{width: "50%"}}>*/}
                                    {/*    <Field*/}
                                    {/*        variant="inline"*/}
                                    {/*        orientation="landscape"*/}
                                    {/*        openTo="date"*/}
                                    {/*        format="dd/MM/yyyy"*/}
                                    {/*        views={["year", "month", "date"]}*/}
                                    {/*        inputVariant="outlined"*/}
                                    {/*        component={DatePicker}*/}
                                    {/*        placeholder="DD/MM/YYYY"*/}
                                    {/*        fullWidth*/}
                                    {/*        autoComplete="off"*/}
                                    {/*        InputLabelProps={{shrink: true}}*/}
                                    {/*        label="DOB"*/}
                                    {/*        name="dob"*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div style={{width: "100%"}} className={'d-flex'}>
                                        <div style={{width: "100%"}}>
                                            <Field
                                                inputProps={{
                                                    maxLength: 6,
                                                }}
                                                variant="outlined"
                                                name="address.zip_code"
                                                type={"text"}
                                                component={TextField}
                                                label="Zip Code*"
                                                autoComplete="off"
                                                id="input_facility_add_address_zip_code"
                                                fullWidth
                                                InputLabelProps={{shrink: true}}
                                            />
                                        </div>
                                        <div className={'mrg-left-10 mrg-top-5'}>
                                            <Button variant={'contained'} color={"primary"}
                                                //@ts-ignore
                                                    disabled={!values?.address?.zip_code}
                                                    onClick={() => {
                                                        //@ts-ignore
                                                        getLatLong('', '', '', values?.address?.zip_code)
                                                            .then((coords: any) => {
                                                                setFieldValue("latitude", coords.latitude);
                                                                setFieldValue("longitude", coords.longitude);
                                                                CommonService.showToast("Latitude and Longitude Fetched", "success")
                                                                // const googleMapsUrl = `https://www.google.com/maps?q=${coords.latitude},${coords.longitude}`;
                                                                // window.open(googleMapsUrl, '_blank');
                                                            })
                                                            .catch((error) => CommonService.showToast("Not Able to fetch Latitude and Longitude due to geocode errors", "error"));
                                                    }}>
                                                Check
                                            </Button>
                                        </div>
                                        <div className={'mrg-left-10 mrg-top-5'}>
                                            <Button variant={'contained'} color={"primary"}
                                                //@ts-ignore
                                                    disabled={!values.latitude || !values.longitude}
                                                    onClick={() => {
                                                        //@ts-ignore
                                                        getLatLong('', '', '', values?.patient_zip_code)
                                                            .then((coords: any) => {
                                                                //@ts-ignore
                                                                if (values.latitude && values.longitude) {
                                                                    //@ts-ignore
                                                                    const googleMapsUrl = `https://www.google.com/maps?q=${values.latitude},${values.longitude}`;
                                                                    window.open(googleMapsUrl, '_blank');
                                                                }
                                                            })
                                                            .catch((error) => console.error(error));
                                                    }}>
                                                Map
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-container">
                                    <Field fullWidth variant="outlined" name="latitude" type={"text"}
                                           component={TextField} InputLabelProps={{shrink: true}}
                                           label="Latitude*" autoComplete="off" id="input_facility_add_latitude"/>
                                    <Field fullWidth variant="outlined" name="longitude" type={"text"}
                                           InputLabelProps={{shrink: true}}
                                           component={TextField}
                                           label="Longitude*" autoComplete="off" id="input_facility_add_longitude"/>
                                </div>
                                <div className="facility-about mrg-top-50">
                                    <p className="card-header">About the Staff</p>
                                    <Field placeholder="About the Staff" variant="outlined" component={TextField}
                                           type={"text"} fullWidth autoComplete="off" name="about"
                                           InputLabelProps={{shrink: true}}
                                           id="input_hcp_add_about" multiline/>
                                </div>
                            </div>
                            <div className="custom-border">
                                <div className="professional-summary mrg-top-10 ">
                                    <p className="card-header">Professional Summary</p>
                                    <Field variant="outlined" component={TextField} type={"text"} fullWidth
                                           autoComplete="off" name="professional_details.summary"
                                           id="input_hcp_add_summary" multiline InputLabelProps={{shrink: true}}
                                           placeholder="Enter Professional Summary"/>
                                </div>
                            </div>
                            <div className="hcp-profession-details  mrg-top-10 custom-border">
                                <p className="card-header">Professional Details (Based on Work Experience)</p>
                                <div className="input-container">
                                    <Field value={expInYears} disabled variant="outlined" component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="Years of Experience" name="professional_details.experience"
                                           id="input_hcp_add_proffesional_details" fullWidth autoComplete="off"/>
                                </div>
                                <div className="input-container professional-details">
                                    <Field value={specialities.join(",")} disabled variant="outlined"
                                           component={TextField} type={"text"} label="Specialities"
                                           InputLabelProps={{shrink: true}}
                                           id="input_hcp_add_speciality" name="professional_details.speciality"
                                           fullWidth autoComplete="off"/>
                                </div>
                            </div>
                            {/*<div className="hcp-documents mrg-top-10 custom-border">*/}
                            {/*    <h3 className="card-header">Documents/Attachments</h3>*/}
                            {/*    <HcpAddAttachmentsComponent required_attachments={required_attachments}*/}
                            {/*                                setRequiredAttachments={setRequiredAttachments}*/}
                            {/*                                fileUpload={fileUpload} setFileUpload={setFileUpload}*/}
                            {/*                                previewFile={previewFile} otherDocuments={otherDocuments}*/}
                            {/*                                setOtherDocuments={setOtherDocuments}/>*/}

                            {/*</div>*/}
                            <div className=" mrg-top-10 custom-border">
                                <h3 className="card-header">Documents/Attachments</h3>
                                <HcpAttachmentComponentANTD uploadedFiles={uploadedFiles}
                                                            setUploadedFiles={setUploadedFiles}/>
                            </div>

                            <div className="custom-border mrg-top-10">
                                <div className="attachments_wrapper  mrg-bottom-30">
                                    {contractFile?.wrapper?.map((item: any, index: any) => {
                                        return (
                                            <div className="attachments">
                                                <h3 className="mrg-top-10 mrg-bottom-10 file_name card-header">{"Contract"}</h3>
                                                <div className="d-flex mrg-top-30">
                                                    <div className={'contract-file-preview-image-wrapper'}>
                                                        <div className={'file-preview-image'}>
                                                            <InsertDriveFileIcon color={"primary"}
                                                                                 className="file-image"/>
                                                        </div>
                                                    </div>
                                                    <div className={'file-preview-btn-wrapper'}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => previewFile(index, "contract")}
                                                            style={{marginRight: "8px"}}
                                                        >
                                                            View
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => deleteContractFile(index)}
                                                            style={{marginRight: "8px"}}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {contractFile?.wrapper.length >= 1 ? (
                                    <></>
                                ) : (
                                    <div>
                                        <h3 className="card-header">Contract</h3>
                                        <Box display="flex" gridGap="10px">
                                            <Tooltip title={"Upload Contract"}>
                                                <Box width="250px" className="mrg-top-10">
                                                    {/*<FileDropZoneComponent allowedTypes={".pdf"}*/}
                                                    {/*                       OnFileSelected={OnContractFileUpload}/>*/}
                                                    <FileUploadComponent
                                                        allowedTypes={".pdf"}
                                                        onFileSelected={OnContractFileUpload}
                                                    />
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </div>
                                )}
                                <div className="input-container mrg-top-30">
                                    <Field placeholder="Rate/hr" variant="outlined" component={TextField} type={"text"}
                                           fullWidth autoComplete="off" InputLabelProps={{shrink: true}} label="Rate/hr"
                                           name="contract_details.rate_per_hour"/>
                                    <Field placeholder="Checkrpay ID" variant="outlined" component={TextField}
                                           type={"text"}
                                           fullWidth autoComplete="off" InputLabelProps={{shrink: true}}
                                           label="Checkrpay ID"
                                           name="worker_id"/>
                                    <Field
                                        variant="inline"
                                        orientation="landscape"
                                        openTo="date"
                                        format="MM/dd/yyyy"
                                        views={["year", "month", "date"]}
                                        inputVariant="outlined"
                                        component={DatePicker}
                                        placeholder="MM/DD/YYYY"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true}}
                                        label="Signed On"
                                        name="contract_details.signed_on"
                                    />
                                    <Field
                                        SelectProps={showDropDownBelowField}
                                        select
                                        variant="outlined"
                                        name="contract_details.salary_credit"
                                        type={"text"}
                                        component={TextField}
                                        id="input_hcp_add_salary_credit"
                                        label="Salary Credit Day"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true}}
                                    >
                                        <MenuItem value="">Select Value</MenuItem>
                                        {salaryCredit.map((item: any, index: any) => (
                                            <MenuItem key={index} value={item.value}
                                                      id={"menu_hcp_add_salary_credit_" + index}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Field>

                                    <Field
                                        SelectProps={showDropDownBelowField}
                                        select
                                        variant="outlined"
                                        name="nc_details.gusto_type"
                                        type={"text"}
                                        component={TextField}
                                        id="input_hcp_add_salary_credit"
                                        label="Staff Payment Mode"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true}}
                                    >
                                        <MenuItem value="">Select Value</MenuItem>
                                        {hcpPaymentModeOptions.map((item: any, index: any) => (
                                            <MenuItem key={index} value={item.value}
                                                      id={"menu_hcp_add_salary_credit_" + index}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </div>
                            </div>

                            <div className="travel-preferences custom-border mrg-top-10">
                                <p className="card-header">Travel Preferences</p>
                                <div className="travel-preferences-container">
                                    {travelDistancePreference.map((item) => (
                                        <Field type="checkbox" component={CheckboxWithLabel}
                                               name="nc_details.travel_preferences" key={item.value} value={item.value}
                                               Label={{label: item.label}}/>
                                    ))}
                                </div>
                            </div>

                            <div className="notification-preferences custom-border mrg-top-10">
                                <p className="card-header">Notification Preferences</p>
                                <div className="notification-preferences-container">
                                    <Field type="checkbox" component={CheckboxWithLabel}
                                           name="notification_preferences.sms" Label={{label: "SMS"}}/>
                                    <Field type="checkbox" component={CheckboxWithLabel}
                                           name="notification_preferences.email" Label={{label: "Email"}}/>
                                    <Field type="checkbox" component={CheckboxWithLabel}
                                           name="notification_preferences.push_notification"
                                           Label={{label: "In-application"}}/>
                                </div>
                            </div>

                            <div className="nc-section custom-border mrg-top-10">
                                <p className="card-header">NC Section</p>
                                <div className="input-container">
                                    <Field variant="outlined" name="nc_details.dnr" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           label="DNR" id="input_hcp_add_dnr" fullWidth autoComplete="off"/>
                                    <Field SelectProps={showDropDownBelowField} select variant="outlined"
                                           name="nc_details.contact_type" type={"text"} component={TextField}
                                           InputLabelProps={{shrink: true}}
                                           id="input_hcp_add_contact_type" label="Contact Type" fullWidth
                                           autoComplete="off">
                                        <MenuItem value="">Select Value</MenuItem>
                                        {contactType.map((item: any, index: any) => (
                                            <MenuItem value={item.value} id={"menu_hcp_add_contact_type" + index}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </div>

                                <div className="input-container">
                                    <Field variant="outlined" name="nc_details.location_preference" type={"text"}
                                           component={TextField} label="Preferred Location to Work"
                                           InputLabelProps={{shrink: true}}
                                           id="input_hcp_add_location_preference" fullWidth autoComplete="off"/>

                                    <Field
                                        SelectProps={showDropDownBelowField}
                                        select
                                        variant="outlined"
                                        name="nc_details.shift_type_preference"
                                        type={"text"}
                                        component={TextField}
                                        id="input_hcp_add_shift_type_preference"
                                        label="Preference Shift Type"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{shrink: true}}
                                    >
                                        <MenuItem value="">Select Shift Type</MenuItem>
                                        {shiftTypePreference.map((item: any, index: any) => (
                                            <MenuItem value={item.value}
                                                      id={"menu_hcp_add_shift_type_preference" + index}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </div>

                                <div className="input-container">
                                    <Field variant="outlined" name="nc_details.zone_assignment" type={"text"}
                                           component={TextField} id="input_hcp_add_zone_assignment"
                                           InputLabelProps={{shrink: true}}
                                           label="Zone Assignment" fullWidth autoComplete="off"/>
                                    <Field
                                        variant="outlined"
                                        component={TextField}
                                        placeholder="MM-DD-YYYY"
                                        fullWidth
                                        autoComplete="off"
                                        label="Last Call Date  (MM-DD-YYYY)"
                                        name="nc_details.last_call_date"
                                        InputLabelProps={{shrink: true}}
                                    />
                                </div>

                                <div className="input-container">
                                    <Field
                                        onClick={(e: any) => {
                                            setFieldValue("nc_details.vaccine", e.target.value);
                                            setVaccineStatus(e.target.value);
                                            if (e.target.value === "exempted") {
                                                setFieldValue("nc_details.vaccination_dates.first_shot", "");
                                                setFieldValue("nc_details.vaccination_dates.latest_shot", "");
                                            }

                                            if (e.target.value === "half") {
                                                setFieldValue("nc_details.vaccination_dates.first_shot", "");
                                            }

                                            if (e.target.value === '') {
                                                setFieldValue("nc_details.vaccination_dates.first_shot", "");
                                                setFieldValue("nc_details.vaccination_dates.latest_shot", "");
                                            }
                                        }}
                                        SelectProps={showDropDownBelowField}
                                        select
                                        variant="outlined"
                                        name="nc_details.vaccine"
                                        type={"text"}
                                        component={TextField}
                                        id="input_hcp_add_vaccine"
                                        label="Vaccine"
                                        fullWidth
                                        InputLabelProps={{shrink: true}}
                                        autoComplete="off"
                                    >
                                        <MenuItem value="">Select Value</MenuItem>
                                        {vaccine.map((item: any, index: any) => (
                                            <MenuItem value={item.value} id={"menu_hcp_add_vaccine_" + index}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                    <Field variant="outlined" name="nc_details.family_consideration" type={"text"}
                                           component={TextField} id="input_hcp_add_family_consideration"
                                           InputLabelProps={{shrink: true}}
                                           label="Family Considerations" fullWidth autoComplete="off"/>
                                </div>

                                <div
                                    className={`${isFirstShotVisible && isLastShotVisible ? "input-container" : "input-container"}`}>
                                    {isFirstShotVisible && (
                                        <Field variant="outlined" name="nc_details.vaccination_dates.first_shot"
                                               type={"text"} component={TextField} label="First Shot Date  (MM-DD-YYYY)"
                                               id="input_hcp_add_vaccination_dates_first_shot" fullWidth
                                               InputLabelProps={{shrink: true}}
                                               autoComplete="off"/>
                                    )}
                                    {isLastShotVisible && (
                                        <Field variant="outlined" name="nc_details.vaccination_dates.latest_shot"
                                               type={"text"} component={TextField}
                                               label="Latest Shot Date  (MM-DD-YYYY)"
                                               id="input_hcp_add_vaccination_dates_latest_shot" fullWidth
                                               InputLabelProps={{shrink: true}}
                                               autoComplete="off"/>
                                    )}
                                </div>

                                <div className="input-container d-flex">
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel
                                                className={"form-label"}>{"Do you have a Full-time Job?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.is_fulltime_job">
                                                <div className="d-flex">
                                                    {boolAcknowledge?.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel key={"full-time" + index}
                                                                                  value={item.value} control={<Radio
                                                                    disabled={isSubmitting}/>} disabled={isSubmitting}
                                                                                  name="nc_details.is_fulltime_job"
                                                                                  label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel
                                                className={"form-label"}>{"Covid (or) Non Covid Facility?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.covid_facility_preference">
                                                <div className="d-flex">
                                                    {covidPreference.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel key={"full-time" + index}
                                                                                  value={item.value} control={<Radio
                                                                    disabled={isSubmitting}/>} disabled={isSubmitting}
                                                                                  name="nc_details.covid_facility_preference"
                                                                                  label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-container d-flex">
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel
                                                className={"form-label"}>{"What is more important for you?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.more_important_preference">
                                                <div className="d-flex">
                                                    {moreImportant.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_more_important_preference" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.more_important_preference"
                                                                    label={item.label}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel
                                                className={"form-label"}>{"Is this a Supplement to your Income ?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.is_supplement_to_income">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_more_important_preference" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.is_supplement_to_income"
                                                                    label={item.label}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-container d-flex">
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel className={"form-label"}>{"Are you Studying?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.is_studying">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_more_important_preference" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.is_studying" label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                    {/*<div className="flex-1">*/}
                                    {/*    <div className="pdd-top-10">*/}
                                    {/*        <FormLabel className={"form-label"}>{"Gusto"}</FormLabel>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="mrg-top-10">*/}
                                    {/*        <Field component={RadioGroup} name="nc_details.gusto_type">*/}
                                    {/*            <div className="d-flex">*/}
                                    {/*                {gustoType.map((item: any, index) => {*/}
                                    {/*                    return (*/}
                                    {/*                        <div>*/}
                                    {/*                            <FormControlLabel*/}
                                    {/*                                key={"input_hcp_add_more_important_preference" + index}*/}
                                    {/*                                value={item.value}*/}
                                    {/*                                control={<Radio disabled={isSubmitting}/>}*/}
                                    {/*                                disabled={isSubmitting} name="nc_details.gusto_type"*/}
                                    {/*                                label={item.label}/>*/}
                                    {/*                        </div>*/}
                                    {/*                    );*/}
                                    {/*                })}*/}
                                    {/*            </div>*/}
                                    {/*        </Field>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="input-container d-flex">
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel className={"form-label"}>{"Is Gusto Invited ?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.is_gusto_invited">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_more_important_preference" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.is_gusto_invited"
                                                                    label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel className={"form-label"}>{"Is Gusto Onboarded ?"}</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.is_gusto_onboarded">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_gusto_onboarded" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.is_gusto_onboarded"
                                                                    label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-container d-flex">
                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel className={"form-label"}>Require Sponsorship for Employment in
                                                United States?</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup}
                                                   name="nc_details.is_require_employment_sponsorship">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_is_require_employment_sponsorship" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.is_require_employment_sponsorship"
                                                                    label={item.label}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="flex-1">
                                        <div className="pdd-top-10">
                                            <FormLabel className={"form-label"}>Legally Authorized to work in United
                                                States?</FormLabel>
                                        </div>
                                        <div className="mrg-top-10">
                                            <Field component={RadioGroup} name="nc_details.is_authorized_to_work">
                                                <div className="d-flex">
                                                    {boolAcknowledge.map((item: any, index) => {
                                                        return (
                                                            <div>
                                                                <FormControlLabel
                                                                    key={"input_hcp_add_is_authorized_to_work" + index}
                                                                    value={item.value}
                                                                    control={<Radio disabled={isSubmitting}/>}
                                                                    disabled={isSubmitting}
                                                                    name="nc_details.is_authorized_to_work"
                                                                    label={item.label}/>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-container">
                                    <Field multiline rows={2} variant="outlined" name="nc_details.other_information"
                                           type={"text"} component={TextField} id="input_hcp_add_other_information"
                                           InputLabelProps={{shrink: true}}
                                           label="Other Information Gathered" fullWidth autoComplete="off"/>
                                </div>
                            </div>
                        </div>
                        <ScrollToError/>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddHcpBasicDetailsComponent;
