import React, {useCallback, useEffect, useState} from "react";
import {Steps, Button} from "antd";
import "./AddJobComponent.scss";
import JobBasicDetailsForm from "./job-basic-details/JobBasicDetailsComponent";
import {useHistory} from "react-router-dom";
import JobCredentialingComponents from "./job-credentialing/JobCredentialingComponents";
import JobRateTable from "./job-rates/JobRateComponent";
const {Step} = Steps;

const JobAddForm: React.FC = () => {
    const history = useHistory();
    const [current, setCurrent] = useState(0);
    const [buttonText, setButtonText] = useState<any>("Next");
    const [apiDataAfterAdd, setApiDataAfterAdd] = useState<any>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [current]);

    useEffect(() => {
        if (current === 0) {
            setButtonText("Next")
        }
        if (current === 2) {
            setButtonText("Submit")
        }
    }, [current]);

    const next = useCallback(() => {
        setCurrent(current + 1);
    }, [current]);


    const steps = [
        {
            title: "Basic Details",
            content: (
                <>
                    <JobBasicDetailsForm next={next} setApiDataAfterAdd={setApiDataAfterAdd}
                    />
                </>
            ),
        },
        {
            title: "Credentialing",
            content: (
                <>
                    <JobCredentialingComponents setButtonText={setButtonText} next={next}
                                                apiDataAfterAdd={apiDataAfterAdd}
                    />
                </>
            ),
        },
        {
            title: "Rate Card",
            content: (
                <>
                    <JobRateTable
                        apiDataAfterAdd={apiDataAfterAdd}
                    />
                </>
            ),
        },
    ];

    const handleSubmit=useCallback(()=>{
        const formId =
            current === 0
                ? "add-job-basic-details"
                : current === 1
                    ? "add-job-credentialing"
                    : "add-job-rate-card";

        const formElement = document.getElementById(formId) as HTMLFormElement;
        formElement?.requestSubmit(); // Ensure Formik's submit handler gets triggered
    },[current])

    const handleExit=useCallback(()=>{
        // if(current !== 0 && apiDataAfterAdd?.job_type === 'per_diem'){
        //     const payload={
        //         is_bulk_edit:true
        //     }
        //     ApiService.put(ENV.API_URL + `ats/${apiDataAfterAdd?._id}/job`, payload)
        //         .then((resp: any) => {
        //         })
        //         .catch((err: any) => {
        //         });
        // }
        history.push("/job");
    },[history])


    return (
        <>
            <div className={'add-job-form-container-whole'}>
                <div className={'form-header'}>
                    <div className="header-title pdd-10">Add Job</div>
                    {/*<Button className="next-button" ghost type={'default'}>Save as draft</Button>*/}
                </div>
                <div className={'mrg-top-30'}>
                    <div className="d-flex justify-content-center align-items-center">
                        <div style={{width: "50%"}}>
                            <Steps current={current}>
                                {steps.map((step, index) => (
                                    <Step key={index} title={step.title}/>
                                ))}
                            </Steps>
                        </div>
                    </div>
                </div>
                <div className="form-body">
                    <div>{steps[current].content}</div>
                </div>

                {/* Fixed Footer with 'Next' button */}
                <div className="form-footer">
                    <Button type="default" className="exit-button" onClick={handleExit}>
                        Exit
                    </Button>
                    <Button
                        type="primary"
                        className="next-button"
                        onClick={handleSubmit}
                    >
                        {buttonText}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default JobAddForm;
