import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent"
import {defaultTableColumnList} from "../defaultTableColumnList";

const ExpiringCredentialsComponent=()=>{
    return (
        <div className={'pipeline-table-wrapper'}>
            <AntTableComponent
                data={[]}
                columns={defaultTableColumnList || []}
                extraPayload={[]}
                noDataText={'No ExpiringCredentials Data Found.'}
            />
        </div>
    )
}
export default ExpiringCredentialsComponent;