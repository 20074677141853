import "./FormItemComponent.scss";
import {Form} from "antd";

export interface FormItemComponentProps {
    label?: React.ReactNode;
    errorMessage?: React.ReactNode;
    validationStatus?: "success" | "warning" | "error" | "validating" | "";
    labelColon?: boolean;
    layout?: 'horizontal' | 'vertical';
    tooltip?: React.ReactNode;
    required?: boolean;
    hasError?: boolean,
    className?: string;
    children?:any
}

export type CommonFormInputProps = Omit<FormItemComponentProps, 'children'>

// Define the function as a generic
export const extractCommonPropsFromInput = <T extends CommonFormInputProps>(props: T): CommonFormInputProps => {
    const {
        tooltip,
        layout,
        label,
        errorMessage,
        labelColon,
        required,
        hasError,
        className,
    } = props;

    return {
        tooltip,
        layout,
        label,
        errorMessage,
        labelColon,
        required,
        hasError,
        className,
    };
};

const FormItemComponent = (props: FormItemComponentProps) => {
    const {
        label,
        className = '',
        errorMessage,
        hasError,
        labelColon,
        layout,
        tooltip,
        required,
        children
    } = props;

    return (
        <Form.Item label={label}
                   className={`form-item-component ${className}`}
                   help={errorMessage}
                   validateStatus={hasError ? 'error' : undefined}
                   colon={labelColon}
                   layout={layout}
                   tooltip={tooltip}
                   required={required}>
            {children as React.ReactNode}
        </Form.Item>
    );

};

export default FormItemComponent;
