import {Button} from "@material-ui/core";
import {FormikHelpers} from "formik";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import "react-phone-number-input/style.css";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import {TsFileUploadConfig, TsFileUploadWrapperClass} from "../../../classes/ts-file-upload-wrapper.class";
import DialogComponent from "../../../components/DialogComponent";
// import LoaderComponent from "../../../components/LoaderComponent";
import CustomPreviewFile from "../../../components/shared/CustomPreviewFile";
import LeavePageConfirmationComponent from "../../../components/shared/LeavePageConfirmationComponent";
import VitawerksConfirmComponent from "../../../components/VitawerksConfirmComponent";
import {ENV, ImageConfig} from "../../../constants";
import {ApiService, CommonService, Communications} from "../../../helpers";
import EditHcpBasicDetailsComponent from "./BasicDetails/EditHcpBasicDetailsComponent";
import "./EditHcpComponent.scss";
import EducationAddComponent from "./EducationEditComponent/EducationEditComponent";
import ExperienceEditComponent from "./ExperienceEditComponent/ExperienceEditComponent";
import ReferenceAddComponent from "./ReferenceEditComponent/ReferenceEditComponent";
import VolunteerExperienceEditComponent from "./VolunteerExperienceEditComponent/VolunteerExperienceEditComponent";
import {HcpEditType} from "./EditHcpValuesValidations";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import LoaderComponent from "../../../components/loader/LoaderComponent"
import {UploadedFile} from "./hcp-edit-attachment-component/HcpEditAttachmentComponentANTD";
import _ from "lodash";


const EditHcpComponent = () => {
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
    const user: any = localStorage.getItem("currentUser");
    let currentUser = JSON.parse(user);
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const {id} = params;
    const [hcpDetails, setHcpDetails] = useState<any | null>(null);
    // const [hcpTypesLoading, setHcpTypesLoading] = useState<boolean>(true);
    // const [educations, setEducations] = useState<any>([]);
    //  const [experiences, setExperiences] = useState<any>([]);
    //   const [references, setReferences] = useState<any>([]);
    // const [volunteerExperiences, setVolunteerExperiences] = useState<any>([]);
    // const [regions, setRegions] = useState<any>([]);
    const {regions, isLoading} = useSelector((state: StateParams) => state.meta);
    //  const [specialitiesMaster, setSpecialitiesMaster] = useState<any>([]);
    const [hcpTypeSpecialities, setHcpTypeSpecialities] = useState<any>([]);
    // const [hcpTypes, setHcpTypes] = useState<any>([]);
    const {hcpTypes, isHcpTypesLoading} = useSelector((state: StateParams) => state.meta);
    const [attachmentsDetails, setAttachmentsDetails] = useState<any | null>(null);
    const [contractDetails] = useState<any>(null);
    const [profileImageFile, setProfileImageFile] = useState<{ wrapper: any } | null>(null);
    const [contractFile, setContractFile] = useState<{ wrapper: any } | null>(null);
    const [fileUpload, setFileUpload] = useState<{ wrapper: any } | null>(null);
    const [isHcpDetailsLoading, setIsHcpDetailsLoading] = useState<boolean>(true);
    //  const [specIsLoading, setSpecIsLoading] = useState<boolean>(true);
    // const [regIsLoading, setRegIsLoading] = useState<boolean>(true);
    const [isAttachmentsLoading, setIsAttachmentsLoading] = useState<boolean>(true);
    const [previewFileData, setPreviewFile] = useState<any | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [previewProfileImage, setPreviewProfileImage] = useState<any | null>(null);
    const [openProfileImage, setOpenProfileImage] = useState<boolean>(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [isProfileImageDeleted, setIsProfileImageDeleted] = useState<boolean>(false);
    const [isContractDeleted, SetIsContractDeleted] = useState<boolean>(false);
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const [isDeleteProfileImageOpen, setIsDeleteProfileImageOpen] = useState<boolean>(false);
    const [isDeleteAttachmentOpen, setIsDeleteAttachmentOpen] = useState<boolean>(false);
    const [deleteAttachmentDetails, setDeleteAttachmentDetails] = useState<any>(null);
    const [isDeleteAttachment, setIsDeleteAttachment] = useState<boolean>(false);
    const [scrollToError, setScrollToError] = useState<boolean>(false);
    const [handlingSubmit, setHandlingSubmit] = useState<boolean>();
    const [state, setState] = useState<any>([]);
    const {specialitiesMasterList} = useSelector((state: StateParams) => state.meta);
    const [apiUploadedFiles,setApiUploadedFiles]=useState<UploadedFile[]>([]);
    const [required_attachments, setRequiredAttachments] = useState<any>([
        {attachment_type: "Resume", index: -1, id: 1},
        {attachment_type: "Physical Test", index: -1, id: 2},
        {attachment_type: "TB Test", index: -1, id: 3},
        {attachment_type: "Chest X-ray", index: -1, id: 4},
        {attachment_type: "CPR/BLS Card", index: -1, id: 5},
        {attachment_type: "Driver License", index: -1, id: 6},
        {attachment_type: "SSN Card", index: -1, id: 7},
        {attachment_type: "License", index: -1, id: 8},
        {attachment_type: "Covid Certificate", index: -1, id: 9},
        {attachment_type: "Covid Vaccine Card", index: -1, id: 10},
        {attachment_type: "Covid Test Result", index: -1, id: 11},
        {attachment_type: "Live Scan", index: -1, id: 12},
        {attachment_type: "Vaccine Exemption Letter", index: -1, id: 13},
        {attachment_type: "Nursing License", index: -1, id: 15},
        {attachment_type: "Others", index: -1, id: 14}

    ]);
    const [otherDocuments, setOtherDocuments] = useState<any>([]);
    const [prevApiUploadedFiles,setPrevApiUploadedFiles]=useState<UploadedFile[]>([]);
    const [isHcpSubmitting, setIsHcpSubmitting] = useState<boolean>(false);
    const [expInYears, setExpInYears] = useState<any>(0);
    const [specialities, setSpecialities] = useState<string>("");
    const [workExpData, setWorkExpData] = useState<any>([]);
    // const workExpData = hcpDetails?.experience.filter((data:any) => data?.exp_type === "fulltime");

    useEffect(() => {
        if (hcpDetails) {
            const workExpData = hcpDetails?.experience.filter((data: any) => data?.exp_type === "fulltime");
            setWorkExpData(workExpData);
        }
    }, [hcpDetails]);

    useEffect(()=>{
        if(apiUploadedFiles?.length > 0 && prevApiUploadedFiles?.length === 0){
            const clonedFiles=_.cloneDeep(apiUploadedFiles);
            setPrevApiUploadedFiles(clonedFiles)
        }
    },[apiUploadedFiles,prevApiUploadedFiles])

    let hcpInitialState: HcpEditType = {
        first_name: hcpDetails?.first_name || '',
        last_name: hcpDetails?.last_name || '',
        dob: hcpDetails?.dob || null,
        email: hcpDetails?.email || '',
        contact_number: hcpDetails?.contact_number?.replace(/^(?:\+1)+/, '') || '',
        hcp_type: hcpDetails?.hcp_type || '',
        gender: hcpDetails?.gender || '',
        about: hcpDetails?.about || '',
        worker_id: hcpDetails?.worker_id || '',
        experience: hcpDetails?.professional_details?.experience || '',
        summary: hcpDetails?.professional_details?.summary,
        hcp_uniqueId: hcpDetails?.hcp_uniqueId || '',
        address: {
            street: hcpDetails?.address?.street || '',
            city: hcpDetails?.address?.city || '',
            state: hcpDetails?.address?.state || '',
            region: hcpDetails?.address?.region || '',
            country: hcpDetails?.address?.country || 'US',
            zip_code: hcpDetails?.address?.zip_code || '',
        },

        professional_details: {
            experience: "",
            speciality: "",
            summary: hcpDetails?.professional_details?.summary || '',
        },

        contract_details: {
            rate_per_hour: hcpDetails?.contract_details?.rate_per_hour,
            signed_on: hcpDetails?.contract_details?.signed_on === "" ? null : hcpDetails?.contract_details?.signed_on,
            salary_credit: hcpDetails?.contract_details?.salary_credit,
        },

        nc_details: {
            dnr: hcpDetails?.nc_details?.dnr,
            shift_type_preference: hcpDetails?.nc_details?.shift_type_preference,
            location_preference: hcpDetails?.nc_details?.location_preference,
            more_important_preference: hcpDetails?.nc_details?.more_important_preference,
            family_consideration: hcpDetails?.nc_details?.family_consideration,
            zone_assignment: hcpDetails?.nc_details?.zone_assignment,
            vaccine: hcpDetails?.nc_details?.vaccine,
            covid_facility_preference: hcpDetails?.nc_details?.covid_facility_preference,
            is_fulltime_job: hcpDetails?.nc_details?.is_fulltime_job,
            is_supplement_to_income: hcpDetails?.nc_details?.is_supplement_to_income,
            is_studying: hcpDetails?.nc_details?.is_studying,
            is_gusto_invited: hcpDetails?.nc_details?.is_gusto_invited,
            is_gusto_onboarded: hcpDetails?.nc_details?.is_gusto_onboarded,
            gusto_type: hcpDetails?.nc_details?.gusto_type,
            nc_last_updated: hcpDetails?.nc_details?.nc_last_updated ? hcpDetails?.nc_details?.nc_last_updated : `${currentUser?.first_name} ${currentUser?.last_name}`,
            last_call_date: hcpDetails?.nc_details?.last_call_date === "" ? null : hcpDetails?.nc_details?.last_call_date,
            contact_type: hcpDetails?.nc_details?.contact_type,
            other_information: hcpDetails?.nc_details?.other_information,
            travel_preferences: hcpDetails?.nc_details?.travel_preferences,
            is_authorized_to_work: hcpDetails?.nc_details?.is_authorized_to_work,
            is_require_employment_sponsorship: hcpDetails?.nc_details?.is_require_employment_sponsorship,
            vaccination_dates: {
                first_shot: hcpDetails?.nc_details?.vaccination_dates?.first_shot,
                latest_shot: hcpDetails?.nc_details?.vaccination_dates?.latest_shot,
            },
        },
        notification_preferences: {
            sms: hcpDetails?.notification_preferences?.sms,
            email: hcpDetails?.notification_preferences?.email,
            push_notification: hcpDetails?.notification_preferences?.push_notification,
        },
        location: {
            coordinates: [Number(hcpDetails?.location?.coordinates?.[0]), Number(hcpDetails?.location?.coordinates?.[1])],
            type: "Point"
        },
    };

    const onAddVolunteerExperience = useCallback((experience: any) => {
        return new Promise((resolve, reject) => {
            ApiService.post(ENV.API_URL + "hcp/" + id + "/experience", experience).then((resp: any) => {
                // console.log(resp);
                if (resp && resp.success) {
                    resolve(null);
                } else {
                    reject(resp);
                }
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }, [id]);

    const handleHcpTypeChange = useCallback(
        (hcp_type: any) => {
            const selectedSpeciality = specialitiesMasterList[hcp_type];
            setHcpTypeSpecialities(selectedSpeciality);
        },
        [specialitiesMasterList]
    );

    const handleCalcExperience = useCallback(() => {
        const res: any = calculateExperience(workExpData);
        setExpInYears(res);
    }, [workExpData]);

    const handleCalcSpecialities = useCallback(() => {
        let specialities = workExpData?.map((item: any) => item?.specialisation);
        let filteredData = specialities.filter((speciality: any) => speciality !== "None");
        setSpecialities(filteredData.join(","));
    }, [workExpData]);

    useEffect(() => {
        handleCalcExperience();
        handleCalcSpecialities();
    }, [workExpData, handleCalcExperience, handleCalcSpecialities]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [scrollToError]);

    const init = useCallback(() => {
        CommonService._api.get(ENV.API_URL + "hcp/" + id).then((resp) => {
            setHcpDetails(resp.data);
            console.log(resp);
            setIsHcpDetailsLoading(false);
        }).catch((err) => {
            console.log(err);
        });
    }, [id]);


    const previewFile = useCallback((index: any, type: any) => {
            setHandlingSubmit(true);
            if (type === "contract") {
                setPreviewFile(contractFile?.wrapper[0]);
            } else {
                setPreviewFile(fileUpload?.wrapper[index]);
            }
            setOpen(true);
        },
        [fileUpload, contractFile?.wrapper]
    );

    const previewProfileImageFile = useCallback((index: any) => {
            setHandlingSubmit(true);
            setPreviewProfileImage(profileImageFile?.wrapper[index]);
            setOpenProfileImage(true);
        },
        [profileImageFile?.wrapper]
    );

    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);
    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const cancelPreviewProfileImage = useCallback(() => {
        setOpenProfileImage(false);
    }, []);
    const confirmPreviewProfileImage = useCallback(() => {
        setOpenProfileImage(false);
    }, []);


    // const getVolunteerExperienceDetails = useCallback(() => {
    //     CommonService._api.get(ENV.API_URL + "hcp/" + id + "/experience?exp_type=volunteer").then((resp) => {
    //         setVolunteerExperiences(resp.data || []);
    //     }).catch((err) => {
    //         console.log(err);
    //         setVolunteerExperiences([]);
    //     });
    // }, [id]);

    // const getRegions = useCallback(() => {
    //     CommonService._api.get(ENV.API_URL + "meta/hcp-regions").then((resp) => {
    //         setRegions(resp.data || []);
    //         setRegIsLoading(false);
    //     }).catch((err) => {
    //         console.log(err);
    //     });
    // }, []);

    // const getContractDetails = useCallback(() => {
    //     CommonService._api.get(ENV.API_URL + "hcp/" + id + "/contract").then((resp) => {
    //         setContractDetails(resp.data[0]);
    //         SetIsContractDeleted(false);
    //     }).catch((err) => {
    //         console.log(err);
    //     });
    // }, [id]);

    // const getSpecialities = useCallback(() => {
    //     CommonService._api.get(ENV.API_URL + "meta/hcp-specialities").then((resp) => {
    //         setSpecialitiesMaster(resp.data || []);
    //         setSpecIsLoading(false);
    //     }).catch((err) => {
    //         console.log(err);
    //     });
    // }, []);

    const getAttachmentsDetails = useCallback(() => {
        CommonService._api.get(ENV.API_URL + "hcp/" + id + "/attachments").then((resp) => {
            setAttachmentsDetails(resp?.data);
            setIsAttachmentsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsAttachmentsLoading(false);
        });
    }, [id]);

    const deleteContractFileApi = useCallback(() => {
        SetIsContractDeleted(true);
        let payload = {
            file_key: hcpDetails?.contract_details?.file_key,
        };
        CommonService._api.delete(ENV.API_URL + "deleteAttachment", payload).then((resp) => {
            init();
            setIsDeleteOpen(false);
            CommonService.showToast(resp?.msg || "Staff Contract Deleted", "info");
        }).catch((err) => {
            SetIsContractDeleted(false);
            console.log(err);
        });
    }, [hcpDetails, init]);

    const deleteProfileImageFileApi = useCallback(() => {
        setIsProfileImageDeleted(true);
        // let payload = {
        //     hcp_id: id,
        // };
        CommonService._api.delete(ENV.API_URL + `hcp/${id}/profile`).then((resp) => {
            setIsDeleteProfileImageOpen(false);
            init();
            CommonService.showToast(resp?.msg || "Staff Profile Image Deleted", "info");
        }).catch((err) => {
            setIsProfileImageDeleted(false);
            console.log(err);
        });
    }, [id, init]);


    const calculateExperience = (experiences: any[]) => {
        let totalExp = 0;
        let showExp = '';
        for (let experience of experiences) {
            if (experience.still_working_here === "0" && experience.exp_type === "fulltime") {
                let start_date = new Date(experience.start_date);
                let end_date = new Date(experience.end_date);
                let y1 = start_date.getFullYear();
                let y2 = end_date.getFullYear();
                let m1 = start_date.getMonth();
                let m2 = end_date.getMonth();
                let diff = ((y2 - y1) * 12) + ((m2 - m1));
                let diffYear = (Math.floor(diff / 12) * 12);
                let diffMonth = diff % 12;
                totalExp = totalExp + diffYear * 12 + diffMonth;
                const yearOfExp = Math.floor(totalExp / 12);
                const montOfExp = totalExp % 12;
                let exp = `${Math.floor(yearOfExp / 12)}.${montOfExp}`;
                showExp = `${exp}`;
            }
        }
        return showExp;
    };

    const deleteAttachment = useCallback(() => {
        setIsDeleteAttachment(true);
        console.log(deleteAttachmentDetails);
        let payload = {
            file_key: deleteAttachmentDetails?.file_key,
            is_license_removal: deleteAttachmentDetails?.attachment_type === "License",
            is_tos_removal: deleteAttachmentDetails?.attachment_type === "Terms of Service",
            hcp_id: id
        };

        CommonService._api
            .delete(ENV.API_URL + "deleteAttachment", payload)
            .then((resp) => {
                getAttachmentsDetails();
                CommonService.showToast(resp?.msg || "Staff Attachment Deleted", "info");
                setIsDeleted(false);
                setIsDeleteAttachment(false);
                setIsDeleteAttachmentOpen(false);
            })
            .catch((err) => {
                console.log(err);
                setIsDeleted(false);
                setIsDeleteAttachment(false);
            });
    }, [getAttachmentsDetails, deleteAttachmentDetails, id]);

    // const getHcpTypes = useCallback(() => {
    //     CommonService._api.get(ENV.API_URL + "meta/hcp-types").then((resp) => {
    //         setHcpTypes(resp.data || []);
    //         setHcpTypesLoading(false);
    //     }).catch((err) => {
    //         console.log(err);
    //     });
    // }, []);

    const OnFileSelected = (files: File[], id: any) => {
        let selectedAttachment = required_attachments?.filter((item: any) => item?.id === id);
        if (selectedAttachment[0]) {
            required_attachments[selectedAttachment[0]?.id - 1].index = fileUpload?.wrapper?.length || 0;
            setRequiredAttachments([...required_attachments]);
        }
        for (let file of files) {
            // console.log(file)
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: "Data",
                uploadUrl: ENV.API_URL + "facility/add",
                allowed_types: ["jpg", "png", "csv", "pdf"],
                extraPayload: {expiry_date: "", file_type: selectedAttachment[0]?.attachment_type},
            };
            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: {
                wrapper: TsFileUploadWrapperClass
            }) => {
                // console.log(state);
                setFileUpload((prevState) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return {wrapper: prevState.wrapper};
                    }
                    return prevState;
                });
            });
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, "error");
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, "success");
                }
            };
            uploadWrapper.onProgress = (progress) => {
                // console.log('progress', progress);
            };
            setFileUpload((prevState) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
            // uploadWrapper.startUpload();
        }
    };

    useEffect(() => {
        init();
        getAttachmentsDetails();
    }, [init, getAttachmentsDetails]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Edit Staff");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    useEffect(() => {
        handleHcpTypeChange(hcpDetails?.hcp_type);
    }, [hcpDetails?.hcp_type, handleHcpTypeChange]);

    const deleteContractFile = (temp: any) => {
        SetIsContractDeleted(true);
        let data = contractFile?.wrapper.filter((_: any, index: any) => index !== temp);
        setContractFile((prevState) => {
            return {wrapper: [...data]};
        });
        SetIsContractDeleted(false);
    };

    const deleteProfileImage = (temp: any) => {
        setIsProfileImageDeleted(true);
        let data = profileImageFile?.wrapper.filter((_: any, index: any) => index !== temp);
        setProfileImageFile((prevState) => {
            return {wrapper: [...data]};
        });
        setIsProfileImageDeleted(false);
    };


    const onAddEducation = useCallback((education: any) => {
        return new Promise((resolve, reject) => {
            ApiService.post(ENV.API_URL + "hcp/" + id + "/education", education).then((resp: any) => {
                if (resp && resp.success) {
                    resolve(null);
                } else {
                    reject(resp);
                }
            })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }, [id]);

    const OnContractFileUpload = (files: File[]) => {
        for (let file of files) {
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: "Data",
                uploadUrl: ENV.API_URL + "facility/add",
                allowed_types: ["jpg", "png", "csv", "pdf"],
                extraPayload: {expiry_date: ""},
            };
            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: {
                wrapper: TsFileUploadWrapperClass
            }) => {
                setContractFile((prevState) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return {wrapper: prevState.wrapper};
                    }
                    return prevState;
                });
            });
            uploadWrapper.onError = (err, heading) => {
                if (heading) {
                    CommonService.showToast(err, "error");
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                console.log(resp, "contract");
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, "success");
                }
            };
            uploadWrapper.onProgress = (progress) => {
            };
            setContractFile((prevState) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
            // uploadWrapper.startUpload();
        }
    };

    const onProfileImageUpload = (files: File[]) => {
        for (let file of files) {
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: "Data",
                uploadUrl: ENV.API_URL + "facility/add",
                allowed_types: ["jpg", "png", "csv", "pdf"],
                extraPayload: {expiry_date: ""},
            };
            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: {
                wrapper: TsFileUploadWrapperClass
            }) => {
                setProfileImageFile((prevState) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return {wrapper: prevState.wrapper};
                    }
                    return prevState;
                });
            });
            uploadWrapper.onError = (err, heading) => {
                if (heading) {
                    CommonService.showToast(err, "error");
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, "success");
                }
            };
            uploadWrapper.onProgress = (progress) => {
            };
            setProfileImageFile((prevState) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
            // uploadWrapper.startUpload();
        }
    };

    const onAddExperience = useCallback((experience: any) => {
        return new Promise((resolve, reject) => {
            ApiService.post(ENV.API_URL + "hcp/" + id + "/experience", experience).then((resp: any) => {
                // console.log(resp);
                if (resp && resp.success) {
                    resolve(null);
                } else {
                    reject(resp);
                }
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }, [id]);


    const onAddReference = useCallback((reference: any) => {
        return new Promise((resolve, reject) => {
            ApiService.post(ENV.API_URL + "hcp/" + id + "/reference", reference).then((resp: any) => {
                // console.log(resp);
                if (resp && resp.success) {
                    resolve(null);
                } else {
                    reject(resp);
                }
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }, [id]);

    // const handleContractFileUpload = useCallback(async (link: any) => {
    //     const file = contractFile?.wrapper[0].file;
    //     delete file.base64;
    //     CommonService._api.upload(link, file, {"Content-Type": file?.type}).then((resp) => {
    //         // console.log(resp);
    //     }).catch((err) => {
    //         console.log(err);
    //     });
    // }, [contractFile?.wrapper]);

    // const handleProfileImageFileUpload = useCallback(async (link: any) => {
    //     const file = profileImageFile?.wrapper[0].file;
    //     delete file.base64;
    //     CommonService._api.upload(link, file, {"Content-Type": file?.type}).then((resp) => {
    //         // console.log(resp);
    //     }).catch((err) => {
    //         console.log(err);
    //     });
    // }, [profileImageFile?.wrapper]);

    const handleContractUpload = useCallback((hcpId: any, setSubmitting, setErrors) => {
        const file = contractFile?.wrapper[0]?.file;
        const formData = new FormData();
        if (file) {
            formData.append("contract", file);
        }
        CommonService._api.upload(ENV.API_URL + "hcp/" + hcpId + "/contract", formData).then((resp) => {
            //   handleContractFileUpload(resp?.data);
            init();
            CommonService.showToast("Staff Contract Uploaded Successfully", "success");
        }).catch((err) => {
            console.log(err);
            init();
            //  setSubmitting(false);
            CommonService.handleErrors(setErrors, err);
        });

    }, [contractFile?.wrapper, init]);

    const handleProfileImageUpload = useCallback(() => {
        const file = profileImageFile?.wrapper[0]?.file;
        const formData = new FormData();
        if (file) {
            formData.append("image", file);
        }
        // let payload = {
        //     file_name: profileImageFile?.wrapper[0]?.file?.name,
        //     file_type: profileImageFile?.wrapper[0]?.file?.type,
        //     hcp_id: id,
        // };
        CommonService._api.upload(ENV.API_URL + `hcp/${id}/profile`, formData).then((resp) => {
            // handleProfileImageFileUpload(resp?.data);
            CommonService.showToast("Staff Profile Uploaded Successfully", "success");
        }).catch((err) => {
            console.log(err);
        });
    }, [profileImageFile?.wrapper, id]);

    const onHandleAttachmentUpload = useCallback((value: any, hcpId: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const file = value?.file;
                const formData = new FormData();
                if (file) {
                    formData.append("attachment", file);
                    formData.append("attachment_type", value?.documentTitle === 'Nursing License' ? "License": value?.documentTitle);
                    if (value?.expiry_date) {
                        formData.append("expiry_date", value?.expiry_date);
                    }
                }
                // let payload = {
                //     file_name: value?.file?.name,
                //     file_type: value?.file?.type,
                //     attachment_type: value?.extraPayload?.file_type,
                //     expiry_date: requiredAttachment?.isAdditionalAttachment === true ? "" : value?.extraPayload?.expiry_date,
                // };
                CommonService._api.upload(ENV.API_URL + "hcp/" + hcpId + "/attachment", formData).then((resp) => {
                    resolve(resp);
                })
                    .catch((err) => {
                        CommonService.showToast(err.message || err.error || 'Error' , 'error')
                        console.log(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const onHandleOtherAttachmentUpload = useCallback((value: any, index: any, hcpId: any, requiredAttachment: any) => {

        return new Promise(async (resolve, reject) => {
            try {
                const file = value?.file;
                const formData = new FormData();
                if (file) {
                    formData.append("attachment", file);
                    formData.append("attachment_type", value?.extraPayload?.doc_name);
                    formData.append("expiry_date", value?.extraPayload?.expiry_date);
                    //  formData.append("expiry_date",requiredAttachment?.isAdditionalAttachment === true ? "" : value?.extraPayload?.expiry_date)
                }
                CommonService._api.upload(ENV.API_URL + "hcp/" + hcpId + "/attachment", formData).then((resp) => {
                    resolve(resp);
                })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const handleOtherAttachmentsUpload = useCallback((hcpId: any, hcpResp: any) => {
        let promArray: any = [];
        otherDocuments?.forEach((value: any, index: any) => {
            if (value?.index !== -1) {
                promArray.push(onHandleOtherAttachmentUpload(fileUpload?.wrapper[value?.index], index, hcpId, value));
            }
        });

        return promArray;
    }, [fileUpload?.wrapper, onHandleOtherAttachmentUpload, otherDocuments]);


    const handleAttachmentsUpload = useCallback((hcpId: any, hcpResp: any) => {
            let promArray: any = [];
            uploadedFiles?.forEach((value: any, index: any) => {
                promArray.push(onHandleAttachmentUpload(value, hcpId));
            });
            if (promArray.length > 0) {
                Promise.all(promArray).then((resp) => {
                    if (hcpDetails?.is_approved === true) {
                        history.push("/hcp/user/view/" + hcpDetails?._id);
                    } else {
                        history.push("/hcp/view/" + id);
                    }
                    CommonService.showToast(hcpResp.msg || "Success", "success");
                }).catch((err) => console.log(err));
            } else {
                if (hcpDetails?.is_approved === true) {
                    history.push("/hcp/user/view/" + hcpDetails?._id);
                } else {
                    history.push("/hcp/view/" + id);
                }
                CommonService.showToast(hcpResp.msg || "Success", "success");
            }
        },
        [uploadedFiles, onHandleAttachmentUpload, hcpDetails?.is_approved, hcpDetails?._id, history, id]
    );

    const onHandleAttachmentEdit = useCallback((value: any, hcpId: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const payload = {
                    attachment_type: value?.documentTitle === 'Nursing License' ? 'License' : value?.documentTitle,
                    expiry_date: value?.expiry_date,
                    file_name: value?.file?.name,
                    ContentType: value?.file?.type,
                    file_key: value?.documentId,
                };

                // let payload = {
                //     file_name: value?.file?.name,
                //     file_type: value?.file?.type,
                //     attachment_type: value?.extraPayload?.file_type,
                //     expiry_date: requiredAttachment?.isAdditionalAttachment === true ? "" : value?.extraPayload?.expiry_date,
                // };
                CommonService._api.put(ENV.API_URL + "editS3Meta", payload).then((resp) => {
                    resolve(resp);
                })
                    .catch((err) => {
                        CommonService.showToast(err.message || err.error || 'Error' , 'error')
                        console.log(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }, []);

    const handleAttachmentsEdit = useCallback((hcpId: any, hcpResp: any) => {
            let promArray: any = [];
            apiUploadedFiles?.forEach((value: any, index: any) => {
                promArray.push(onHandleAttachmentEdit(value, hcpId));
            });
            if (promArray.length > 0) {
                Promise.all(promArray).then((resp) => {
                    if (hcpDetails?.is_approved === true) {
                        history.push("/hcp/user/view/" + hcpDetails?._id);
                    } else {
                        history.push("/hcp/view/" + id);
                    }
                    CommonService.showToast(hcpResp.message || "Document Successfully Updated", "success");
                }).catch((err) => console.log(err));
            } else {
                if (hcpDetails?.is_approved === true) {
                    history.push("/hcp/user/view/" + hcpDetails?._id);
                } else {
                    history.push("/hcp/view/" + id);
                }
                CommonService.showToast(hcpResp.msg || "Success", "success");
            }
        },
        [apiUploadedFiles, onHandleAttachmentEdit, hcpDetails?.is_approved, hcpDetails?._id, history, id]
    );

    const onAdd = useCallback((hcp: HcpEditType, {
        setSubmitting,
        setErrors,
        setFieldValue,
        resetForm
    }: FormikHelpers<HcpEditType>) => {
        setHandlingSubmit(false);
        let signed_on = hcp?.contract_details?.signed_on ? moment(hcp?.contract_details?.signed_on).format("YYYY-MM-DD") : null;
        let payload: any = hcp;
        payload = {
            ...payload,
            dob: hcp?.dob ? moment(hcp?.dob).format("YYYY-MM-DD") : null,
            contact_number: hcp?.contact_number?.startsWith("+1")
                ? hcp.contact_number
                : "+1" + hcp?.contact_number?.replace(/^\+/, ''),
            email: payload.email.toLowerCase(),
            address: {
                ...payload?.address,
                country: "US"
            },
            professional_details: {
                ...payload?.professional_details,
                experience: expInYears,
                speciality: specialities,
            },
            contract_details: {
                ...payload.contract_details,
                signed_on,
            },
            coordinates: [Number(hcp?.location?.coordinates[0]), Number(hcp?.location?.coordinates[1])],
            location: {
                coordinates: [Number(hcp?.location?.coordinates[0]), Number(hcp?.location?.coordinates[1])],
                type: "Point"
            }
        };
        console.log(payload,"PAYLOAD")
        if (!handlingSubmit) {
            setIsHcpSubmitting(true);
            ApiService.put(ENV.API_URL + "hcp/" + id, payload).then((resp: any) => {
                // console.log(resp);
                if (resp && resp.success) {
                    if (contractFile?.wrapper[0]?.file) {
                        handleContractUpload(id, setSubmitting, setErrors);
                    }
                    handleAttachmentsUpload(id, resp);
                    const clonedFiles=_.cloneDeep(apiUploadedFiles);
                    if(!_.isEqual(prevApiUploadedFiles,clonedFiles)){
                        //call only any changes are made in filed
                        handleAttachmentsEdit(id,resp);
                    }
                    handleOtherAttachmentsUpload(id, resp);
                } else {
                    setSubmitting(false);
                    setIsHcpSubmitting(false);
                }
            }).catch((err) => {
                console.log(err);
                CommonService.handleErrors(setErrors, err);
                setSubmitting(false);
                setIsHcpSubmitting(false);
                CommonService.showToast(err?.msg, "error");
                setScrollToError(prevState => !prevState);
            });
            if (profileImageFile?.wrapper[0]?.file) {
                handleProfileImageUpload();
            }
        }
    }, [contractFile,apiUploadedFiles,prevApiUploadedFiles, expInYears,handleAttachmentsEdit,handleAttachmentsUpload, handleOtherAttachmentsUpload, handleContractUpload, handleProfileImageUpload, id, profileImageFile, specialities, handlingSubmit]);

    const handleExpiryDate = (event: any, index: any) => {
        setFileUpload((prevState) => {
            if (prevState) {
                prevState.wrapper[index].extraPayload.expiry_date = event.target.value.trim();
            }
            ;
            return {wrapper: [...(prevState || {wrapper: []}).wrapper]};
        });
    };


    const deleteLocalAttachment = (index: any) => {
        if (required_attachments[index - 1]) {
            required_attachments[index - 1].index = -1;
            setRequiredAttachments([...required_attachments]);
        }
        CommonService.showToast("Staff attachment Removed", "info");
    };

    const openAdd = useCallback(() => {
        setIsAddOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);

    const openDeleteContract = useCallback((e) => {
        e.preventDefault();
        setIsDeleteOpen(true);
    }, []);
    const openDeleteProfileImage = useCallback((e) => {
        e.preventDefault();
        setIsDeleteProfileImageOpen(true);
    }, []);

    const confirmDeleteContract = useCallback(() => {
        deleteContractFileApi();
    }, [deleteContractFileApi]);

    const cancelDeleteContract = useCallback(() => {
        setIsDeleteOpen(false);
    }, []);

    const confirmDeleteProfileImage = useCallback(() => {
        deleteProfileImageFileApi();
    }, [deleteProfileImageFileApi]);


    const cancelDeleteProfileImage = useCallback(() => {
        setIsDeleteProfileImageOpen(false);
    }, []);

    const openDeleteAttachment = useCallback((e, file: any) => {
        e.preventDefault();
        setDeleteAttachmentDetails(file);
        setIsDeleteAttachmentOpen(true);
    }, []);

    const getState = useCallback(() => {
        CommonService._api
            .get(ENV.API_URL + "states")
            .then((resp) => {
                console.log(resp);
                setState(resp.data || []);
                // setRegIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getState();
    }, [getState]);

    const confirmDeleteAttachment = useCallback((file: any) => {
        setIsDeleted(true);
        deleteAttachment();
    }, [deleteAttachment]);

    const cancelDeleteAttachment = useCallback(() => {
        setIsDeleteAttachmentOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        hcpDetails?.is_approved === true ? history.push("/hcp/user/view/" + hcpDetails?._id) : history.push("/hcp/view/" + id);
    }, [history, hcpDetails?.is_approved, hcpDetails?._id, id]);

    useEffect(() => {
        console.log(isAttachmentsLoading);
    }, [isAttachmentsLoading]);
    // if (isLoading || specIsLoading || isHcpDetailsLoading || isHcpTypesLoading || isAttachmentsLoading) {
    //     return <LoaderComponent/>;
    // }


    return (
        <div className={'hcp-edit-component'}>
            <div className={'add-header-wrapper'}>
                <div className={'add-header-back-wrapper'} onClick={openAdd}>
                    <img src={ImageConfig.BackIcon} alt="back"/>
                    <div className={'add-header-back'}>
                        Back
                    </div>
                </div>
                <div className={'add-header-title'}>Edit Staff</div>
                <div className={'add-header-btn-wrapper mrg-right-30'}>
                    <Button size="large" onClick={openAdd} variant={"outlined"} color="primary"
                            id="btn_hcp_edit_cancel">
                        Cancel
                    </Button>
                    <Button disabled={isHcpSubmitting} form="hcp-edit-form" type={'submit'} size="large"
                            id="btn_hcp_edit_submit" variant={"contained"} color={"primary"}
                            className={isHcpSubmitting ? "has-loading-spinner" : ""}>
                        {isHcpSubmitting ? "Saving" : "Save"}
                    </Button>
                </div>
            </div>
            {
                (!isHcpDetailsLoading &&
                    // !specIsLoading &&
                    !isLoading &&
                    !isHcpTypesLoading &&
                    !isAttachmentsLoading) ?
                    <div className="edit-hcp screen">
                        <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                            <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile}
                                               previewData={previewFileData}/>
                        </DialogComponent>
                        <DialogComponent open={openProfileImage} cancel={cancelPreviewProfileImage}
                                         class="preview-content">
                            <CustomPreviewFile cancel={cancelPreviewProfileImage} confirm={confirmPreviewProfileImage}
                                               previewData={previewProfileImage}/>
                        </DialogComponent>
                        {/*<DialogComponent open={isAddOpen} cancel={cancelAdd}>*/}
                        {/*    <LeavePageConfirmationComponent cancel={cancelAdd} confirm={confirmAdd}*/}
                        {/*                                    confirmationText={""}*/}
                        {/*                                    notext={"Cancel"} yestext={"Leave"}/>*/}
                        {/*</DialogComponent>*/}
                        <LeavePageConfirmationComponent
                            visible={isAddOpen}
                            cancel={cancelAdd}
                            confirm={confirmAdd}
                            confirmationText="Are you sure you want to leave without saving?"
                            notext="Cancel"
                            yestext="Leave"
                        />
                            <VitawerksConfirmComponent open={isDeleteOpen} isConfirm={isContractDeleted} cancel={cancelDeleteContract}
                                                       confirm={confirmDeleteContract} text1="Want to delete"
                                                       hcpname={"Contract"} groupname={""} confirmationText={""}
                                                       notext={"Back"}
                                                       yestext={"Delete"}/>

                            <VitawerksConfirmComponent open={isDeleteProfileImageOpen} isConfirm={isProfileImageDeleted}
                                                       cancel={cancelDeleteProfileImage}
                                                       confirm={confirmDeleteProfileImage} text1="Want to delete"
                                                       hcpname={"Profile Image"} groupname={""} confirmationText={""}
                                                       notext={"Back"}
                                                       yestext={"Delete"}/>

                        <VitawerksConfirmComponent
                            open={isDeleteAttachmentOpen}
                            isConfirm={isDeleteAttachment}
                            cancel={cancelDeleteAttachment}
                            confirm={confirmDeleteAttachment}
                            text1="Want to delete"
                            hcpname={`${deleteAttachmentDetails?.attachment_type}`}
                            groupname={""}
                            confirmationText={""}
                            notext={"Back"}
                            yestext={"Delete"}
                        />
                        <EditHcpBasicDetailsComponent
                            uploadedFiles={uploadedFiles}
                            setUploadedFiles={setUploadedFiles}
                            apiUploadedFiles={apiUploadedFiles}
                            setApiUploadedFiles={setApiUploadedFiles}
                            state={state}
                            otherDocuments={otherDocuments}
                            setOtherDocuments={setOtherDocuments}
                            hcpDetails={hcpDetails}
                            setRequiredAttachments={setRequiredAttachments}
                            openDeleteContract={openDeleteContract}
                            openDeleteProfileImage={openDeleteProfileImage}
                            setFileUpload={setFileUpload}
                            isContractDeleted={isContractDeleted}
                            isProfileImageDeleted={isProfileImageDeleted}
                            openDeleteAttachment={openDeleteAttachment}
                            contractFile={contractFile}
                            profileImageFile={profileImageFile}
                            fileUpload={fileUpload}
                            onAdd={onAdd}
                            hcpTypes={hcpTypes}
                            regions={regions}
                            specialities={specialities}
                            hcpInitialState={hcpInitialState}
                            expInYears={expInYears}
                            required_attachments={required_attachments}
                            OnContractFileUpload={OnContractFileUpload}
                            onProfileImageUpload={onProfileImageUpload}
                            deleteContractFile={deleteContractFile}
                            OnFileSelected={OnFileSelected}
                            isDeleted={isDeleted}
                            attachmentsDetails={attachmentsDetails}
                            previewFile={previewFile}
                            previewProfileImageFile={previewProfileImageFile}
                            contractDetails={contractDetails}
                            handleExpiryDate={handleExpiryDate}
                            deleteLocalAttachment={deleteLocalAttachment}
                            deleteProfileImage={deleteProfileImage}
                            id={id}
                            getAttachmentsDetails={getAttachmentsDetails}
                        />
                        <div className="mrg-top-0 custom-border ">
                            <p className="card-header">Education</p>
                            <EducationAddComponent getEducationDetails={init} onAddEducation={onAddEducation}
                                                   hcpId={id} education={hcpDetails?.education}/>
                        </div>

                        <div className="mrg-top-0 custom-border">
                            <p className="card-header">Work Experience</p>
                            <ExperienceEditComponent
                                hcpTypeSpecialities={hcpTypeSpecialities}
                                hcpTypes={hcpTypes}
                                handleHcpTypeChange={handleHcpTypeChange}
                                getExperienceDetails={init}
                                hcpId={id}
                                onAddExperience={onAddExperience}
                                experiences={hcpDetails?.experience}
                            />
                        </div>

                        <div className="mrg-top-0 custom-border">
                            <p className="card-header">Volunteer Experience</p>
                            <VolunteerExperienceEditComponent getExperienceDetails={init} hcpId={id}
                                                              onAddExperience={onAddVolunteerExperience}
                                                              experiences={hcpDetails?.experience}
                                // experiences={experiences}
                            />
                        </div>
                        <div className="mrg-top-0 custom-border ">
                            <p className="card-header">References</p>
                            <ReferenceAddComponent getReferenceDetails={init} hcpId={id}
                                                   onAddReference={onAddReference} reference={hcpDetails?.reference}/>
                        </div>
                        {/*<div className="add-hcp-actions mrg-top-80">*/}
                        {/*    <Button size="large" onClick={openAdd} variant={"outlined"} color="primary"*/}
                        {/*            id="btn_hcp_edit_cancel">*/}
                        {/*        Cancel*/}
                        {/*    </Button>*/}
                        {/*    <Button disabled={isHcpSubmitting} form="hcp-edit-form" type={'submit'} size="large"*/}
                        {/*            id="btn_hcp_edit_submit" variant={"contained"} color={"primary"}*/}
                        {/*            className={isHcpSubmitting ? "has-loading-spinner" : ""}>*/}
                        {/*        {isHcpSubmitting ? "Saving" : "Save"}*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                        <ScrollToTop smooth color="#10c4d3"/>
                    </div>
                    :
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh'
                        }}>
                        <LoaderComponent type={'spinner'} size={'lg'} color={'primary'}/>
                    </div>
            }
        </div>
    )
}

export default EditHcpComponent;
