import AntTableComponent from "../../../components/ant-table-component/AntTableComponent";
import {ENV, ImageConfig} from "../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import "./JobListComponent.scss";
import {TableColumnsType} from "antd";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ApiService, CommonService, Communications} from "../../../helpers";
import {Tooltip} from "@material-ui/core";
import SelectDropdownComponent from "../../ant-component/select/SelectComponent";
import {useSessionStorage} from "../../../components/useSessionStorage";
import JobFilterDrawerComponent from "./job-filter-drawer/JobFilterDrawerComponent";
import DrawerComponent from "../../../components/drawer/DrawerComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {AddRounded, SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import {CssTextField} from "../../../constants/data/styles";


const JobListComponent = () => {
    const [selectedJobs, setSelectedJobs] = useState<any>([]);
    const [jobFilterData, setJobFilterData] = useSessionStorage("vitaJobsFilterData",{
        search: '',
        sort: {},
        limit: 10,
        page:1
    });
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [rowStatus, setRowStatus] = useState<{ [key: string]: string }>({});
    const [openFilterDrawer,setOpenFilterDrawer]=useState<boolean>(false);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [facilityList, setFacilityList] = useState<any | null>(null);

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, []);

    useEffect(() => getFacilityData(), [ getFacilityData]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Job");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    const handleStatusChange = useCallback((key: string, newStatus: string) => {
        setRowStatus((prev) => ({
            ...prev,
            [key]: newStatus, // Update only the selected row
        }));
        let payload: any = {};
        payload.job_status = newStatus;
        ApiService.put(ENV.API_URL + `ats/${key}/job`, payload)
            .then((resp: any) => {
                CommonService.showToast('Status updated successfully','success');
                setJobFilterData({...jobFilterData});
            })
            .catch((err: any) => {
                CommonService.showToast(err.error,'error');
            });
    }, [jobFilterData,setJobFilterData]);


    const JobListTableColumns: TableColumnsType = [
        {
            title: "Facility",
            width: 250,
            sorter: (a: any, b: any) => (a?.facility?.name || "").localeCompare(b?.facility?.name || ""),
            render: (item: any) => {
                return (
                    <div>
                        {item?.facility?.name?.length > 25 ?
                            <Tooltip title={item?.facility?.name}>
                                <div className={'ellipses-for-table-data '}>{item?.facility?.name}</div>
                            </Tooltip> :
                            <div className={'facility_name'}>
                                {item?.facility?.name || 'N/A'}
                            </div>
                        }
                    </div>
                );
            },
        },
        {
            title: "Job Title",
            width: 250,
            sorter: (a: any, b: any) => (a?.job_title || "").localeCompare(b?.job_title || ""),
            render: (item: any) => {
                return (
                    <div>
                        {item?.job_title?.length > 25 ?
                            <Tooltip title={item?.job_title}>
                                <div className={'ellipses-for-table-data '}>{item?.job_title}</div>
                            </Tooltip> :
                            <div className={'facility_name'}>
                                {item?.job_title || 'N/A'}
                            </div>
                        }
                    </div>
                );
            },
        },
        {
            title: "Job Type",
            width: 120,
            sorter: (a: any, b: any) => (a?.job_type || "").localeCompare(b?.job_type || ""),
            render: (item: any) => {
                return (
                        <div>
                            {item?.job_type?.length > 25 ?
                                <Tooltip title={CommonService.formatTitleCase(item?.job_type)}>
                                    <div className={'ellipses-for-table-data '}>{CommonService.formatTitleCase(item?.job_type)}</div>
                                </Tooltip> :
                                <div className={'facility_name'}>
                                    {CommonService.formatTitleCase(item?.job_type) || 'N/A'}
                                </div>
                            }
                        </div>
                    );
            },
        },
        {
            title: "Staff Type",
            width: 170,
            sorter: (a: any, b: any) => (a?.staff_type || "").localeCompare(b?.staff_type || ""),
            render: (item: any) => {
                return (
                        <div>
                            {item?.staff_type?.length > 25 ?
                                <Tooltip title={item?.staff_type}>
                                    <div className={'ellipses-for-table-data '}>{item?.staff_type}</div>
                                </Tooltip> :
                                <div className={'facility_name'}>
                                    {item?.staff_type || 'N/A'}
                                </div>
                            }
                        </div>
                    );
            },
        },
        {
            title: "Speciality",
            width: 200,
            sorter: (a: any, b: any) => (a?.speciality || "").localeCompare(b?.speciality || ""),
            render: (item: any) => {
                return (
                        <div>
                            {item?.speciality?.length > 25 ?
                                <Tooltip title={item?.speciality}>
                                    <div className={'ellipses-for-table-data'}>{item?.speciality}</div>
                                </Tooltip> :
                                <div className={'facility_name'}>
                                    {item?.speciality || 'N/A'}
                                </div>
                            }
                        </div>
                    );
            },
        },
        {
            title: "Start Date",
            width: 130,
            render: (item: any) => {
                return <div>
                    {item?.job_type === 'per_diem' ?
                        <>
                            {CommonService.getUtcDate(item?.per_diem_details?.start_date) || "N/A"}
                        </> : item?.job_type === 'contract' ? <>{CommonService.getUtcDate(item?.contract_details?.start_date) || "N/A"}</>
                            :<>{CommonService.getUtcDate(item?.job_start_date) || "N/A"}</>
                    }
                    </div>;
            },
        },
        {
            title: "End Date",
            width: 130,
            render: (item: any) => {
                return <div>
                    {item?.job_type === 'per_diem' ?
                        <>
                            {CommonService.getUtcDate(item?.per_diem_details?.end_date) || "N/A"}
                        </> : item?.job_type === 'contract' ? <>{CommonService.getUtcDate(item?.contract_details?.end_date) || "N/A"}</>
                            :<>{CommonService.getUtcDate(item?.job_end_date) || "N/A"}</>
                    }
                </div>
            },
        },
        {
            title: "Remote Job",
            width: 120,
            render: (item: any) => {
                return <div>{item?.remote_job  || 'N/A'}</div>;
            },
        },
        {
            title: "No. of Positions",
            width: 150,
            render: (item: any) => {
                return <div>{item?.no_of_positions || "N/A"}</div>;
            },
        },
        {
            title:'Source',
            width:120,
            render:(item:any)=>{
                return <div>{CommonService.formatTitleCase(item?.added_source) || 'N/A'}</div>
            }
        },
        {
            title: "Status",
            width: 150,
            render: (item: any) => {
                return <div className={'table-under-select-box'}>
                    <SelectDropdownComponent
                        placeholder={'Select job status'}
                        value={rowStatus[item._id] || item.job_status}
                        options={[
                            {title: 'Open', code: 'open'},
                            {title: 'Closed', code: 'closed'},
                            {title: 'Filled', code: 'filled'},
                            {title: 'Hold', code: 'hold'},
                            {title: 'In Progress', code: 'in_progress'},
                        ]}
                        required={true}
                        size={"middle"}
                        displayWith={(option) => option.title}
                        valueExtractor={(option) => option.code}
                        onChange={(value) => handleStatusChange(item._id, value)}
                    />
                </div>;
            },
        },
        {
            title: "View Details",
            width: 140,
            fixed: "right",
            render: (_: any, item: any, rowIndex: number) => {
                return (
                    <div className={"display-center"}>
                        <Link
                            to={`/jobs/view/${item?._id}`}
                            id={`link_job_details_${rowIndex}`}
                            style={{color: "#1e1e1e"}}
                        >
                            <ImageConfig.ArrowRightOutlinedIcon/>
                        </Link>
                    </div>
                );
            },
        },
    ];


    const clearFilterValues=useCallback(()=>{
        setJobFilterData((prevFilterData:any)=>{
            const updatedFilterData={
                ...prevFilterData,
            }
            const keysToRemove=[
                "facilities",
                "job_types",
                "status",
                "staff_types",
                "start_date",
                "end_date",
            ]
            keysToRemove.forEach((key:any)=> delete updatedFilterData[key])

            return updatedFilterData
        })
    },[setJobFilterData])

    const resetFilters = () => {
        clearFilterValues();
    };

    return (
        <>
            <DrawerComponent isOpen={openFilterDrawer} onClose={() => setOpenFilterDrawer(false)}>
                <JobFilterDrawerComponent
                    filterData={jobFilterData}
                    setFilterData={setJobFilterData}
                    setOpenFilterDrawer={setOpenFilterDrawer}
                    hcpTypes={hcpTypes}
                    isFacilityListLoading={isFacilityListLoading}
                    facilityList={facilityList}
                    resetFilters={resetFilters}
                />
            </DrawerComponent>
            <div className={"facility-list screen crud-layout pdd-30 pdd-top-10"}>
                <div className="custom-border pdd-10 pdd-top-20 pdd-bottom-0">
                    <div className="header">
                        <div className="mrg-left-5 filter">
                            <div>
                                <div className="d-flex">
                                    <div className="d-flex position-relative">
                                        {!jobFilterData.search ? (
                                            <div className={"search_icon"}>
                                                <SearchRounded/>
                                            </div>
                                        ) : (
                                            <div className={"search_icon"}>
                                                <ClearIcon
                                                    onClick={(event) => {
                                                        setJobFilterData({...jobFilterData, search: '', page: 1})
                                                    }}
                                                    id="clear_facility_search"
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <CssTextField
                                                defaultValue={""}
                                                className="search-cursor searchField"
                                                id="input_search_facility"
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    setJobFilterData({...jobFilterData, search: value, page: 1});
                                                }}
                                                value={jobFilterData.search}
                                                variant={"outlined"}
                                                size={"small"}
                                                type={"text"}
                                                placeholder={"Search Job"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Button
                                variant={"outlined"} id={'add_facility_btn'}
                                color={"primary"}
                                onClick={() => {
                                    setOpenFilterDrawer(true)
                                }}
                            >
                                &nbsp;&nbsp;Filters&nbsp;&nbsp;
                            </Button>
                        </div>
                        <div className="action pdd-right-5 d-flex">
                            <div className="mrg-left-20">
                                <Button component={Link} to={"/ats_job/bulk_upload"} variant={"contained"}
                                        color={"primary"}>
                                    &nbsp;&nbsp;Bulk Upload&nbsp;&nbsp;
                                </Button>
                            </div>
                        </div>
                        <div className="mrg-left-20">
                            <Button variant={"contained"} id={'add_facility_btn'} color={"primary"}
                                    component={Link} to="/job/add">
                                <AddRounded/>
                                &nbsp;&nbsp;Add Job&nbsp;&nbsp;
                            </Button>
                        </div>
                    </div>
                    <div className={'facility-table-component mrg-top-20'}>
                        <AntTableComponent
                            isBordered={true}
                            url={ENV.API_URL + "ats/jobsList"}
                            method={'post'}
                            extraPayload={jobFilterData}
                            columns={JobListTableColumns}
                            noDataText={'No Job Found.'}
                            isRowSelection={true}
                            selectedRowKeys={selectedJobs}
                            setSelectedRowKeys={setSelectedJobs}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobListComponent
