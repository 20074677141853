import {
    Button,
    IconButton,
    InputAdornment,
    MenuItem,
    Tooltip
} from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {TextField} from "formik-material-ui";
import React, {useCallback, useMemo, useState} from "react";
import VitawerksConfirmComponent from "../../../../components/VitawerksConfirmComponent";
import {ENV} from "../../../../constants";
import {designationNames} from "../../../../constants/data";
import {ApiService, CommonService} from "../../../../helpers";
import {memberFormValidation} from "../../add/FacilityMemberAddComponent/FacilityMemberFormValidation";
import "./FacilityMemberEditComponent.scss";
import {TableColumnsType} from "antd";
import Delete from "@material-ui/icons/Delete";
import AntTableComponent from "../../../../components/ant-table-component/AntTableComponent";

type FacilityMemberEditComponentProps = {
    onAddMember: any;
    hcpId: string;
    members: any;
    init: any;
    // setMembers: any;
    // getFacilityMembers: () => void;
};

interface MemberType {
    name: string;
    email: string;
    contact_number: string;
    extension_number: string;
    designation: string;
}

const memberInitialState: MemberType = {
    name: "",
    contact_number: "",
    extension_number: "",
    designation: "",
    email: "",
};

const FacilityMemberEditComponent = ({init, onAddMember, hcpId, members}: FacilityMemberEditComponentProps) => {
    const [isMembers, setIsMembers] = useState<boolean>(false);
    const [fieldType, setFieldType] = useState<boolean>(false);
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const [facilityId, setFacilityId] = useState<any>(null);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    const [facilityMember, setFacilityMember] = useState<any>(null);

    const onAdd = (member: MemberType, {setSubmitting, setErrors, resetForm}: FormikHelpers<MemberType>) => {
        const newMember = {
            name: member.name,
            email: member.email,
            contact_number: "+1" + member.contact_number,
            extension_number: member.extension_number ? member.extension_number : "",
            designation: member.designation,
        };

        onAddMember(newMember).then(() => {
            // getFacilityMembers();
            init();
            setIsMembers(false);
            resetForm();
        })
            .catch((err: any) => {
                //   console.log(err);
            });
    };

    const handleDeleteClick = useCallback((memberId: number) => {
            setIsConfirm(true);
            ApiService.delete(ENV.API_URL + "facility/" + hcpId + "/member/" + memberId).then((resp: any) => {
                CommonService.showToast(resp?.msg || "Facility Member Deleted", "success");
                //getFacilityMembers();
                init();
                setIsAddOpen(false);
                setIsConfirm(false);
            })
                .catch((err) => {
                    // console.log(err);
                    setIsConfirm(false);
                });
        },
        [hcpId,init]
    );

    const showDropDownBelowField = {
        MenuProps: {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
            getContentAnchorEl: null,
        },
    };

    const openAdd = useCallback((id: any, memberName: any) => {
        setFacilityMember(memberName);
        setFacilityId(id);
        setIsAddOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        handleDeleteClick(facilityId);
    }, [facilityId, handleDeleteClick]);

    const memberListColumn:TableColumnsType=useMemo(()=>{
        return [
            {
                title:"Name",
                width:180,
                dataIndex:"name",
                key:"name",
                render:(item:any)=>{
                    return (
                        <div>{item || 'N/A'}</div>
                    )
                }
            },
            {
                title:'Phone Number',
                width:120,
                dataIndex:'contact_number',
                key:'contact_number',
                render:(item:any)=>{
                    return (
                        <div>
                            {CommonService.formatPhoneNumber(item?.replace(/^\+1/,'')) || 'N/A'}
                        </div>
                    )
                }
            },
            {
                title:'Email',
                width:150,
                dataIndex:'email',
                key:'email',
                render:(item:any)=>{
                    return (
                        <div style={{textTransform:'initial'}}>{item || 'N/A'}</div>
                    )
                }
            },
            {
                title:'Designation',
                width:180,
                dataIndex:'designation',
                key:'designation',
                render:(item:any)=>{
                    return (
                        <div>{item || 'N/A'}</div>
                    )
                }
            },
            {
                title:'Delete',
                width:50,
                render:(item:any)=>{
                    return (
                        <div className={'display-center'}>
                            <Tooltip title={`Delete ${item.name} Facility Member`}>
                                <IconButton onClick={() => openAdd(item?._id,item?.name)}>
                                    <Delete className="delete-icon" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }
            }
        ]
    },[openAdd])

    return (
        <div className="facility-add-container">
            {/*<DialogComponent open={isAddOpen} cancel={cancelAdd}>*/}
            {/*    */}
            {/*</DialogComponent>*/}
            <VitawerksConfirmComponent open={isAddOpen} cancel={cancelAdd} confirm={confirmAdd} isConfirm={isConfirm}
                                       text1="Want to delete" hcpname={facilityMember} groupname={""}
                                       confirmationText={""} notext={"Back"} yestext={"Delete"}/>

            {isMembers ? (
                <div>
                    <Formik initialValues={memberInitialState} validateOnChange={true}
                            validationSchema={memberFormValidation} onSubmit={onAdd}>
                        {({isSubmitting, handleSubmit, isValid, resetForm}) => (
                            <Form className={"form-holder"}>
                                <div className="facility-add-input">
                                    <Field variant="outlined" name="name" type={"text"} component={TextField}
                                           label="Name" fullWidth autoComplete="off"
                                           id="input_facility_member_add_name"/>

                                    <Field
                                        SelectProps={showDropDownBelowField}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{fieldType &&
                                                <BackspaceIcon style={{cursor: "pointer"}}
                                                               onClick={() => setFieldType((prevState) => !prevState)}/>}</InputAdornment>,
                                        }}
                                        select={fieldType ? false : true}
                                        name="designation"
                                        type={"text"}
                                        component={TextField}
                                        label="Designation"
                                        fullWidth
                                        autoComplete="off"
                                        id="input_facility_member_add_designation"
                                    >
                                        {!fieldType &&
                                            designationNames &&
                                            designationNames.map((item: any, index) => (
                                                <MenuItem value={item.value} key={index}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        <MenuItem
                                            onClick={() => setFieldType((prevState) => !prevState)}>Other</MenuItem>
                                    </Field>
                                </div>
                                <div className="facility-add-input">
                                    <div className="number-container">
                                        <Field
                                            inputProps={{maxLength: 10}}
                                            className="phone_number"
                                            variant="outlined"
                                            name="contact_number"
                                            type={"text"}
                                            component={TextField}
                                            label="Contact Number"
                                            fullWidth
                                            autoComplete="off"
                                            id="input_facility_member_add_phone_number"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        +1
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                        {/*<Field*/}
                                        {/*    inputProps={{maxLength: 10}}*/}
                                        {/*    className="extension_number"*/}
                                        {/*    variant="outlined"*/}
                                        {/*    name="extension_number"*/}
                                        {/*    type={"text"}*/}
                                        {/*    component={TextField}*/}
                                        {/*    label="Extension No."*/}
                                        {/*    autoComplete="off"*/}
                                        {/*    id="input_facility_member_add_extension_number"*/}
                                        {/*/>*/}
                                    </div>
                                    <Field variant="outlined" name="email" type={"email"} component={TextField}
                                           label="Email" fullWidth autoComplete="off"
                                           id="input_facility_member_add_email"/>
                                </div>
                                <div className="facility-add-btn-grp">
                                    <Button
                                        variant="outlined"
                                        id="btn_facility_member_add_cancel"
                                        type="reset"
                                        onClick={() => {
                                            resetForm();
                                            setIsMembers(false);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                    <Button id="btn_facility_member_add_submit" color="primary"
                                            type="submit" variant="contained">
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            ) : (
                <>
                    <div className="facility-add-action">
                        <Tooltip title={"Add New Facility Member"}>
                            <p id="btn_facility_member_add_open" onClick={() => setIsMembers(true)}
                               className="generic-add-multiple">
                                + Add a Facility Member
                            </p>
                        </Tooltip>
                    </div>
                </>
            )}
            {members.length > 0 && (
               <div className={'mrg-top-20'}>
                   <AntTableComponent
                       data={members}
                       extraPayload={members}
                       columns={memberListColumn}
                       noDataText={'No Facility Members are added.'}
                   />
               </div>
            )}
        </div>
    );
};

export default FacilityMemberEditComponent;
