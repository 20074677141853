import "./EmailDocumentComponent.scss";
import {ENV, ImageConfig} from "../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import {Button, Divider, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import type {RcFile} from "antd/es/upload";
import {CommonService} from "../../../helpers";
import {useParams} from "react-router-dom";
import LoaderComponent from "../../../components/loader/LoaderComponent";


const EmailDocumentComponent=()=> {
    const {jobId, hcpId} = useParams<{ jobId: string; hcpId: string }>();
    const [documentList, setDocumentList] = useState<any>({})
    const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<any>({})
    console.log(documentList)

    const getDocumentList = useCallback(() => {
        if (!jobId || !hcpId) return;
        setIsDocumentLoading(true);
        CommonService._api.get(ENV.API_URL + `job/${jobId}/hcp/${hcpId}`).then((response) => {
            setDocumentList(response?.data);
        }).catch((error: any) => {
            CommonService.showToast(error.error, 'error')
        }).finally(() => {
            setIsDocumentLoading(false);
        })
    }, [jobId, hcpId])

    useEffect(() => {
        getDocumentList();
    }, [getDocumentList]);


    const handleUpload = useCallback((file: RcFile, submissionType, attachmentType) => {
        const formData = new FormData();
        formData.append('attachment_type', attachmentType);
        formData.append('attachment', file);
        formData.append('submission_type', submissionType);
        setIsUploading((prevState: any) => ({
            ...prevState,
            [attachmentType]: true
        }))
        CommonService._api.upload(ENV.API_URL + `job/${jobId}/hcp/${hcpId}`, formData).then((response: any) => {
            CommonService.showToast(response.message || 'Document Uploaded Successfully', 'success')
            getDocumentList();
        }).catch((error: any) => {
            CommonService.showToast(error.error, 'error');
        }).finally(() => {
            setIsUploading((prevState: any) => ({
                ...prevState,
                [attachmentType]: false
            }))
        })
    }, [jobId, hcpId, getDocumentList])


    const renderSubmissionSection = (submissionType: string, items: any) => {
        if (!items || items.length === 0) return null;
        const title = submissionType?.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        return (
            <div key={submissionType} className={'mrg-top-20'}>
                <div className={'submission-title'}>{title}</div>
                {items.map((item: any, index: number) => (
                    <div className={'document-card'} key={index}>
                        <div>{item}</div>
                        <div>
                            <Upload
                                beforeUpload={(file) => handleUpload(file, submissionType, item)}
                                showUploadList={false}
                                accept=".pdf,.doc,.docx,.jpg,.png"
                            >
                                <Button type="primary" icon={<UploadOutlined/>} loading={isUploading[item]}
                                        className={'upload-btn'}>
                                    Upload File
                                </Button>
                            </Upload>
                        </div>
                    </div>
                ))}
                <Divider/>
            </div>
        );
    };

    const allArraysEmpty =
        documentList?.certifications_eboarding?.length === 0 &&
        documentList?.certifications_submission?.length === 0 &&
        documentList?.documents_eboarding?.length === 0 &&
        documentList?.documents_submission?.length === 0 &&
        documentList?.license_eboarding?.length === 0 &&
        documentList?.license_submission?.length === 0 &&
        documentList?.skill_checklist_submission?.length === 0;


    return (
        <div className={'email-document-component'}>
            <div className={'email-document-header'}>
                <div className={'vitawerks-logo'}>
                    <img src={ImageConfig.Logo} alt={'vitawerks'}/>
                </div>
            </div>
            <div className={'email-document-content'}>
                <div className={'email-document-holder'}>
                    {isDocumentLoading ?
                        <div className={'document-loader'}>
                            <LoaderComponent type={'spinner'} color={'primary'}/>
                        </div> :
                        <div>
                            {!allArraysEmpty?
                                <>
                                    <div className={'required-documents-title'}>
                                        Required Documents
                                    </div>
                                    <div className={'document-list'}>
                                        {renderSubmissionSection('documents_submission', documentList.documents_submission)}
                                        {renderSubmissionSection('certifications_submission', documentList.certifications_submission)}
                                        {renderSubmissionSection('license_submission', documentList.license_submission)}
                                        {renderSubmissionSection('skill_checklist_submission', documentList.skill_checklist_submission)}
                                        {renderSubmissionSection('documents_eboarding', documentList.documents_eboarding)}
                                        {renderSubmissionSection('certifications_eboarding', documentList.certifications_eboarding)}
                                        {renderSubmissionSection('license_eboarding', documentList.license_eboarding)}
                                    </div>
                                </>
                                :
                                <div className={'no-document-text'}>
                                    Thank you for uploading all the documents.
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EmailDocumentComponent;