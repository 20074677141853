import {Input} from 'antd';
import './InputComponent.scss';
import {ReactNode, useCallback} from "react";
import FormItemComponent, {CommonFormInputProps, extractCommonPropsFromInput} from '../formItem/FormItemComponent';

export interface InputComponentProps extends CommonFormInputProps {
    className?: string;
    addonAfter?: ReactNode;
    name?: string;
    addonBefore?: ReactNode;
    allowClear?: boolean;
    status?: 'error' | 'warning';
    defaultValue?: string;
    disabled?: boolean;
    maxLength?: number;
    size?: 'small' | 'middle' | 'large';
    value?: string;
    suffix?: ReactNode;
    prefix?: React.ReactNode;
    variant?: 'outlined' | 'filled' | 'borderless';
    onChange?: (event: any) => void;
    onPressEnter?: (event: any) => void;
    onClear?: () => void;
    onSearch?: (value: string) => void;
    loading?: boolean;
    onBlur?: (event: any) => void;
    placeholder?: string;
    type?: string;
}

const InputComponent = (props: InputComponentProps) => {
    const {
        type,
        placeholder,
        className,
        addonAfter,
        addonBefore,
        allowClear,
        onClear,
        defaultValue,
        value,
        maxLength,
        onChange,
        onPressEnter,
        size,
        status,
        variant,
        suffix,
        prefix,
        name,
        disabled,
        onBlur
    } = props;

    const handleChange = useCallback((e: any) => {
        if (onChange) {
            onChange(e.target.value)
        }
    }, [onChange]);

    const handleWheel=useCallback((e)=>{
      if(type === 'number'){
          e.target.blur()
      }
    },[type])

    return (
        <FormItemComponent {...extractCommonPropsFromInput(props)}>
            <Input
                className={'input-component' + className ? className : ''}
                data-testid={'input-component'}
                size={size}
                placeholder={placeholder}
                status={status}
                disabled={disabled}
                variant={variant}
                defaultValue={defaultValue}
                addonAfter={addonAfter}
                addonBefore={addonBefore}
                suffix={suffix}
                prefix={prefix}
                value={value}
                name={name}
                type={type}
                maxLength={maxLength}
                allowClear={allowClear}
                onClear={onClear}
                onChange={handleChange}
                onPressEnter={onPressEnter}
                onBlur={onBlur}
                onWheel={handleWheel}
            />
        </FormItemComponent>
    );
};

export default InputComponent;
